import { Button, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHits } from 'react-instantsearch';
import AddIcon from '@mui/icons-material/Add';
import { AlgoliaHit } from 'instantsearch.js';
import AddPackagingsDialog from './AddPackagingsDialog';
import FilterUtils from '../../search/filters/filter.utils';
import { ProductHit } from '../../search/search';

interface AddPackagingsButtonProps {
  supplierCompanyId: string;
  buyerCompanyId: string;
  excludedObjectIds: string[];
}
export default function AddPackagingsButton({ supplierCompanyId, buyerCompanyId, excludedObjectIds }: AddPackagingsButtonProps): React.JSX.Element {
  const { t } = useTranslation(['products', 'discovery', 'common', 'order']);
  const filters = `${excludedObjectIds.map((id) => `NOT objectID:${id}`).join(' AND ')} AND ${FilterUtils.visiblePackagingsForBuyerFilter(buyerCompanyId)}`;
  const [isAddPackagingModalOpen, setIsAddPackagingModalOpen] = useState(false);
  const { items } = useHits<AlgoliaHit<ProductHit>>();

  const handleButtonClick = (): void => {
    setIsAddPackagingModalOpen(true);
  };

  return (
    <>
      <Tooltip
        title={t('order:all_packagings_are_already_in_this_order')}
        disableHoverListener={!!items.length}
      >
        <span>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleButtonClick}
            disabled={!items.length}
          >
            {t('products:add_a_base_unit')}
          </Button>
        </span>
      </Tooltip>
      <AddPackagingsDialog
        companyId={supplierCompanyId}
        filters={filters}
        isOpen={isAddPackagingModalOpen}
        setIsOpen={setIsAddPackagingModalOpen}
      />
    </>
  );
}
