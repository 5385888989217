import { Box, Card, Divider, Grid2, Tooltip, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useAtomValue } from 'jotai';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import useCurrentBreakpoint from '../../hooks/useCurrentBreakpoint';
import { companyPackagingsAtom } from '../state';
import CustomPriceListProductsFormRow from './CustomPriceListProductsFormRow';
import { insensitiveStringIncludes } from '../../common/utils/common.utils';
import SearchInput from '../../common/components/SearchInput';
import ProductSubFamilyFilter from '../../products/common/ProductSubFamilyFilter';
import useSearchProducts from '../../products/common/useSearchProducts';

export default function CustomPriceListProductsForm(): React.JSX.Element {
  const { t } = useTranslation(['common', 'products', 'price']);
  const customPriceListPackagings = useAtomValue(companyPackagingsAtom);
  const breakpoint = useCurrentBreakpoint();
  const [filteredProducts, setFilteredProducts] = useState(customPriceListPackagings);

  const productFamiliesOptions = useMemo((): Product_Sub_Family_Name_Enum[] => {
    const options = new Set<Product_Sub_Family_Name_Enum>();

    customPriceListPackagings.forEach(({ product }) => options.add(product.sub_family as Product_Sub_Family_Name_Enum));

    return [...options.values()];
  }, [customPriceListPackagings]);

  const virtualItemSize = useMemo((): number => {
    switch (breakpoint) {
      case 'xs':
      case 'sm':
      case 'md':
        return 312;
      default:
        return 115;
    }
  }, [breakpoint]);

  const handleProductSearchChange = useCallback((productFamilies: Product_Sub_Family_Name_Enum[], query: string): void => {
    const newFilteredProducts = customPriceListPackagings.filter((packaging) => {
      const matchFamilies = !productFamilies.length || productFamilies.includes(packaging.product.sub_family as Product_Sub_Family_Name_Enum);
      const matchSearch = !query || insensitiveStringIncludes(packaging.product.name, query) || packaging.base_unit.gtin?.includes(query);

      return matchFamilies && matchSearch;
    });

    setFilteredProducts(newFilteredProducts);
  }, [customPriceListPackagings]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const VirtualRow: any = useCallback(({ index, style }: any) => (
    <div style={style}>
      <Card
        sx={{
          mt: 1.5,
          backgroundColor: 'surfaceContainerLow.main',
          px: 1.5,
          py: 2,
          mr: 1,
          borderRadius: 4,
        }}
      >
        <CustomPriceListProductsFormRow packaging={filteredProducts[index]} />
      </Card>
    </div>

  ), [filteredProducts]);

  useEffect(() => {
    setFilteredProducts(customPriceListPackagings);
  }, [customPriceListPackagings]);

  const {
    setQuery,
    setProductFamilies,
    selectedProductFamilyMap,
    productFamilyMap,
    query,
  } = useSearchProducts({ productFamiliesOptions, onChange: handleProductSearchChange });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        py: {
          xs: 1,
          lg: 1.5,
        },
        px: {
          xs: 0,
          lg: 1.5,
        },
      }}
    >
      <Box>
        <Typography variant="titleMedium" sx={{ mb: 1.5 }}>
          {t('common:product_other')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            alignItems: {
              xs: 'flex-start',
              sm: 'center',
            },
            gap: 1,
            mb: 3,
          }}
        >
          <SearchInput
            onChange={(inputQuery) => setQuery(inputQuery)}
            value={query}
            sx={{
              width: {
                xs: '100%',
                lg: 364,
              },
            }}
          />
          <Divider
            orientation="vertical"
            sx={{
              height: 24,
              display: {
                xs: 'none',
                sm: 'flex',
              },
            }}
          />
          <ProductSubFamilyFilter
            productFamilyMap={productFamilyMap}
            selectedProductFamilyMap={selectedProductFamilyMap}
            setProductFamilies={setProductFamilies}
          />
        </Box>
        <Grid2
          container
          columnSpacing={1}
          sx={{
            display: {
              xs: 'none',
              lg: 'flex',
            },
            mb: 1,
            '& .MuiGrid-item': {
              py: 2,
              display: 'flex',
              alignItems: 'center',
            },
          }}
        >
          <Grid2 size={{ xs: 6 }}>
            <Typography variant="bodySmall">
              {t('products:base_unit_one')}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 2 }}>
            <Typography variant="bodySmall">
              {t('price:catalog_price_without_tax')}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 2 }}>
            <Typography variant="bodySmall">
              {t('price:discount_in_percent')}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 2 }} sx={{ display: 'flex', gap: 0.5 }}>
            <Typography variant="bodySmall">
              {t('price:final_price_without_tax')}
            </Typography>
            <Tooltip title={t('price:price_field_tooltip')} placement="top-start">
              <HelpOutlineIcon fontSize="inherit" />
            </Tooltip>
          </Grid2>
        </Grid2>
      </Box>
      <Box sx={{ flex: '1 1 auto' }}>
        <AutoSizer disableWidth>
          {({ height }) => (
            <Grid2 container>
              <FixedSizeList
                height={height}
                width="100%"
                itemSize={virtualItemSize}
                itemCount={filteredProducts.length}
                overscanCount={5}
              >
                {VirtualRow}
              </FixedSizeList>
            </Grid2>
          )}
        </AutoSizer>
      </Box>
    </Box>
  );
}
