import { Box, Chip, Popover, SxProps, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ReactElement, useState } from 'react';
import useOpenable from '../../hooks/useOpenable';

interface SelectFilterChipProps {
  label: string;
  isFilterActive: boolean;
  content: React.JSX.Element;
  icon?: ReactElement;
  onClose?: () => void;
}

const ARROW_ICONS_SX: SxProps = {
  width: 18,
  height: 18,
  color: 'onSecondaryContainer.main',
};

export default function SelectFilterChip({ label, isFilterActive, content, icon, onClose }: SelectFilterChipProps): React.JSX.Element {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { isOpen, open, close } = useOpenable();

  const handleSelectFilterChipClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    open();
  };

  const handlePopoverClose = (): void => {
    setAnchorEl(null);
    close();

    if (onClose) {
      onClose();
    }
  };

  const labelComponent = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,

      }}
    >
      <Typography variant="labelLarge" color="onSecondaryContainer">{label}</Typography>
      {
        isOpen
          ? <KeyboardArrowUpIcon sx={ARROW_ICONS_SX} />
          : <KeyboardArrowDownIcon sx={ARROW_ICONS_SX} />
      }
    </Box>
  );
  return (
    <>
      <Chip
        variant="filter"
        className={isFilterActive ? 'Kheops-selected' : ''}
        icon={icon}
        label={labelComponent}
        onClick={handleSelectFilterChipClick}
        sx={{
          pr: 1,
        }}
      />
      <Popover
        open={isOpen}
        keepMounted
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 0,
          vertical: 40,
        }}
        slotProps={{
          paper: {
            sx: {
              maxHeight: 280,
              p: 2,
              borderRadius: 3,
              width: 300,
            },
          },
        }}
      >
        {content}
      </Popover>
    </>
  );
}
