import { Box, Chip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import ConfirmDialog from '../../common/components/ConfirmDialog';
import { ProductFragment } from '../products';
import { PackagingFamilyTypeIntl } from '../../common/i18n/product-family.translation';
import ProductLabels from './Product/ProductLabels';

interface DeleteProductConfirmDialogProps {
  open: boolean;
  onClose: (confirm: boolean) => void;
  product: ProductFragment;
}

export default function DeleteProductConfirmDialog({ open, onClose, product }: DeleteProductConfirmDialogProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'products']);

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title={t('products:delete_the_product')}
      confirmLabel={t('common:delete')}
      cancelLabel={t('common:cancel')}
      subTitle={t('products:you_are_about_to_delete_a_product')}
      waitForClosing
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3, pb: 0.5 }}>
        <Typography variant="titleLarge">
          {product.name}
        </Typography>
        <Chip
          variant="tag"
          sx={{ mt: 1, mb: 2 }}
          label={t(`product-family:${PackagingFamilyTypeIntl[product.sub_family as Product_Sub_Family_Name_Enum]}`)}
        />
        {!!product.labels.length && <ProductLabels labels={product.labels} short />}
      </Box>
    </ConfirmDialog>
  );
}
