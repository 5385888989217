import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import ConfirmDialog from '../../../common/components/ConfirmDialog';
import SubCatalogCard from './SubCatalogCard';
import { SubCatalogsByCompanyIdQuery } from '../../../queries/__generated__/subCatalogsByCompanyId.generated';

interface SubCatalogDeleteDialogProps {
  subCatalog: SubCatalogsByCompanyIdQuery['sub_catalog'][number];
  supplierPackagingsCount: number;
  isOpen: boolean;
  handleConfirm: (confirm: boolean) => void;
}

export default function SubCatalogDeleteDialog({ subCatalog, isOpen, handleConfirm, supplierPackagingsCount }: SubCatalogDeleteDialogProps): React.JSX.Element {
  const { t } = useTranslation(['catalogs', 'common']);

  return (
    <ConfirmDialog
      open={isOpen}
      onClose={handleConfirm}
      cancelLabel={t('common:cancel')}
      confirmLabel={t('common:delete')}
      title={t('catalogs:delete_sub_catalog')}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <Typography variant="bodyMedium" color="secondary">
          {t('catalogs:delete_sub_catalog_confirm_message')}
        </Typography>
        <SubCatalogCard subCatalog={subCatalog} supplierPackagingsCount={supplierPackagingsCount} shouldDisplayOptionsMenu={false} />
      </Box>
    </ConfirmDialog>
  );
}
