import { Grid2, Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { companyIdAtom } from '../../state';
import { ProductPackagingsFormData } from '../../ProductFormPackagings';
import { SUPPORTED_IMAGE_TYPES, useUploadFile } from '../../../../hooks/useUploadFile';
import GridPackagingPicture from './GridPackagingPicture';

export default function BaseUnitPhotosForm({ csuIndex }: { csuIndex: number }): React.JSX.Element {
  const MAX_PHOTOS = 6;
  const { t } = useTranslation('products');
  const { register, setValue, watch } = useFormContext<ProductPackagingsFormData>();
  const photos = watch(`productPackagings.${csuIndex}.csu.photos`);
  const mainPhoto = watch(`productPackagings.${csuIndex}.csu.main_photo`);
  const companyId = useAtomValue(companyIdAtom);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [editPictureIndex, setEditPictureIndex] = useState<number | undefined>();
  const {
    handleFileChange,
    isFileUploadLoading,
    fileParameters,
  } = useUploadFile({ type: 'PACKAGING_PHOTOS', pathId: companyId });

  useEffect(() => {
    if (fileParameters) {
      const newPhotos = [...photos];

      if (editPictureIndex !== undefined) {
        const editedPhotoWasMainPhoto = newPhotos[editPictureIndex].path === mainPhoto?.path;

        if (editedPhotoWasMainPhoto) {
          setValue(`productPackagings.${csuIndex}.csu.main_photo`, fileParameters);
        }
        newPhotos[editPictureIndex] = fileParameters;
        setEditPictureIndex(undefined);
      } else {
        if (newPhotos.length === 0) {
          // when there are not any photos, make sure the first inserted is set as main photo
          setValue(`productPackagings.${csuIndex}.csu.main_photo`, fileParameters);
        }
        newPhotos.push(fileParameters);
      }

      setValue(`productPackagings.${csuIndex}.csu.photos`, newPhotos, { shouldDirty: true });
    }
  }, [fileParameters]);

  const handlePicturesItemClick = (): void => {
    fileInputRef.current!.click();
  };

  const handleEditPictureClick = (pictureIndex: number): void => {
    setEditPictureIndex(pictureIndex);
    handlePicturesItemClick();
  };

  const handleDeletePictureClick = (pictureIndex: number): void => {
    const deletedPhotoWasMain = photos[pictureIndex].path === mainPhoto?.path;
    const newPhotos = photos.filter((photo, index) => index !== pictureIndex);

    setValue(`productPackagings.${csuIndex}.csu.photos`, newPhotos, { shouldDirty: true });
    if (deletedPhotoWasMain) {
      setValue(`productPackagings.${csuIndex}.csu.main_photo`, newPhotos[0]);
    }
  };

  const fileChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    handleFileChange(event);

    // empty the value attribute of the input file so we can re-upload the same file in case of error
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
  }, []);

  return (
    <Box>
      <Typography variant="titleLarge" sx={{ mb: 1 }}>
        {t('products:pictures_other')}
      </Typography>
      <Typography variant="bodyMedium" color="secondary">
        {t('products:pictures_form_description')}
      </Typography>
      <Box
        sx={{
          mt: 3,
          p: 1,
          borderRadius: 4,
          border: '1px dashed',
          borderColor: 'outlineVariant.main',
        }}
      >
        <Grid2
          container
          rowSpacing={1}
          columnSpacing={3}
          sx={{
            minHeight: 194,
            justifyContent: 'center',
          }}
        >
          {
            !photos?.length
              ? (
                <Grid2 size={{ xs: 12 }} sx={{ textAlign: 'center', my: 'auto' }}>
                  <Button
                    loading={isFileUploadLoading}
                    variant="outlined"
                    onClick={handlePicturesItemClick}
                    startIcon={<AddIcon />}
                  >
                    {t('products:add_pictures')}
                  </Button>
                </Grid2>
              )
              : (
                <>
                  {photos.map((photo, index) => (
                    <GridPackagingPicture
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      src={photo}
                      csuIndex={csuIndex}
                      onEdit={() => handleEditPictureClick(index)}
                      onDelete={() => handleDeletePictureClick(index)}
                    />
                  ))}
                  {photos.length < MAX_PHOTOS && (
                    <Grid2 size={{ xs: 'auto' }} sx={{ my: 'auto' }}>
                      <Button
                        loading={isFileUploadLoading}
                        variant="outlined"
                        onClick={handlePicturesItemClick}
                      >
                        <AddIcon />
                      </Button>
                    </Grid2>
                  )}
                </>
              )
          }
        </Grid2>
      </Box>
      <Box
        ref={fileInputRef}
        component="input"
        type="file"
        accept={SUPPORTED_IMAGE_TYPES.join(', ')}
        sx={{ display: 'none' }}
        onChange={fileChanged}
      />
      <input type="hidden" {...register(`productPackagings.${csuIndex}.csu.photos`)} />
    </Box>
  );
}
