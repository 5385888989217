import SearchIcon from '@mui/icons-material/Search';
import { Box, Checkbox, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from '@mui/material';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import { useTranslation } from 'react-i18next';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { Dispatch, SetStateAction, useState } from 'react';
import { ProductFamily } from '../../hooks/useSortedProductFamiliesMap';
import SelectFilterChip from '../../common/components/SelectFilterChip';

interface ProductSubFamilyFilterProps {
  productFamilyMap: ProductFamily[];
  selectedProductFamilyMap: ProductFamily[];
  setProductFamilies: Dispatch<SetStateAction<Product_Sub_Family_Name_Enum[]>>;
}

export default function ProductSubFamilyFilter({ productFamilyMap, selectedProductFamilyMap, setProductFamilies }: ProductSubFamilyFilterProps): React.JSX.Element {
  const { t } = useTranslation(['discovery']);
  const [itemQuery, setItemQuery] = useState('');
  const selectedOptions = selectedProductFamilyMap.map((option) => option.value);

  const handleToggle = (value: Product_Sub_Family_Name_Enum): void => {
    const currentIndex = selectedOptions.indexOf(value);

    if (currentIndex === -1) {
      selectedOptions.push(value);
    } else {
      selectedOptions.splice(currentIndex, 1);
    }

    setProductFamilies(selectedOptions);
  };

  const filterContent = (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography sx={{ mb: 1 }} variant="titleMedium">{t('discovery:select_one_or_several_departments')}</Typography>
      <TextField
        size="small"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" sx={{ mr: 1 }} />,
        }}
        onChange={(event) => setItemQuery(event.target.value)}
        value={itemQuery}
        placeholder={t('discovery:search_a_particular_departement')}
        sx={{
          mb: 1,
        }}
      />
      <List>
        {(productFamilyMap as ProductFamily[])
          .filter((value) => value.label.toLowerCase().indexOf(itemQuery.toLowerCase()) === 0)
          .map((option, index, arr) => (
            <ListItem
              key={option.value}
              sx={{
                '&:hover': {
                  backgroundColor: 'surfaceContainerLow.main',
                  cursor: 'pointer',
                },
              }}
              dense
              divider={index < arr.length - 1}
              onClick={() => handleToggle(option.value)}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedOptions.indexOf(option.value) !== -1}
                />
              </ListItemIcon>
              <ListItemText primary={option.label} />
            </ListItem>
          ))}
      </List>
    </Box>
  );

  return (
    <SelectFilterChip
      isFilterActive={!!selectedOptions.length}
      icon={<ShoppingBasketOutlinedIcon />}
      label={t('discovery:departments')}
      content={filterContent}
    />
  );
}
