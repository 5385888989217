import { Box, Button, Card, Chip, Typography } from '@mui/material';
import { Link } from 'react-router';
import { useTranslation } from 'react-i18next';
import { fr } from 'date-fns/locale';
import { format } from 'date-fns';
import { useAtomValue } from 'jotai';
import { Business_Profile_Enum } from 'kheops-graphql';
import { FormatBaseUnitQuantity, FormatLogisticalPackaging } from 'kheops-utils';
import i18n from '../i18n';
import LastOrderBgXs from '../assets/images/last_order_bg_xs.png';
import LastOrderBgSm from '../assets/images/last_order_bg_sm.png';
import LastOrderBgMd from '../assets/images/last_order_bg_md.png';
import LastOrderBgLg from '../assets/images/last_order_bg_lg.png';
import LastOrderBgXl from '../assets/images/last_order_bg_xl.png';
import { useLastOrderByContractReferenceIdQuery } from '../queries/__generated__/lastOrderByContractReferenceId.generated';
import useCurrencyFormat from '../hooks/useCurrencyFormat';
import useCurrentBreakpoint from '../hooks/useCurrentBreakpoint';
import { ContractPreview } from '../hooks/useContracts';
import { RoutePaths } from '../routes/AppRoutes';
import SizedImage from '../common/components/SizedImage';
import FileUtils from '../common/utils/file.utils';
import AddOrderToBasketButton from '../common/components/AddOrderToBasketButton';
import { currentContextAtom } from '../state';
import { ellipsisStyle } from '../common/utils/style.utils';

interface LastOrderRecommendationProps {
  contract: ContractPreview;
}

export const BreakpointLastOrderBackgrounds = {
  xs: LastOrderBgXs,
  sm: LastOrderBgSm,
  md: LastOrderBgMd,
  lg: LastOrderBgLg,
  xl: LastOrderBgXl,
};

const PackagingDisplayedNumbersByBreakpoint = {
  xs: 0,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4,
};

export default function LastOrderRecommendation({ contract }: LastOrderRecommendationProps): React.JSX.Element {
  const { t } = useTranslation(['basket', 'common', 'order', 'products']);
  const currencyFormat = useCurrencyFormat();
  const numberFormat = new Intl.NumberFormat(i18n.resolvedLanguage, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });
  const { realm } = useAtomValue(currentContextAtom);
  const breakpoint = useCurrentBreakpoint();
  const { data } = useLastOrderByContractReferenceIdQuery({
    variables: { contractReferenceId: contract.reference_id! },
  });

  const hiddenPackagingsCount = data?.order.length ? data.order[0].items.length - PackagingDisplayedNumbersByBreakpoint[breakpoint] : 0;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!!data?.order.length && (
        <Box
          sx={{
            mb: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
              mt: 1.5,
              gap: 1,
              justifyContent: 'space-between',
              alignItems: {
                xs: 'start',
                sm: 'center',
              },
            }}
          >
            <Typography variant="titleLarge">{t('order:your_last_order')}</Typography>
            <Button
              component={Link}
              variant="outlined"
              to={`${RoutePaths.ORDER_LIST}?companyId=${realm === Business_Profile_Enum.Buyer ? contract.supplyingCompanyId : contract.buyingCompanyId}`}
            >
              {t('order:see_all_orders')}
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              pt: 1.5,
            }}
          >
            <Box
              sx={{
                flexShrink: 0,
                display: 'flex',
                backgroundColor: 'surfaceContainer.main',
                backgroundImage: `url(${BreakpointLastOrderBackgrounds[breakpoint]})`,
                backgroundSize: 640,
                backgroundPosition: 'bottom',
                backgroundRepeat: 'no-repeat',
                flexDirection: 'column',
                borderRadius: 6,
                p: 2,
                flex: { xs: 1, sm: 'initial' } }}
            >
              <Typography variant="titleMedium">{format(data.order[0].created_at, 'dd MMMM yyyy', { locale: fr })}</Typography>
              <Typography variant="titleSmall" color="primary" sx={{ mb: 1.5 }}>{currencyFormat.format(data.order[0].total_price_excl_tax!)}</Typography>
              <AddOrderToBasketButton orderReferenceId={data.order[0].reference_id} />
            </Box>
            <Box
              sx={{
                flex: 1,
                minWidth: 0,
                display: {
                  xs: 'none',
                  sm: 'flex',
                },
                gap: 2,
              }}
            >
              {data.order[0].items.slice(0, PackagingDisplayedNumbersByBreakpoint[breakpoint]).map(({ packaging }) => (
                <Card
                  sx={{
                    display: 'flex',
                    flex: 1,
                    gap: 1,
                    borderRadius: 6,
                    p: 2,
                    minWidth: 0,
                  }}
                >
                  <SizedImage
                    src={FileUtils.BuildPackagingImageUrl({
                      photo: packaging.base_unit?.main_photo,
                      type: packaging.product!.sub_family!,
                      size: { height: 56, fit: 'contain' },
                    })}
                    height={56}
                    width={56}
                    alt={t('products:csu')}
                  />
                  <Box sx={{ minWidth: 0 }}>
                    <Typography
                      variant="bodyMedium"
                      sx={{
                        ...ellipsisStyle,
                        mb: 0.5,
                      }}
                    >
                      {packaging.product!.name}
                    </Typography>
                    <Chip
                      variant="tag"
                      label={FormatBaseUnitQuantity(packaging.base_unit!, 'fr', numberFormat)}
                      sx={{ mb: 1 }}
                    />
                    <Typography variant="bodySmall" color="secondary">
                      {FormatLogisticalPackaging(packaging, 'fr', packaging.base_unit!)}
                    </Typography>
                  </Box>
                </Card>
              ))}
              {hiddenPackagingsCount > 0 && (
                <Typography
                  variant="labelLarge"
                  color="secondary"
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'block',
                    },
                    alignSelf: 'center',
                  }}
                >
                  +{hiddenPackagingsCount}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
