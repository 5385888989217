import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { Accordion, AccordionDetails, AccordionSummary, accordionSummaryClasses, Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import { Contact } from './CompanyContactList';
import { useGetUserContactInformationQuery } from '../queries/__generated__/getUserContactInformation.generated';
import PhotosUtils from '../common/utils/photos.utils';

export interface CompanyContactProps {
  contact: Contact;
  displayContactInfo?: boolean;
}

export default function CompanyContact({ contact, displayContactInfo }: CompanyContactProps): React.JSX.Element {
  const { data: userContactInfoData } = useGetUserContactInformationQuery({
    variables: {
      userId: contact.id,
    },
    skip: !displayContactInfo,
  });

  const contactInfo = userContactInfoData?.getUserContactInformation;

  if (!contactInfo || (!contactInfo.email && !contactInfo.landlinePhoneNumber && !contactInfo.mobilePhoneNumber)) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, py: 1, px: 2 }} key={contact.id}>
        <Avatar src={PhotosUtils.getUserAvatarPicture(contact)} size={48} />
        <Typography variant="bodyMedium">
          {contact.first_name} {contact.last_name}
        </Typography>
      </Box>
    );
  }

  return (
    <Accordion
      disableGutters
      elevation={0}
      sx={{
        '&::before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon sx={{ color: 'text.primary' }} />}
        sx={{
          [`& .${accordionSummaryClasses.content}`]: {
            alignItems: 'center',
            gap: 2,
            m: 0,
          },
          px: 2,
          py: 1,
        }}
      >
        <Avatar src={PhotosUtils.getUserAvatarPicture(contact)} size={40} />
        <Typography variant="bodyMedium">
          {contact.first_name} {contact.last_name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="bodyMedium" color="secondary">
          📞 {userContactInfoData?.getUserContactInformation?.mobilePhoneNumber || '-'}
        </Typography>
        <Typography variant="bodyMedium" color="secondary">
          ☎️ {userContactInfoData?.getUserContactInformation?.landlinePhoneNumber || '-'}
        </Typography>
        <Typography variant="bodyMedium" color="secondary">
          ✉️ {userContactInfoData?.getUserContactInformation?.email || '-'}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
