import { Box, Card, Chip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { AlgoliaHit } from 'instantsearch.js';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router';
import { Business_Profile_Enum, Contract_Status_Enum } from 'kheops-graphql';
import { formatPriceByBillingType } from 'kheops-utils';
import FileUtils from '../../common/utils/file.utils';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';
import { ProductHit } from '../search';
import QualityLabels from './QualityLabels';
import { ProductHitInfo, SearchProductHitOptions } from './SearchProductHit';
import UpdateBasketButton from './UpdateBasketButton';
import SizedImage from '../../common/components/SizedImage';
import { ellipsisStyle, linkStyle } from '../../common/utils/style.utils';
import { formatPackagingPriceIncludingDescriptor } from '../../common/utils/common.utils';
import SearchUtils from '../../common/utils/search.utils';

type SearchProductCardProps = SearchProductHitOptions
& {
  hit: AlgoliaHit<ProductHit>;
  productHitInfo: ProductHitInfo;
  contractStatus?: Contract_Status_Enum;
};

const imageSizes = {
  xs: 94,
  sm: 138,
};

export default function SearchProductCard({ hit, productHitInfo, contractStatus, hideCompany }: SearchProductCardProps): React.JSX.Element {
  const { t } = useTranslation(['packaging', 'products', 'common', 'contracts']);
  const { pathname: origin } = useLocation();
  const currencyFormat = useCurrencyFormat();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const detailedPrice = useMemo(() => {
    const isContractualized = contractStatus === Contract_Status_Enum.Signed;

    if (!isContractualized) {
      if (hit.suggestedRetailPrice) {
        return (
          <Typography variant="bodySmall" color="onSurfaceVariant">
            {t('price:suggest_retail_price_acronym')} : {SearchUtils.getFormatedSuggestedRetailPrice(hit, currencyFormat.format)}
          </Typography>
        );
      }

      return undefined;
    }

    return (
      <Typography variant="bodySmall" color="onSurfaceVariant">
        {formatPackagingPriceIncludingDescriptor(t, formatPriceByBillingType(currencyFormat.format(Number(hit.price)), hit.billingType), hit.packagingTradeItemUnitDescriptor)}
      </Typography>
    );
  }, [contractStatus, hit]);

  return (
    <Card sx={{
      px: {
        xs: 1.5,
        sm: 2,
      },
      py: {
        xs: 2,
        sm: 2,
      },
      my: 0.5,
      boxSizing: 'border-box',
      boxShadow: 'unset',
    }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: {
            xs: 'row',
            sm: 'column',
          },
        }}
      >
        <Box
          component={Link}
          to={productHitInfo.productUrl}
          state={{ origin }}
          sx={{
            height: imageSizes,
            width: {
              xs: imageSizes,
              sm: '100%',
            },
          }}
        >
          <SizedImage
            src={FileUtils.BuildPackagingImageUrl({ photo: hit.photo, type: hit.family, size: { height: 264, fit: 'contain' } })}
            height={imageSizes}
            width={imageSizes}
            borderRadius={0}
            sx={{
              margin: 'auto',
              maxHeight: imageSizes,
              maxWidth: imageSizes,
            }}
            alt={t('common:product_one')}
          />
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              flexDirection: {
                xs: 'row',
                sm: 'column',
              },
              position: {
                sm: 'absolute',
              },
              top: {
                sm: 0,
              },
              left: {
                sm: 0,
              },
            }}
          >
            <QualityLabels labels={hit.labels} size={isXs ? 24 : 32} />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            ml: {
              xs: 1,
              sm: 0,
            },
            pt: {
              sm: 3,
            },
            minWidth: 0,
          }}
        >
          {!hideCompany && (
            <Box
              component={Link}
              to={productHitInfo.companyUrl}
              sx={{
                maxWidth: '100%',
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                ...linkStyle,
              }}
            >
              <Box
                component="img"
                src={FileUtils.BuildCompanyImageUrl({ photo: hit.companyPhoto, type: Business_Profile_Enum.Supplier, size: { height: 64, fit: 'contain' } })}
                sx={{
                  height: 18,
                  width: 18,
                  borderRadius: '50%',
                  marginRight: 0.5,
                }}
                alt={t('common:company')}
              />
              <Typography variant="bodySmall" sx={{ ...ellipsisStyle }}>
                {hit.company}
              </Typography>
            </Box>
          )}
          <Box
            component={Link}
            to={productHitInfo.productUrl}
            state={{ origin }}
            sx={{
              pt: 0.5,
              pb: 1,
              ...linkStyle,
            }}
          >
            <Typography
              variant="bodyMedium"
              sx={{
                ...ellipsisStyle,
              }}
            >
              {hit.name}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', gap: 1 }}>
            <Chip variant="tag" label={hit.baseUnit} />
            <Typography variant="bodySmall" color="onSurfaceVariant">
              {hit.packaging}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <Typography variant="titleMedium" color="primary" sx={{ mb: 0.5 }}>
                  {productHitInfo.price}
                </Typography>
                {detailedPrice}
              </Box>
              {contractStatus === Contract_Status_Enum.Signed && (
                <UpdateBasketButton packagingId={hit.packaging_id} supplyingCompanyId={hit.company_id} />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
