import ViewHeadlineRoundedIcon from '@mui/icons-material/ViewHeadlineRounded';
import { Box, Checkbox, Divider, FormControlLabel, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRefinementList } from 'react-instantsearch';
import useNormalizedCurrentRefinements from '../../hooks/useNormalizedCurrentRefinements';
import FilterDisplay from './FilterDisplay';

export default function GtinFilter(): React.JSX.Element {
  const attribute = 'hasGtin';
  const { t } = useTranslation(['common', 'search']);
  const { refine } = useRefinementList({ attribute });
  const currentRefinements = useNormalizedCurrentRefinements(attribute);

  const gtinOptions = useMemo(() => (
    ['true', 'false']
      .map((value, index, array) => (
        <>
          <FormControlLabel
            key={value}
            control={<Checkbox checked={currentRefinements.includes(value)} />}
            onChange={() => refine(value)}
            label={value === 'true' ? t('search:product_with_gtin') : t('search:product_without_gtin')}
            sx={{ ml: 0 }}
          />
          {index < array.length - 1 && <Divider />}
        </>
      ))
  ), [currentRefinements]);

  return (
    <FilterDisplay
      icon={<ViewHeadlineRoundedIcon sx={{ transform: 'rotate(90deg)' }} />}
      label={t('common:barcode')}
      activeItemCount={currentRefinements.length}
      content={(
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ m: 1 }} variant="titleMedium">
            {t('search:select_products_to_display')}
          </Typography>
          {gtinOptions}
        </Box>
      )}
    />
  );
}
