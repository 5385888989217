/* eslint-disable react/jsx-no-useless-fragment */
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Box, Button, Chip, Container, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useAtomValue } from 'jotai';
import { Business_Profile_Enum } from 'kheops-graphql';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useNavigate } from 'react-router';
import FileUtils from '../common/utils/file.utils';
import useCurrencyFormat from '../hooks/useCurrencyFormat';
import { RoutePaths } from '../routes/AppRoutes';
import { lastOrdersOrderedAtom } from './state/state';
import { OrderedCompany } from './state/basket';
import SizedImage from '../common/components/SizedImage';
import OrderExportButton from '../orders/tracking-page/OrderExportButton';
import { longDateFormat } from '../common/state/state';
import CompanyLogo from '../common/components/CompanyLogo';

const wordingsOrderConfirmationPage = {
  classicOrder: {
    title: 'basket:thanks_for_order',
    description: 'basket:thanks_for_order_description',
    listHeader: 'basket:your_orders',
    orderButtonLink: 'basket:see_your_order',
  },
  suggestionOrder: {
    title: 'basket:thanks_for_your_order_suggestion',
    description: 'basket:thanks_for_your_order_suggestion_description',
    listHeader: 'basket:your_order_suggestions',
    orderButtonLink: 'basket:see_your_order_suggestion',
  },
};

export default function OrdersConfirmationPage(): React.JSX.Element {
  const { t } = useTranslation(['basket', 'common', 'order']);
  const navigate = useNavigate();
  const currencyFormat = useCurrencyFormat();
  const orders = useAtomValue(lastOrdersOrderedAtom);

  useEffect(() => {
    if (!orders.length) {
      navigate(RoutePaths.ROOT);
    }
  }, []);

  const areOrdersSuggested = orders[0].isOrderSuggested;
  const wordings = areOrdersSuggested ? wordingsOrderConfirmationPage.suggestionOrder : wordingsOrderConfirmationPage.classicOrder;
  const photoSize = {
    height: 74,
    width: 74,
  };

  const orderList = orders.map((order) => {
    let companyToDisplay: OrderedCompany;
    let placeholderType: Business_Profile_Enum;

    if (areOrdersSuggested) {
      companyToDisplay = order.contract.buying_company;
      placeholderType = Business_Profile_Enum.Buyer;
    } else {
      companyToDisplay = order.contract.supplying_company;
      placeholderType = Business_Profile_Enum.Supplier;
    }

    return (
      <Box
        key={companyToDisplay.tradeName}
        sx={{
          backgroundColor: 'surfaceContainerLowest.main',
          borderRadius: 4,
          mb: 2,
          p: 2,
          display: 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          alignItems: {
            xs: 'start',
            sm: 'center',
          },
        }}
      >
        <Box sx={{ display: 'flex', flex: 1 }}>
          <SizedImage
            src={
              FileUtils.BuildCompanyImageUrl({
                photo: companyToDisplay.photos[0]?.photo,
                size: photoSize,
                type: placeholderType,
              })
            }
            alt={t('common:company')}
            height={photoSize.height}
            width={photoSize.width}
            overlay={companyToDisplay.brand && (
              <CompanyLogo
                variant="square"
                size="small"
                brand={companyToDisplay.brand}
                sx={{
                  position: 'absolute',
                  bottom: 2,
                  right: 8,
                }}
              />
            )}
            sx={{
              borderRadius: 3,
              '& .Kheops-brand': {
                width: 32,
                height: 32,
                borderRadius: 3,
                position: 'absolute',
                bottom: 8,
                right: 8,
              },
              '& .Kheops-brandLogo': {
                width: 18,
              },
            }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
            <Typography variant="bodyMedium">
              {companyToDisplay.tradeName}
            </Typography>
            <Typography variant="bodyMedium" color="secondary">
              {t('basket:item', { count: order.packagingCount, value: order.packagingCount })}
            </Typography>
            {order.deliveryDate && (<Typography variant="bodyMedium" color="text.secondary">{`${t('order:proposed_delivery')} : ${format(order.deliveryDate, longDateFormat)}`}</Typography>)}
            <Typography variant="labelLarge" color="primary">
              {currencyFormat.format(order.totalPrice)}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: {
              sm: 'inherit',
              xs: '100%',
            },
            flexDirection: {
              xs: 'row',
              sm: 'column',
            },
            justifyContent: 'space-between',
            gap: 1,
            mt: {
              xs: 1,
              sm: 0,
            },
            '& > *': {
              width: {
                sm: 'inherit',
                xs: '50%',
              },
            },
          }}
        >
          <Chip
            variant="assistive"
            label={t(wordings.orderButtonLink)}
            onClick={() => navigate(generatePath(RoutePaths.ORDER_TRACKING, { orderReferenceId: order.referenceId }))}
          />
          <OrderExportButton
            orderReferenceId={order.referenceId}
            orderFriendlyId={order.friendlyId}
            orderId={order.id}
            variant="chip"
          />
        </Box>

      </Box>
    );
  });

  return (
    <Container
      sx={{
        width: {
          sm: 600,
          lg: 800,
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: 'primaryContainer.main',
          borderRadius: 4,
          mt: 3,
          py: 3,
          px: 2,
          textAlign: 'center',
        }}
      >
        <Typography
          variant="headlineSmall"
          sx={{ mb: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          color="onPrimaryContainer"
        >
          <TaskAltIcon
            color="primary"
            sx={{
              width: 24,
              height: 24,
              marginRight: 1,
              display: {
                xs: 'none',
                sm: 'block',
              },
            }}
          />
          {t(wordings.title, { count: orders.length })}
        </Typography>
        <Typography variant="bodyMedium" color="onPrimaryContainer">
          {t(wordings.description, { count: orders.length })}
        </Typography>
        <Button
          variant="contained"
          component={Link}
          sx={{ mt: 3 }}
          to={RoutePaths.ORDER_LIST}
        >
          {t('basket:see_orders_list')}
        </Button>
      </Box>
      <Typography variant="titleLarge" sx={{ mt: 2 }}>
        {t(wordings.listHeader)}
      </Typography>
      <Box sx={{ mb: 2, mt: 1 }}>
        {orderList}
      </Box>
    </Container>
  );
}
