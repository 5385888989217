import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { RoutePaths } from '../../../routes/AppRoutes';
import { PackagingFamilyTypeIntl } from '../../../common/i18n/product-family.translation';
import ProductVatRate from './ProductVatRate';
import ProductIngredients from './ProductIngredients';
import ProductAllergens from './ProductAllergens';
import ProductNutritionFacts from './ProductNutritionFacts';
import ProductLabels from './ProductLabels';
import { ProductFragment } from '../../products';
import ProductDescription from './ProductDescription';
import ShelfLifeDurationDisplay from './ShelfLifeDurationDisplay';

interface ProductInfoDialogProps {
  product: ProductFragment;
  open: boolean;
  onClose: () => void;
}

export default function ProductInfoDialog({ product, open, onClose }: ProductInfoDialogProps): React.JSX.Element {
  const { t } = useTranslation(['products']);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography>{t('products:product_details')}</Typography>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', rowGap: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <Typography variant="headlineSmall" sx={{ mb: 1 }}>
            {product.name}
          </Typography>
          <Chip
            variant="tag"
            label={t(`product-family:${PackagingFamilyTypeIntl[product.sub_family as Product_Sub_Family_Name_Enum]}`)}
          />
        </Box>
        {product.description && <ProductDescription description={product.description} />}
        {!!product.labels.length && (
          <ProductLabels labels={product.labels} />
        )}
        <ProductVatRate vatRate={product.vat_rate!} />
        <ShelfLifeDurationDisplay
          titleVariant="titleMedium"
          duration={product.shelf_life_duration}
          durationType={product.shelf_life_duration_type}
        />
        {product.ingredients && <ProductIngredients ingredients={product.ingredients} />}
        {product.allergens && <ProductAllergens allergens={product.allergens} />}
        <ProductNutritionFacts product={product} />
      </DialogContent>
      <DialogActions sx={{ py: 2, justifyContent: 'center' }}>
        <Button onClick={onClose} variant="text">
          {t('common:close')}
        </Button>
        <Button
          variant="outlined"
          startIcon={<EditIcon />}
          component={Link}
          to={`${RoutePaths.PRODUCT_ADD_INFO}?productId=${product.id}`}
        >
          {t('common:modify')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
