import { useTranslation } from 'react-i18next';
import { Box, Card, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Address, Administrative_Area, Business_Profile_Enum, Company, Contract_Status_Enum, Delivery_Zone, Maybe } from 'kheops-graphql';
import { useMemo } from 'react';
import SizedImage from '../../common/components/SizedImage';
import FileUtils from '../../common/utils/file.utils';
import ContractStatusTag from '../../search/ContractStatusTag';
import CompanyContactInfo from '../CompanyContactInfo';
import CompanyInfoChips from '../CompanyInfoChips';
import ImageDarkOverlay from '../../common/components/ImageDarkOverlay';
import ContactBox from './ContactBox';
import SearchUtils from '../../common/utils/search.utils';
import { UserDeliveryZone } from '../../common/models/user.models';
import { currentContextAtom } from '../../state';
import { ContractPreview } from '../../hooks/useContracts';
import { DisplayPhoto } from '../../common/common';
import BackButton from '../../common/components/BackButton';
import { RoutePaths } from '../../routes/AppRoutes';
import CompanyLogo from '../../common/components/CompanyLogo';

export interface CompanyDeliveryZone {
  administrative_areas: Pick<Administrative_Area, 'area'>[];
}

export type CompanyAddress = Pick<Address, 'administrativeAreaLevel2' | 'formattedAddress' | 'latitude' | 'longitude' | 'locality' | 'postalCode' | 'countryCode'>;

export type CompanyInfo = Pick<
  Company,
  'business_profile' | 'contactEmail' | 'contactPhoneNumber' | 'tradeName' | 'description' | 'brand' | 'id' | 'legalName'
> & {
  photos?: Maybe<DisplayPhoto[]>;
  addresses: CompanyAddress[];
  delivery_zones: (Pick<Delivery_Zone, 'minimum_order_value'> & CompanyDeliveryZone)[];
};

interface CompanyInfoCardProps {
  company: CompanyInfo;
  contract?: ContractPreview;
}

export function CompanyInfoCard({ company, contract }: CompanyInfoCardProps): React.JSX.Element {
  const theme = useTheme();
  const isSmallerThanLg = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation(['common', 'contracts']);
  const context = useAtomValue(currentContextAtom);
  const canDeliver = useMemo(() => {
    if (contract?.status === Contract_Status_Enum.Signed) {
      return true;
    }
    if (context.realm === Business_Profile_Enum.Buyer) {
      return SearchUtils.canItDeliverAnArea(company.delivery_zones as UserDeliveryZone[], context.companyAddress.administrativeAreaLevel2!);
    }

    return SearchUtils.canItDeliverAnArea(context.deliveryZones, company.addresses[0].administrativeAreaLevel2!);
  }, [contract]);

  const backTo = context.realm === Business_Profile_Enum.Buyer
    ? RoutePaths.DISCOVERY_SUPPLIERS
    : RoutePaths.DISCOVERY_BUYERS;

  return (
    <Card
      sx={{
        display: 'flex',
        p: { xs: 2, lg: 3 },
        backgroundColor: 'surfaceContainer.main',
        flexDirection: {
          xs: 'column',
          lg: 'row',
        },
        justifyContent: 'space-between',
        mb: 2,
        gap: {
          xs: 2,
        },
      }}
    >
      <Box sx={{ display: 'flex', flex: 1, position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            top: theme.spacing(2),
            left: theme.spacing(2),
            zIndex: 9,
            display: {
              xs: 'none',
              lg: 'block',
            },
          }}
        >
          <BackButton to={backTo} variant="tonal" />
        </Box>
        <SizedImage
          src={
            FileUtils.BuildCompanyImageUrl({
              photo: company.photos ? company.photos[0]?.photo : undefined,
              type: company.business_profile,
              size: { height: 332, width: 332 },
            })
          }
          alt={t('common:company')}
          height={{ xs: 112, lg: 256 }}
          width={{ xs: 112, lg: 256 }}
          sx={{
            mr: 2,
          }}
          overlay={(
            <>
              {company.brand && (
                <CompanyLogo
                  brand={company.brand}
                  size={isSmallerThanLg ? 'medium' : 'large'}
                  variant="square"
                  sx={{
                    position: 'absolute',
                    bottom: {
                      xs: 8,
                      lg: 18,
                    },
                    right: {
                      xs: 8,
                      lg: 18,
                    },
                    width: {
                      xs: 40,
                      sm: 48,
                      lg: 72,
                    },
                  }}
                />
              )}
              {!canDeliver && <ImageDarkOverlay label={t(context.realm === Business_Profile_Enum.Buyer ? 'discovery:dont_deliver_in_your_area' : 'discovery:out_of_your_delivery_zones')} />}
            </>
          )}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
          <Box>
            <Typography variant="titleLarge" sx={{ mb: 1 }}>
              {contract && (
                <ContractStatusTag
                  status={contract.status}
                  size={{
                    xs: 18,
                    sm: 24,
                  }}
                  sx={{
                    mt: {
                      xs: 0,
                      sm: 0.25,
                    },
                    mr: 1,
                    verticalAlign: 'top',
                  }}
                />
              )}
              {company.tradeName}
            </Typography>
            <CompanyInfoChips company={company} contract={contract} />
            <Typography variant="bodyMedium" color="secondary">
              📍 {`${company.addresses[0].locality}, ${company.addresses[0].countryCode} (${company.addresses[0].postalCode})`}
            </Typography>
          </Box>
          {!isSmallerThanLg && (
            <>
              <CompanyContactInfo company={company} contract={contract} />
              <ContactBox company={company} contract={contract} />
            </>
          )}
        </Box>
      </Box>
      {isSmallerThanLg && (
        <>
          <CompanyContactInfo company={company} contract={contract} />
          <ContactBox company={company} contract={contract} />
        </>
      )}
    </Card>
  );
}
