import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Basket_Item_Insert_Input, Maybe } from 'kheops-graphql';
import { useCallback, useEffect, useRef } from 'react';
import { debounceTime, switchMap, tap } from 'rxjs';
import { createSignal } from '@react-rxjs/utils';
import * as Sentry from '@sentry/react';
import { currentContextAtom, userAtom } from '../state';
import { BasketDocument, BasketQuery, useBasketLazyQuery } from '../queries/__generated__/basket.generated';
import { useCreateBasketMutation } from '../mutations/__generated__/createBasket.generated';
import { basketDataAtom, currentBasketItemsAtom, isBasketLoadingAtom, isRemoteBasketEmptyAtom } from './state/state';
import { useUpdateBasketMutation } from '../mutations/__generated__/updateBasketItems.generated';

interface BasketMutationParams {
  items: Basket_Item_Insert_Input | Basket_Item_Insert_Input[];
  deletedIds: string[],
  id: string;
}

const [basketItems$, postNewBasketItems] = createSignal<BasketMutationParams>();

export default function useBasket(): void {
  const setIsRemoteBasketEmpty = useSetAtom(isRemoteBasketEmptyAtom);
  const [basketItems, setBasketItems] = useAtom(
    currentBasketItemsAtom,
  );
  const { id: userId } = useAtomValue(userAtom);
  const { companyId } = useAtomValue(currentContextAtom);
  const [getBasket, { data: dataBasket, loading: basketLoading, error: basketQueryError }] = useBasketLazyQuery({
    variables: { userId, companyId },
  });
  const [createBasket, { loading: createBasketLoading }] = useCreateBasketMutation({ refetchQueries: [BasketDocument], awaitRefetchQueries: true });
  const [updateBasketItems, { loading: updateBasketLoading }] = useUpdateBasketMutation();

  const loading = basketLoading || createBasketLoading || updateBasketLoading;
  const setIsBasketLoading = useSetAtom(isBasketLoadingAtom);

  const setBasketData = useSetAtom(basketDataAtom);

  const updateBasketControllerRef = useRef<AbortController>(undefined);

  useEffect(() => {
    if (userId) {
      getBasket();
    }
  }, [userId]);

  const updateLocalBasket = useCallback((basket?: Maybe<BasketQuery['basket'][number]>) => {
    setIsRemoteBasketEmpty(!basket?.items.length);

    if (basket) {
      setBasketItems(
        ...basket.items.map(({ id, packaging_id, quantity, contract_id }) => ({
          id,
          packagingId: packaging_id,
          quantity,
          contractId: contract_id,
        })),
      );
    }

    setBasketData(basket || undefined);
  }, []);

  useEffect(() => {
    if (!userId || loading || !dataBasket) {
      return;
    }

    const basket = dataBasket?.basket[0];

    if (!basket && !basketQueryError) {
      createBasket({ variables: { items: [], userId, company_id: companyId } });
      setBasketItems();
    }

    updateLocalBasket(basket);
  }, [loading, dataBasket, basketQueryError]);

  useEffect(() => {
    if (!userId) {
      return;
    }

    const basket = dataBasket?.basket[0];
    const items: Basket_Item_Insert_Input[] = [];
    const deletedIds: string[] = [];

    Array.from(basketItems.entries()).forEach(([, { id, packagingId, quantity, contractId }]) => {
      if (quantity) {
        items.push({
          id,
          packaging_id: packagingId,
          basket_id: basket?.id,
          quantity,
          contract_id: contractId,
        });
      } else if (id) {
        deletedIds.push(id);
      }
    });

    const basketHasChanged = basket
      && (basket.items.length !== basketItems.size || basket.items.some((item) => {
        const newItem = basketItems.get(
          `${item.packaging_id}_${item.contract_id}`,
        );

        return newItem?.quantity !== item.quantity;
      }));

    if (basketHasChanged) {
      postNewBasketItems({ items, id: basket!.id, deletedIds });
    }
  }, [userId, basketItems]);

  useEffect(() => {
    const updateBasketResult$ = basketItems$.pipe(
      tap(() => {
        if (updateBasketControllerRef.current && !updateBasketControllerRef.current.signal.aborted) {
          updateBasketControllerRef.current.abort('New basket update queued');
        }
      }),
      debounceTime(500),
      switchMap(async (param) => {
        const controller = new AbortController();
        updateBasketControllerRef.current = controller;

        try {
          const { data } = await updateBasketItems({
            variables: { ...param!, now: new Date().toISOString() },
            context: {
              fetchOptions: {
                signal: controller.signal,
              },
            },
          });

          return {
            data,
            controller,
          };
        } catch (error) {
          Sentry.captureException(error);
        }
      }),
      tap((response) => {
        if (response?.data && !response.controller.signal.aborted) {
          const basket = response.data.update_basket_by_pk;

          updateLocalBasket(basket);
        }

        delete updateBasketControllerRef.current;
      }),
    );

    const noop = (): void => { /* noop */ };
    // subscribe to trigger observable, but we don't need the result, as everything is plugged on apollo's hooks
    const subscriptions = [
      updateBasketResult$.subscribe(noop),
    ];

    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  useEffect(() => {
    setIsBasketLoading(loading);
  }, [loading]);
}
