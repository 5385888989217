import { useAtom, useSetAtom } from 'jotai';
import { Button } from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useTranslation } from 'react-i18next';
import { sendbirdSelectors, useSendbirdStateContext } from '@sendbird/uikit-react';
import { Chat_Message_Custom_Type } from 'kheops-utils';
import ConfirmDialog from '../common/components/ConfirmDialog';
import useOpenable from '../hooks/useOpenable';
import { channelListQueryParamsAtom, currentChannelAtom } from './state';
import { commonSnackbarPropsAtom } from '../common/state/state';

export interface LeaveChannelButtonProps {
  companyName: string;
}

export default function LeaveChannelButton({ companyName }: LeaveChannelButtonProps): React.JSX.Element {
  const { t } = useTranslation(['chat', 'common']);
  const { open, close, isOpen } = useOpenable();
  const globalStore = useSendbirdStateContext();
  const sdkInstance = sendbirdSelectors.getSdk(globalStore);
  const [channelListQueryParams, setChannelListQueryParams] = useAtom(channelListQueryParamsAtom);
  const [currentChannel, setCurrentChannel] = useAtom(currentChannelAtom);
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);

  const handleConfirm = async (confirm: boolean): Promise<void> => {
    if (confirm) {
      await new Promise((resolve) => {
        const request = currentChannel!.sendUserMessage({
          message: t('chat:someone_has_left_the_conversation', { name: sdkInstance?.currentUser?.nickname }),
          customType: Chat_Message_Custom_Type.LEFT,
        });

        request.onSucceeded(resolve);
      });
      await currentChannel!.leave(true);
      setCurrentChannel(undefined);

      // force a channel list reset
      setChannelListQueryParams({
        ...channelListQueryParams,
      });

      setCommonSnackbarProps({
        label: t('chat:leave_conversation_success'),
        snackbarProps: {
          open: true,
        },
      });
    }

    close();
  };

  return (
    <>
      <Button variant="text" onClick={open}>
        <LogoutOutlinedIcon />
      </Button>
      <ConfirmDialog
        open={isOpen}
        onClose={handleConfirm}
        cancelLabel={t('common:cancel')}
        confirmLabel={t('common:quit')}
        confirmIcon={<LogoutOutlinedIcon />}
        title={t('chat:leave_conversation')}
        subTitle={t('chat:leave_conversation_description', { company: companyName })}
      />
    </>
  );
}
