import { Button, Menu, MenuItem } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { RoutePaths } from '../routes/AppRoutes';

interface ProductUpdateMenuProps {
  productId: string;
  baseUnitSku: number;
  origin: string;
}

const links = [
  {
    route: RoutePaths.PRODUCT_ADD_INFO,
    label: 'products:general_specifications',
  },
  {
    route: RoutePaths.PRODUCT_ADD_PACKAGING,
    label: 'products:base_unit_one',
  },
];

export default function ProductUpdateMenu({ productId, baseUnitSku, origin }: ProductUpdateMenuProps) : React.JSX.Element {
  const { t } = useTranslation(['products', 'price']);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const linksMap = useMemo(() => (
    links.map(({ route, label }) => (
      <MenuItem
        key={route}
        component={Link}
        to={`${route}?productId=${productId}&baseUnitSku=${baseUnitSku}`}
        state={{ origin }}
      >
        {t(label)}
      </MenuItem>
    ))
  ), [productId, origin, baseUnitSku]);

  return (
    <>
      <Button variant="outlined" onClick={handleClick} sx={{ ml: 1 }}>
        <MoreVertIcon />
      </Button>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ mt: 1 }}
      >
        {linksMap}
      </Menu>
    </>
  );
}
