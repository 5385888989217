import { Box, Chip, Tooltip, Typography } from '@mui/material';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import { AlgoliaHit } from 'instantsearch.js';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import { Base_Unit_Type_Enum, Business_Profile_Enum, Contract_Status_Enum, Measurement_Unit_Enum } from 'kheops-graphql';
import { FormatMeasurementUnit, formatPriceByBillingType } from 'kheops-utils';
import FileUtils from '../../common/utils/file.utils';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';
import { ProductHit } from '../search';
import { ProductHitInfo, SearchProductHitOptions } from './SearchProductHit';
import UpdateBasketButton from './UpdateBasketButton';
import { ellipsisStyle, linkStyle } from '../../common/utils/style.utils';
import { formatPackagingPriceIncludingDescriptor } from '../../common/utils/common.utils';

interface SearchProductCardProps extends SearchProductHitOptions {
  hit: AlgoliaHit<ProductHit>;
  productHitInfo: ProductHitInfo;
  contractStatus?: Contract_Status_Enum;
}

export default function SearchProductRow({ hit, productHitInfo, contractStatus, hideCompany }: SearchProductCardProps): React.JSX.Element {
  const { t } = useTranslation(['products', 'contracts']);
  const currencyFormat = useCurrencyFormat();
  const priceComponent = useMemo(() => {
    const isContractSigned = contractStatus === Contract_Status_Enum.Signed;

    if (hit.baseUnitType === Base_Unit_Type_Enum.Bulk && !isContractSigned) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }

    return (
      <Tooltip
        title={t(isContractSigned ? 'products:price_per_packaging' : 'products:price_per_volume')}
      >
        <Typography variant="bodySmall">
          {isContractSigned
            ? formatPackagingPriceIncludingDescriptor(
              t,
              formatPriceByBillingType(currencyFormat.format(Number(hit.price)), hit.billingType),
              hit.packagingTradeItemUnitDescriptor,
            )
            : `${currencyFormat.format(Number(hit.volumePrice.value))}/${FormatMeasurementUnit(hit.volumePrice.unit as Measurement_Unit_Enum, 'fr', 1)}`}
        </Typography>
      </Tooltip>
    );
  }, [contractStatus, hit]);
  const imageSize = 72;

  return (
    <Box sx={{ display: 'flex', width: '100%', gap: 1.5, justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 0 }}>
        <Box
          component={Link}
          to={productHitInfo.productUrl}
          sx={{
            width: imageSize,
            height: imageSize,
            textAlign: 'center',
            mr: 1,
          }}
        >
          <Box
            component="img"
            src={FileUtils.BuildPackagingImageUrl({ photo: hit.photo, type: hit.family, size: { height: 160, fit: 'contain' } })}
            sx={{
              maxWidth: imageSize,
              maxHeight: imageSize,
            }}
          />
        </Box>
        <Box
          sx={{
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
          }}
        >
          {!hideCompany && (
            <Box
              component={Link}
              to={productHitInfo.companyUrl}
              sx={{
                display: 'flex',
                gap: 0.5,
                alignItems: 'center',
                ...linkStyle,
              }}
            >
              <Box
                component="img"
                src={FileUtils.BuildCompanyImageUrl({ photo: hit.companyPhoto, type: Business_Profile_Enum.Supplier, size: { height: 24, fit: 'contain' } })}
                sx={{
                  height: 24,
                  width: 24,
                  borderRadius: '50%',
                }}
                alt={t('common:company')}
              />
              <Typography variant="bodySmall" sx={{ ...ellipsisStyle, textDecoration: 'underline' }}>
                {hit.company}
              </Typography>
            </Box>
          )}
          <Box
            component={Link}
            to={productHitInfo.productUrl}
            sx={{
              ...linkStyle,
            }}
          >
            <Typography variant="bodyMedium" sx={{ ...ellipsisStyle }}>
              {hit.name}
            </Typography>
          </Box>
          {!!hit.gtin && (
          <Box sx={{ display: 'flex', gap: 0.5 }}>
            <DocumentScannerOutlinedIcon sx={{ transform: 'rotate(90deg)', width: 16, height: 16 }} />
            <Typography variant="bodySmall" color="onSurfaceVariant.main">
              {hit.gtin}
            </Typography>
          </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: 0.5 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            minWidth: 132,
          }}
        >
          <Chip variant="tag" label={hit.baseUnit} />
          <Typography variant="bodySmall" color="secondary" sx={{ mt: 0.5, ml: 1 }}>
            {hit.packaging}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: 132, gap: 0.5 }}>
          <Tooltip
            title={t(hit.baseUnitType === Base_Unit_Type_Enum.Bulk ? 'products:price_per_volume' : 'products:price_per_sales_unit')}
            placement="top"
          >
            <Typography variant="titleMedium" color="primary.main">
              {productHitInfo.price}
            </Typography>
          </Tooltip>
          {priceComponent}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end', width: 132 }}>
          {contractStatus === Contract_Status_Enum.Signed && (
            <UpdateBasketButton packagingId={hit.packaging_id} supplyingCompanyId={hit.company_id} />
          )}
        </Box>
      </Box>
    </Box>
  );
}
