import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { Box, DialogContent, DialogTitle, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Business_Profile_Enum, Contract_Status_Enum } from 'kheops-graphql';
import SizedImage from '../../../common/components/SizedImage';
import FileUtils from '../../../common/utils/file.utils';
import { BuyerInfoViewProps } from './BuyerInfoDialog';
import ContractStatusTag from '../../../search/ContractStatusTag';
import { getGMapUrlForAddress } from '../../../common/utils/common.utils';
import CompanyLogo from '../../../common/components/CompanyLogo';
import DialogCloseButton from '../../../common/components/DialogCloseButton';

export default function BuyerInfoGeneralView({ company, close, changeView }: BuyerInfoViewProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'contracts']);
  const companyAddress = company.addresses[0];
  const photo = company.photos && company.photos[0];

  return (
    <>
      <DialogCloseButton onClick={close} />
      <DialogTitle variant="image">
        <SizedImage
          src={FileUtils.BuildCompanyImageUrl({
            photo: photo?.photo,
            type: Business_Profile_Enum.Buyer,
            size: { height: 200, width: 500 },
          })}
          alt={t('common:company')}
          height={200}
          width={500}
          borderRadius={0}
          overlay={
            !!company.brand && (
              <Box sx={{ position: 'absolute', bottom: 16, left: 16, zIndex: 100 }}>
                <CompanyLogo brand={company.brand} variant="square" size="large" sx={{ width: 72 }} />
              </Box>
            )
          }
        />
      </DialogTitle>
      <DialogContent sx={{ maxWidth: 500, p: 0 }}>
        <Box sx={{ px: 2, py: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="titleLarge">
            <ContractStatusTag
              status={Contract_Status_Enum.Signed}
              size={24}
              sx={{ verticalAlign: 'middle', mr: 1 }}
            />
            {company.tradeName}
          </Typography>
          <List
            sx={{
              py: 0,
              '& > *:not(:last-child)': {
                borderBottom: '1px solid',
                borderColor: 'divider',
              },
            }}
          >
            <ListItemButton onClick={() => changeView('CONTACTS')}>
              <ListItemIcon sx={{ minWidth: 48 }}>
                <StorefrontIcon sx={{ color: 'text.primary' }} />
              </ListItemIcon>
              <ListItemText
                primary={t('contracts:company_contacts')}
                primaryTypographyProps={{
                  sx: {
                    lineHeight: '2rem',
                  },
                }}
              />
              <ArrowRightOutlinedIcon />
            </ListItemButton>
            <ListItemButton onClick={() => changeView('DELIVERY')}>
              <ListItemIcon sx={{ minWidth: 48 }}>
                <LocalShippingOutlinedIcon sx={{ color: 'text.primary' }} />
              </ListItemIcon>
              <ListItemText
                primary={t('common:delivery')}
                primaryTypographyProps={{
                  sx: {
                    lineHeight: '2rem',
                  },
                }}
              />
              <ArrowRightOutlinedIcon />
            </ListItemButton>
            <ListItemButton onClick={() => changeView('INVOICING')}>
              <ListItemIcon sx={{ minWidth: 48 }}>
                <ReceiptLongOutlinedIcon sx={{ color: 'text.primary' }} />
              </ListItemIcon>
              <ListItemText
                primary={t('common:billing')}
                primaryTypographyProps={{
                  sx: {
                    lineHeight: '2rem',
                  },
                }}
              />
              <ArrowRightOutlinedIcon />
            </ListItemButton>
            <ListItemButton href={getGMapUrlForAddress(companyAddress.formattedAddress)} target="_blank">
              <ListItemText
                primary={(
                  <>
                    📍&nbsp;
                    <Typography sx={{ textDecoration: 'underline', display: 'inline' }}>
                      {companyAddress.formattedAddress}
                    </Typography>
                  </>
              )}
                primaryTypographyProps={{
                  sx: {
                    lineHeight: '2rem',
                  },
                }}
              />
              <OpenInNewOutlinedIcon />
            </ListItemButton>
            <ListItem>
              <ListItemText
                primary={<>📞 {company.contactPhoneNumber}</>}
                primaryTypographyProps={{
                  sx: {
                    lineHeight: '2rem',
                  },
                }}
              />
            </ListItem>
          </List>
        </Box>
      </DialogContent>
    </>
  );
}
