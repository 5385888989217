import { Box, Grid2, Slide, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router';
import { useApolloClient } from '@apollo/client';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { sendbirdSelectors, useSendbirdStateContext } from '@sendbird/uikit-react';
import Message from '@sendbird/uikit-react/GroupChannel/components/Message';
import { GroupChannel } from '@sendbird/uikit-react/GroupChannel';
import { GroupChannelHandler } from '@sendbird/chat/groupChannel';
import '@sendbird/uikit-react/dist/index.css';
import '../assets/styles/sendbird.css';
import { UserMessage, UserMessageCreateParams } from '@sendbird/chat/message';
import { useGroupChannelListContext } from '@sendbird/uikit-react/GroupChannelList/context';
import { Chat_Message_Custom_Type } from 'kheops-utils';
import { GroupChannelMetadata, currentChannelAtom, currentContractAtom, showChannelMembersAtom } from './state';
import { ContractPropositionInfoDocument, useContractPropositionInfoLazyQuery } from '../queries/__generated__/contractPropositionInfo.generated';
import { PageWrapper } from '../common/components/PageWrapper';
import ChannelList from './ChannelList';
import ChannelHeader from './ChannelHeader';
import ChannelSeparator from './ChannelSeparator';
import OfferMessage from './offer/OfferMessage';
import ConversationActionMessage, { ConversationAction } from './customMessages/ConversationActionMessage';
import AcceptedOfferMessage from './offer/AcceptedOfferMessage';
import ChannelMemberList from './members/ChannelMemberList';
import useHandleBuyerAckNotification from './hooks/useHandleBuyerAckNotification';

interface KheopsChannelProps {
  url: string;
  handleBeforeMessageSent: (message: string) => UserMessageCreateParams;
}

function KheopsChannel({ url, handleBeforeMessageSent }: KheopsChannelProps): React.JSX.Element {
  return (
    <GroupChannel
      channelUrl={url}
      onBeforeSendUserMessage={({ message }) => handleBeforeMessageSent(message)}
      isReactionEnabled={false}
      renderChannelHeader={() => <ChannelHeader />}
      renderCustomSeparator={({ message }) => <ChannelSeparator message={message} />}
      renderMessage={(messageProps) => {
        const { message } = messageProps;

        switch (messageProps.message.customType) {
          case Chat_Message_Custom_Type.OFFER:
            return (
              <Message {...messageProps}>
                <OfferMessage message={message as UserMessage} />
              </Message>
            );
          case Chat_Message_Custom_Type.ACCEPTED_OFFER:
            return (
              <Message {...messageProps}>
                <AcceptedOfferMessage />
              </Message>
            );
          case Chat_Message_Custom_Type.JOINED:
          case Chat_Message_Custom_Type.CREATED:
          case Chat_Message_Custom_Type.LEFT:
          case Chat_Message_Custom_Type.INVITED:
            return (
              <Message {...messageProps}>
                <ConversationActionMessage message={message as UserMessage} action={message.customType as ConversationAction} />
              </Message>
            );
          default:
            return (
              <Message {...messageProps} />
            );
        }
      }}
    />
  );
}

function KheopsChannelList(): React.JSX.Element {
  return (
    <Box
      sx={{
        height: 'calc(100% - 32px)',
        borderRadius: 6,
        p: 2,
        backgroundColor: 'background.paper',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <ChannelList />
    </Box>
  );
}

export default function ChatListPage(): React.JSX.Element {
  const globalStore = useSendbirdStateContext();
  const sdkInstance = sendbirdSelectors.getSdk(globalStore);
  const apolloClient = useApolloClient();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmall = useMediaQuery(theme.breakpoints.down('lg'));
  const [searchParams] = useSearchParams();
  const defaultChannelUrl = searchParams.get('channelUrl');
  const { groupChannels, initialized } = useGroupChannelListContext();
  const [currentChannel, setCurrentChannel] = useAtom(currentChannelAtom);
  const setCurrentContract = useSetAtom(currentContractAtom);
  const showChannelMembers = useAtomValue(showChannelMembersAtom);
  const [contractPropositionInfoQuery, { data: contractPropositionInfoData }] = useContractPropositionInfoLazyQuery({ fetchPolicy: 'cache-and-network' });
  const [hasSetChannelUsingSearchParamOnce, setHasSetChannelUsingSearchParamOnce] = useState(false);
  const { handleBuyerAckNotification } = useHandleBuyerAckNotification();

  const handleBeforeMessageSent = useCallback((message: string): UserMessageCreateParams => {
    handleBuyerAckNotification(currentChannel);

    return { message };
  }, [handleBuyerAckNotification, currentChannel]);

  useEffect(() => {
    if (sdkInstance?.groupChannel) {
      const channelHandlerKey = 'offerReceptionHandler';
      const newGroupChannelHandler = new GroupChannelHandler({
        onMessageReceived: (channel, message) => {
          if (message.customType === Chat_Message_Custom_Type.OFFER || message.customType === Chat_Message_Custom_Type.ACCEPTED_OFFER) {
            apolloClient.refetchQueries({
              include: [ContractPropositionInfoDocument],
            });
          }
        },
      });

      sdkInstance.groupChannel.addGroupChannelHandler(channelHandlerKey, newGroupChannelHandler);

      return (): void => {
        sdkInstance.groupChannel.removeGroupChannelHandler(channelHandlerKey);
      };
    }
  }, [sdkInstance, apolloClient]);

  useEffect(() => {
    if (!initialized) {
      setHasSetChannelUsingSearchParamOnce(false);
    }
  }, [initialized]);

  useEffect((): void => {
    if (defaultChannelUrl && initialized && !hasSetChannelUsingSearchParamOnce) {
      setHasSetChannelUsingSearchParamOnce(true);
      setCurrentChannel(groupChannels.find(({ url }) => defaultChannelUrl === url)!);
    }
  }, [defaultChannelUrl, initialized]);

  useEffect((): void => {
    if (currentChannel) {
      const channelMetadata = currentChannel.cachedMetaData as GroupChannelMetadata;

      contractPropositionInfoQuery({
        variables: {
          buyingCompanyId: channelMetadata.buyingCompanyId,
          supplyingCompanyId: channelMetadata.supplyingCompanyId,
        },
      });
    }
  }, [currentChannel]);

  useEffect((): void => {
    const contract = contractPropositionInfoData?.contract?.[0];

    setCurrentContract(contract);
  }, [contractPropositionInfoData]);

  return (
    <PageWrapper>
      <Grid2
        container
        sx={{
          height: {
            xs: 'calc(100vh - 184px)',
            lg: 'calc(100vh - 120px)',
            flexWrap: 'nowrap',
          },
          position: 'relative',
        }}
        columnSpacing={2}
      >
        {!isXs && (
          <>
            <Grid2 size={{ sm: 5, lg: 4 }} sx={{ height: '100%' }}>
              <KheopsChannelList />
            </Grid2>
            <Grid2
              size={{ sm: showChannelMembers && isSmall ? 0 : 7, lg: showChannelMembers ? 4 : 8 }}
              sx={{
                transition: `${theme.transitions.create('width')}`,
                overflow: 'hidden',
              }}
            >
              <KheopsChannel url={currentChannel?.url || ''} handleBeforeMessageSent={handleBeforeMessageSent} />
            </Grid2>
            <Grid2
              size={{
                sm: showChannelMembers ? 7 : 0,
                lg: showChannelMembers ? 4 : 0,
              }}
              sx={{
                transition: theme.transitions.create('width'),
                maxHeight: '100%',
                overflow: 'hidden',
              }}
            >
              <ChannelMemberList />
            </Grid2>
          </>
        )}
        {isXs && (
          <>
            <Slide direction="right" in={!currentChannel} mountOnEnter unmountOnExit>
              <Grid2 size={{ xs: 12 }} sx={{ position: 'absolute', height: '100%', width: '100%', zIndex: 10 }}>
                <KheopsChannelList />
              </Grid2>
            </Slide>
            <Grid2 size={{ xs: 12 }}>
              <KheopsChannel url={currentChannel?.url || ''} handleBeforeMessageSent={handleBeforeMessageSent} />
            </Grid2>
            <Slide direction="left" in={showChannelMembers} mountOnEnter unmountOnExit>
              <Grid2 size={{ xs: 12 }} sx={{ position: 'absolute', height: '100%', width: '100%', zIndex: 10 }}>
                <ChannelMemberList />
              </Grid2>
            </Slide>
          </>
        )}
      </Grid2>
    </PageWrapper>
  );
}
