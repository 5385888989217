import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import { Brand_Enum, Business_Profile_Enum } from 'kheops-graphql';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Chip } from '@mui/material';
import { useMemo } from 'react';
import { signedContractsAtom } from '../state';
import { getCompanyShortName } from '../common/utils/common.utils';
import CompanyLogo from '../common/components/CompanyLogo';

interface StoresChipProps {
  storesList: { brand: Brand_Enum, tradeName: string }[];
}

export default function StoresChip({ storesList }: StoresChipProps): React.JSX.Element {
  const { t } = useTranslation(['catalogs', 'price']);
  const signedContracts = useAtomValue(signedContractsAtom);

  const { storesChipIcon, storesChipLabel } = useMemo(() => {
    if (storesList.length === 0) {
      return {
        storesChipLabel: t('catalogs:no_store'),
        storesChipIcon: (
          <StoreOutlinedIcon />
        ),
      };
    }

    if (storesList.length === 1) {
      const store = storesList[0];

      return {
        storesChipLabel: getCompanyShortName(store.tradeName, Business_Profile_Enum.Buyer),
        storesChipIcon: !!store.brand && (
          <CompanyLogo size="small" variant="transparent" brand={store.brand} sx={{ width: 24 }} />
        ),
      };
    }

    if (storesList.length === signedContracts.length) {
      return {
        storesChipLabel: t('catalogs:all_stores'),
        storesChipIcon: (
          <StoreOutlinedIcon />
        ),
      };
    }

    return {
      storesChipLabel: t('catalogs:N_store_count', { count: storesList.length }),
      storesChipIcon: (
        <StoreOutlinedIcon />
      ),
    };
  }, [storesList]);

  return (
    <Chip
      variant="tag"
      label={storesChipLabel}
      icon={storesChipIcon}
      sx={{
        '&, & .Kheops-brand': {
          backgroundColor: (theme) => `${theme.palette.surfaceContainerHighest.main}!important`,
        },
      }}
    />
  );
}
