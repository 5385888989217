import { Box, MenuItem, Select, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Business_Profile_Enum, Privacy_Setting_Enum } from 'kheops-graphql';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { ContactInformations } from '../settingsDialog/ContactInformationSettings';
import { currentContextAtom } from '../../state';

type PrivacySettingWordingMap = {
  [setting in Privacy_Setting_Enum]: {
    [context in Business_Profile_Enum]: string;
  }
};

export const PRIVACY_SETTING_WORDING: PrivacySettingWordingMap = {
  EVERYONE: {
    BUYER: 'settings:everyone',
    SUPPLIER: 'settings:everyone',
  },
  CONTRACTUALIZED: {
    BUYER: 'settings:only_my_suppliers',
    SUPPLIER: 'settings:only_my_buyers',
  },
  NOBODY: {
    BUYER: 'settings:nobody',
    SUPPLIER: 'settings:nobody',
  },
};

interface PrivacySettingSelect {
  fieldName: 'mobile_phone_number' | 'landline_number' | 'email';
}

export default function PrivacySettingSelect({ fieldName }: PrivacySettingSelect): React.JSX.Element {
  const { t } = useTranslation(['product-family', 'settings']);
  const { control, watch } = useFormContext<ContactInformations>();
  const { realm } = useAtomValue(currentContextAtom);
  const ignorePhoneNumber = watch(`${fieldName}.ignore`);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const menuItems = useMemo(() => (
    Object.keys(PRIVACY_SETTING_WORDING).map((setting) => (
      <MenuItem
        value={setting}
        key={setting}
        sx={{
          fontSize: 14,
          '&.Mui-selected': {
            fontWeight: 600,
            backgroundColor: 'common.white',
          },
          py: 2,
          '&:not(:last-child)': {
            borderBottom: '1px solid',
            borderColor: 'divider',
          },
        }}
      >
        {t(PRIVACY_SETTING_WORDING[setting as Privacy_Setting_Enum][realm])}
      </MenuItem>
    ))
  ), []);

  return (
    <Box sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Typography variant="bodyMedium" sx={{ whiteSpace: 'nowrap' }}>
          {t(
            isXs
              ? 'settings:privacy'
              : 'settings:make_this_information_visible_to',
          )}
        </Typography>
        <Tooltip
          title={t(realm === Business_Profile_Enum.Buyer ? 'settings:privacy_setting_description_as_buyer' : 'settings:privacy_setting_description_as_supplier')}
          placement="right-start"
          sx={{
            whiteSpace: 'pre-wrap',
            '& .MuiTooltip-tooltip': {
              textAlign: 'start',
            },
          }}
        >
          <HelpOutlineIcon fontSize="small" sx={{ color: 'text.secondary', width: 18 }} />
        </Tooltip>
      </Box>
      <Controller
        name={`${fieldName}.privacy`}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            sx={{
              height: 40,
            }}
            MenuProps={{
              slotProps: {
                paper: {
                  sx: {
                    borderRadius: 2,
                    mt: 1,
                  },
                },
              },
            }}
            disabled={ignorePhoneNumber}
          >
            {menuItems}
          </Select>
        )}
      />
    </Box>
  );
}
