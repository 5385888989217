import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';

export interface ConfirmDialogProps {
  title?: string;
  subTitle?: string;
  children?: React.ReactNode;
  cancelLabel: string;
  confirmLabel: string;
  confirmIcon?: ReactNode;
  open: boolean;
  onClose: (value: boolean) => void;
  waitForClosing?: boolean;
  confirmDisabled?: boolean;
}

export default function ConfirmDialog({
  title,
  subTitle,
  children,
  cancelLabel,
  confirmLabel,
  confirmIcon,
  open,
  onClose,
  waitForClosing,
  confirmDisabled,
}: ConfirmDialogProps): React.JSX.Element {
  const [loading, setLoading] = useState(false);

  const handleCancel = (): void => {
    onClose(false);
  };

  const handleConfirm = (): void => {
    if (waitForClosing) {
      setLoading(true);
    }

    onClose(true);
  };

  return (
    <Dialog onClose={handleCancel} open={open}>
      {!!title
        && (
        <DialogTitle>
          <Typography>{ title }</Typography>
          {subTitle && <Typography>{subTitle}</Typography>}
        </DialogTitle>
        )}
      {children && (
        <DialogContent>
          {children}
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleCancel} variant="text">{ cancelLabel }</Button>
        <Button
          onClick={handleConfirm}
          startIcon={confirmIcon}
          loading={loading}
          variant="contained"
          disabled={confirmDisabled}
        >
          { confirmLabel }
        </Button>
      </DialogActions>
    </Dialog>
  );
}
