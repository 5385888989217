import axios, { AxiosError } from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { useLocation, useNavigate } from 'react-router';
import { ApolloError } from '@apollo/client';
import { AUTH_TOKEN, AuthToken } from '../../common/models/auth.model';
import AuthAPIService from '../../common/services/AuthAPIService';
import { authStateAtom, dispatchAuthStateAtom } from '../../common/state/state';
import { RoutePaths } from '../../routes/AppRoutes';
import { AuthActionType } from './authActionsTypes';
import { useLoginWithAuthCodeMutation } from '../../mutations/__generated__/loginWithAuthCode.generated';

// TODO : Improve error handling
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseAxiosError(error: AxiosError<any>): string[] | undefined {
  const errorMessage = error.response?.data.message;
  if (errorMessage && errorMessage.length) {
    if (typeof errorMessage === 'string') {
      return [errorMessage];
    }
    return errorMessage;
  }
}

export default function useAuth(): {
  loginWithGoogle: (accessToken: string) => Promise<void>;
  loginWithAuthCode: (code: string, email: string) => Promise<void>;
  logout: () => void;
  setLoginToken: (token: AuthToken) => void;
  errors: string[] | undefined;
  hasCheckedRefreshToken: boolean;
  isLoading: boolean;
  isAuthenticated: boolean;
  } {
  const state = useAtomValue(authStateAtom);
  const dispatch = useSetAtom(dispatchAuthStateAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const [loginWithAuthCodeMutation] = useLoginWithAuthCodeMutation();

  const authService = new AuthAPIService();

  const redirectFrom = (): void => {
    const { from } = (location.state as { from: string }) || {};

    if (from) {
      navigate(-1);
    } else {
      navigate(RoutePaths.ROOT);
    }
  };

  const loginWithGoogle = async (accessToken: string): Promise<void> => {
    dispatch({ type: AuthActionType.LOGIN });
    try {
      const { tokens } = await authService.loginWithGoogle(accessToken);
      if (!tokens) {
        throw new Error('No token found');
      }
      localStorage.setItem(AUTH_TOKEN, JSON.stringify(tokens));
      dispatch({ type: AuthActionType.LOGIN_SUCCESS });
      // Redirect either to previous page or to root if no previous page exists
      redirectFrom();
    } catch (err) {
      if (axios.isAxiosError(err)) {
        dispatch({
          type: AuthActionType.LOGIN_ERROR,
          errors: parseAxiosError(err),
        });
        throw err;
      } else {
        throw err;
      }
    }
  };

  const setLoginToken = (token: AuthToken): void => {
    localStorage.setItem(AUTH_TOKEN, JSON.stringify(token));
    dispatch({ type: AuthActionType.LOGIN_SUCCESS });
    redirectFrom();
  };

  const logout = (): void => {
    localStorage.removeItem(AUTH_TOKEN);
    dispatch({ type: AuthActionType.LOGOUT });
    navigate(RoutePaths.ROOT, { replace: true });
  };

  const loginWithAuthCode = async (code: string, email:string): Promise<void> => {
    dispatch({ type: AuthActionType.LOGIN });
    try {
      const { data: tokensData } = await loginWithAuthCodeMutation({
        variables: {
          authenticationCode: code,
          email,
        },
      });
      if (tokensData?.loginWithAuthCode) {
        setLoginToken(tokensData.loginWithAuthCode);
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        dispatch({
          type: AuthActionType.LOGIN_ERROR,
          errors: [err.message],
        });
      }
      throw err;
    }
  };

  return {
    loginWithGoogle,
    loginWithAuthCode,
    logout,
    setLoginToken,
    errors: state.errors,
    hasCheckedRefreshToken: state.hasCheckedRefreshToken,
    isLoading: state.isLoading,
    isAuthenticated: state.isAuthenticated,
  };
}
