import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AppBar, Button } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router';
import { RoutePaths } from '../../routes/AppRoutes';
import BasketButton from '../../basket/BasketButton';

export interface BackBarProps {
  to: string;
}

export default function BackBar({ to }: BackBarProps): React.JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);
  const matchCompanyRoute = useMatch(RoutePaths.CONTRACT_COMPANY);
  const matchPackagingRoute = useMatch(RoutePaths.PACKAGING);

  const displayBasketButton = useMemo((): boolean => {
    return !!(matchCompanyRoute || matchPackagingRoute);
  }, [matchCompanyRoute, matchPackagingRoute]);

  const handleBackClick = (): void => {
    if (window.history.length <= 2) {
      navigate(to);
    } else {
      window.history.back();
    }
  };

  return (
    <AppBar
      elevation={0}
      sx={{
        height: 72,
        backgroundColor: 'common.white',
        display: {
          xs: 'flex',
          lg: 'none',
        },
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: {
          xs: 2,
          sm: 3,
        },
        zIndex: 10,
      }}
    >
      <Button variant="text" onClick={handleBackClick} startIcon={<ArrowBackIcon />}>
        {t('common:back')}
      </Button>
      {displayBasketButton && <BasketButton />}
    </AppBar>
  );
}
