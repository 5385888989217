import ErrorIcon from '@mui/icons-material/Error';
import { useAtomValue } from 'jotai';
import { Box, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { orderAtom } from '../state/state';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';

export default function OrderTaxFreeTotalPriceDisplay(): React.JSX.Element {
  const currencyFormat = useCurrencyFormat();
  const { t } = useTranslation(['order']);
  const order = useAtomValue(orderAtom);

  return (
    order.total_price_excl_tax !== order.total_price_to_pay_excl_tax
      ? (
        <Tooltip
          title={t('order:invoice_total_different_from_kheops_order')}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 0.5,
              color: 'orange',
              alignItems: 'center',
            }}
          >
            <ErrorIcon />
            <Typography variant="titleMedium" sx={{ color: 'inherit' }}>{currencyFormat.format(order.total_price_to_pay_excl_tax!)}</Typography>
          </Box>
        </Tooltip>
      )
      : (<Typography variant="titleMedium" color="primary">{currencyFormat.format(order.total_price_to_pay_excl_tax!)}</Typography>)
  );
}
