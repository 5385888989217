import { Button } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { TaskAction, Order_Status_Enum, Business_Profile_Enum, Payment_Method_Enum } from 'kheops-graphql';
import { OrderStatusType } from '../../common/models/order.models';
import useOrderActions from '../../hooks/useOrderActions';
import { areOrderItemsEditedAtom, isPerformingActionAtom, orderAtom, thereAreOrderItemsWithUpdatedQuantitiesAtom } from '../state/state';
import OrderDeliveryDateDialog, { DeliveryDateOrderStatus } from './OrderDeliveryDateDialog';
import OrderValidationDialog from './OrderValidationDialog';
import ConfirmDialog from '../../common/components/ConfirmDialog';
import { currentContextAtom } from '../../state';
import OrderPaymentSchedulingDialog from './dialogs/OrderPaymentSchedulingDialog';
import UploadInvoiceDrawer from './documents/UploadInvoiceDrawer';
import useOpenable from '../../hooks/useOpenable';

export type ActionNeededStatus = Exclude<keyof typeof OrderStatusType, 'order_closed'>;
type LabelByPaymentMethod = {[method in Payment_Method_Enum]: string};

interface OrderActionButtonProps {
  action?: TaskAction;
}

const APPROVE_LABEL_MAP: {[status in ActionNeededStatus]: string | LabelByPaymentMethod} = {
  order_to_be_validated_by_buyer: 'validate_order',
  order_to_be_validated_by_supplier: 'validate_order',
  order_to_be_delivered_by_supplier: 'confirm_delivery',
  order_to_be_prepared_by_supplier: 'confirm_weights',
  order_to_be_received_by_buyer: 'confirm_the_receipt',
  order_to_be_billed_by_supplier: 'add_invoice',
  order_to_be_paid_by_buyer: {
    NO_PAYMENT: 'confirm_the_payment',
    BANK_TRANSFER: 'confirm_the_payment',
    SDD_TRIGGERED: 'schedule_payment',
    SDD_AUTO: '',
  },
};

const MODIFY_LABEL_MAP: {
  [context in Business_Profile_Enum]: {
    [status in ActionNeededStatus]?: string
  }
} = {
  BUYER: {
    order_to_be_validated_by_buyer: 'modify_and_submit_to_supplier',
    order_to_be_received_by_buyer: 'receive_and_notify_supplier',
    order_to_be_validated_by_supplier: 'modify_and_submit_to_supplier',
  },
  SUPPLIER: {
    order_to_be_validated_by_buyer: 'modify_and_submit_to_buyer',
    order_to_be_validated_by_supplier: 'modify_and_submit_to_buyer',
    order_to_be_received_by_buyer: 'modify_and_submit_to_buyer',
    order_to_be_prepared_by_supplier: 'confirm_weights',
    order_to_be_delivered_by_supplier: 'submit_updates',
  },
};

export default function OrderActionButton({ action }: OrderActionButtonProps): React.JSX.Element {
  const { t } = useTranslation(['order', 'common']);
  const { realm } = useAtomValue(currentContextAtom);
  const order = useAtomValue(orderAtom);

  const { updateOrder, isLoading } = useOrderActions({ orderReferenceId: order.reference_id, orderId: order.id });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [label, setLabel] = useState<string>();
  const isPerformingAction = useAtomValue(isPerformingActionAtom);
  const areItemsEdited = useAtomValue(areOrderItemsEditedAtom);
  const thereIsAnItemWithUpdatedQuantity = useAtomValue(thereAreOrderItemsWithUpdatedQuantitiesAtom);
  const { open: openUploadInvoice, isOpen: isUploadInvoiceOpen, close: closeUploadInvoice } = useOpenable();

  const hasNotEditedQuantitiesInToBePrepared = useMemo(() => (
    order.status === Order_Status_Enum.OrderToBePreparedBySupplier && !thereIsAnItemWithUpdatedQuantity
  ), [order.status, thereIsAnItemWithUpdatedQuantity]);

  const handleActionButtonClick = async (): Promise<void> => {
    if (order.status === Order_Status_Enum.OrderToBeBilledBySupplier) {
      openUploadInvoice();
      return;
    }
    if (order.status === Order_Status_Enum.OrderToBeValidatedBySupplier
      || order.status === Order_Status_Enum.OrderToBeDeliveredBySupplier
      || order.status === Order_Status_Enum.OrderToBePreparedBySupplier
      || order.status === Order_Status_Enum.OrderToBeReceivedByBuyer
      || (order.status === Order_Status_Enum.OrderToBePaidByBuyer && order.payment_method === Payment_Method_Enum.SddTriggered)
      || action === TaskAction.Modify
    ) {
      setIsModalOpen(true);
      return;
    }
    await updateOrder(action);
  };

  const handleDialog = useCallback((isConfirmed: boolean): void => {
    if (isConfirmed) {
      if (hasNotEditedQuantitiesInToBePrepared) {
        updateOrder(TaskAction.Approve);
        setIsModalOpen(false);
        return;
      }
      const hasUpdatedItemsinDelivery = (order.status === Order_Status_Enum.OrderToBeDeliveredBySupplier || order.status === Order_Status_Enum.OrderToBePreparedBySupplier)
        && areItemsEdited;
      updateOrder(hasUpdatedItemsinDelivery ? undefined : action);
    }
    setIsModalOpen(false);
  }, [updateOrder, areItemsEdited, order.status, hasNotEditedQuantitiesInToBePrepared]);

  const dialogComponent = useMemo(() => {
    if ((areItemsEdited || order.status === Order_Status_Enum.OrderToBePreparedBySupplier)) {
      return (
        <OrderValidationDialog
          open={isModalOpen}
          onClose={handleDialog}
        />
      );
    }

    if (order.status === Order_Status_Enum.OrderToBeReceivedByBuyer) {
      return (
        <ConfirmDialog
          title={t('order:confirm_the_receipt')}
          cancelLabel={t('common:cancel')}
          confirmLabel={t('common:validate')}
          subTitle={t('order:confirm_reception_dialog_message')}
          open={isModalOpen}
          onClose={handleDialog}
        />
      );
    }

    if (order.status === Order_Status_Enum.OrderToBeValidatedBySupplier || order.status === Order_Status_Enum.OrderToBeDeliveredBySupplier) {
      return (
        <OrderDeliveryDateDialog
          open={isModalOpen}
          orderStatus={order.status as DeliveryDateOrderStatus}
          onClose={(date) => handleDialog(!!date)}
          actionType={TaskAction.Approve}
        />
      );
    }

    if (order.status === Order_Status_Enum.OrderToBePaidByBuyer && order.payment_method === Payment_Method_Enum.SddTriggered) {
      return (
        <OrderPaymentSchedulingDialog
          open={isModalOpen}
          onClose={handleDialog}
        />
      );
    }
  }, [order.status, isModalOpen, handleDialog]);

  useEffect(() => {
    if (!isPerformingAction) {
      let value = areItemsEdited
        ? MODIFY_LABEL_MAP[realm][order.status as ActionNeededStatus]
        : APPROVE_LABEL_MAP[order.status as ActionNeededStatus];

      if (!!value && typeof value !== 'string') {
        value = (value as LabelByPaymentMethod)[order.payment_method!];
      }

      setLabel(t(`order:${value}`));
    }
  }, [order.status, isPerformingAction, areItemsEdited, realm]);

  return (
    <>
      <UploadInvoiceDrawer open={isUploadInvoiceOpen} onClose={closeUploadInvoice} orderReferenceId={order.reference_id} />
      <Button
        variant="contained"
        onClick={handleActionButtonClick}
        loading={isLoading || isPerformingAction}
        sx={{ flex: 'auto' }}
      >
        {label}
      </Button>
      {dialogComponent}
    </>

  );
}
