import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './i18n';
import './index.css';

Sentry.init({
  dsn: 'https://e81d5c409b7747019a0a939d62478fca@o1044090.ingest.sentry.io/6013889',
  integrations: [browserTracingIntegration()],
  enabled: import.meta.env.REACT_APP_SENTRY_ENV !== 'development',
  environment: import.meta.env.REACT_APP_SENTRY_ENV,
  tracesSampleRate: 1.0,
  normalizeDepth: 5,
});

// Rendering
const container = document.getElementById('root');
const root = createRoot(
  container!,
  {
    onUncaughtError: Sentry.reactErrorHandler(),
    onCaughtError: Sentry.reactErrorHandler(),
    onRecoverableError: Sentry.reactErrorHandler(),
  },
);
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
