import { Box, Card, chipClasses, Grid2, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';
import { format } from 'date-fns';
import { useAtomValue } from 'jotai';
import { Business_Profile_Enum, Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { PackagingFamilyTypeIntl } from '../common/i18n/product-family.translation';
import { ContractPreview } from '../hooks/useContracts';
import { RoutePaths } from '../routes/AppRoutes';
import SizedImage from '../common/components/SizedImage';
import FileUtils from '../common/utils/file.utils';
import CompanyDistanceChip from '../common/components/CompanyDistanceChip';
import MinimumOrderValueChip from '../common/components/MinimumOrderValueChip';
import useCurrencyFormat from '../hooks/useCurrencyFormat';
import PriceModeChip from '../common/components/PriceModeChip';
import { longDateFormat } from '../common/state/state';
import { currentContextAtom } from '../state';
import CatalogChip from '../common/components/CatalogChip';
import CompanyLogo from '../common/components/CompanyLogo';

interface ContractRowProps {
  contract: ContractPreview;
}

export default function ContractRow({ contract }: ContractRowProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'contracts', 'product-family']);
  const theme = useTheme();
  const navigate = useNavigate();
  const currencyFormatter = useCurrencyFormat({ maximumFractionDigits: 0 });
  const { realm } = useAtomValue(currentContextAtom);

  const onRowClick = (): void => {
    navigate(
      generatePath(RoutePaths.CONTRACT_COMPANY, {
        companyId: contract.company.id,
      }),
    );
  };

  return (
    <Card sx={{ px: 2, py: 1.5, borderRadius: 4 }}>
      <Grid2
        container
        size={{ xs: 12 }}
        onClick={onRowClick}
        columnSpacing={2}
        sx={{
          cursor: 'pointer',
        }}
      >
        <Grid2
          size={{ xs: 12, md: 6 }}
          sx={{
            display: 'flex',
            [theme.breakpoints.down('lg')]: {
              mb: 1.5,
            },
          }}
        >
          <SizedImage
            src={
              FileUtils.BuildCompanyImageUrl({
                photo: contract.company.photos[0]?.photo,
                type: contract.company.business_profile,
                size: { height: 128, width: 128 },
              })
            }
            alt={t('common:company')}
            height={80}
            width={80}
            sx={{
              mr: 1.5,
            }}
            borderRadius={4}
            overlay={
              contract.company.brand && (
                <CompanyLogo
                  brand={contract.company.brand}
                  size="medium"
                  variant="square"
                  sx={{
                    width: 40,
                    height: 40,
                    position: 'absolute',
                    bottom: 4,
                    right: 4,
                  }}
                />
              )
            }
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <Typography variant="bodyLarge">{contract.company.tradeName}</Typography>
            {contract.company.products_aggregate?.aggregate.max.sub_family && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  mb: 0.5,
                }}
              >
                <Typography variant="bodyMedium" color="onSurfaceVariant">
                  {t(`product-family:${PackagingFamilyTypeIntl[contract.company.products_aggregate.aggregate.max.sub_family as Product_Sub_Family_Name_Enum]}`)}
                </Typography>
                {contract.company.products!.length > 1 && (
                  <Typography variant="bodySmall" color="onSurfaceVariant">+{contract.company.products!.length - 1}</Typography>
                )}
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 0.5,
                flexWrap: 'wrap',
                pt: 0.5,
                [`& .${chipClasses.root}`]: {
                  maxWidth: '220px',
                },
              }}
            >
              <CompanyDistanceChip
                targetGeoLoc={{
                  lat: contract.company.addresses[0].latitude,
                  lng: contract.company.addresses[0].longitude,
                }}
              />
              <MinimumOrderValueChip minimumOrderValue={contract.minimum_order_value as number} />
              {realm === Business_Profile_Enum.Supplier && !!contract.sub_catalog_id && (
                <CatalogChip contract={contract} />
              )}
              {!!(contract.custom_price_list_id || contract.discount) && (
                <PriceModeChip contract={contract} />
              )}
            </Box>
          </Box>
        </Grid2>
        <Grid2 container size={{ xs: 12, md: 6 }} rowSpacing={3}>
          <Grid2 size={{ xs: 6, sm: 4 }} sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <Typography variant="bodySmall">
              {t('contracts:last_order')}
            </Typography>
            <Typography variant="bodyMedium">
              {contract.last_order_date ? format(contract.last_order_date, longDateFormat) : '-'}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 6, sm: 4 }} sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <Typography variant="bodySmall">
              {t('contracts:orders_count')}
            </Typography>
            <Typography variant="bodyMedium">
              {contract.order_count}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 6, sm: 4 }} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: 0.5 }}>
            <Typography variant="bodySmall">
              {t('contracts:total_revenue')}
            </Typography>
            <Typography variant="bodyMedium">
              💰 {currencyFormatter.format(contract.total_revenue!)}
            </Typography>
          </Grid2>
        </Grid2>
      </Grid2>
    </Card>
  );
}
