/* eslint-disable react/jsx-no-useless-fragment */
import { Box, Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useAtomValue } from 'jotai';
import React, { useState } from 'react';
import { Link, useMatch, useNavigate } from 'react-router';
import TopBar from '../common/components/TopBar';
import KheopsLogoFullBlack from '../assets/logo/kheopsLogo.svg?react';
import KheopsLogoShort from '../assets/logo/kheops-logo-icon.svg?react';
import { RoutePaths } from '../routes/AppRoutes';
import BasketButton from '../basket/BasketButton';
import TopMenuCTAButton from './TopMenuCTAButton';
import ProfileBox from './ProfileBox';
import ChatButton from './ChatButton';
import NavigationTabs from './NavigationTabs';
import { currentContextAtom } from '../state';
import ContextImage from './ContextImage';
import { getStateLayerColor, StateLayer } from '../theme/color.utils';

export default function TopMenu(): React.JSX.Element {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const context = useAtomValue(currentContextAtom);

  const matchUserPage = useMatch(RoutePaths.USER);
  const matchUserCompanyPage = useMatch(`${RoutePaths.COMPANY}/*`);
  const matchProductPage = useMatch(`${RoutePaths.PRODUCT}`);
  const matchProductEditPage = useMatch(`${RoutePaths.PRODUCT_ADD}/*`);
  const matchNavigationPage = useMatch(`${RoutePaths.NAVIGATION}/*`);
  const matchCustomPriceListPage = useMatch(`${RoutePaths.CUSTOM_PRICE_LIST}`);

  const highlightProfile = matchUserPage || matchUserCompanyPage || matchProductPage || matchProductEditPage || matchCustomPriceListPage;

  // Handle user menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleUserMenuClick = (
    event: React.MouseEvent<HTMLElement>,
  ): void => {
    if (isDownMd) {
      navigate(RoutePaths.NAVIGATION_MAIN);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleNeedHelpClick = (): void => {
    window.Intercom('show');
  };

  //  Return top manu, and display a banner object only once (keep this comment as legacy content, do not remove please)
  return (
    <>
      {!matchNavigationPage && (
        <Box sx={{ position: 'sticky', top: 0, zIndex: 10 }}>
          <TopBar
            sx={{
              justifyContent: 'center',
              px: {
                position: 'static',
                xs: 1,
                sm: 2,
                lg: 3,
              },
            }}
          >
            <Box
              sx={{
                maxWidth: theme.breakpoints.values.xl,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flex: {
                    xs: 0,
                    md: 1,
                  },
                }}
              >
                <Link to={isSmall ? RoutePaths.ORDER_LIST : RoutePaths.ROOT}>
                  {isDownLg ? <KheopsLogoShort /> : <KheopsLogoFullBlack />}
                </Link>
                <TopMenuCTAButton
                  sx={{
                    ml: 2,
                    display: {
                      xs: 'none',
                      sm: 'inline-flex',
                    },
                  }}
                />
              </Box>
              {!isDownLg && (
                <NavigationTabs
                  sx={{
                    flex: 1,
                    '& .MuiTabs-flexContainer': {
                      gap: 1,
                      my: 0.5,
                    },
                    '& .MuiTab-root': {
                      flex: 1,
                    },
                  }}
                />
              )}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 0,
                  ml: 3,
                  gap: 2,
                  flex: 1,
                  justifyContent: 'right',
                }}
              >
                <Box sx={{ alignSelf: 'right', display: 'flex', gap: 2 }}>
                  <BasketButton />
                  <ChatButton />
                </Box>
                <Box
                  sx={{
                    px: 1,
                    py: 0.5,
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    cursor: 'pointer',
                    backgroundColor: anchorEl || highlightProfile ? 'secondaryContainer.main' : 'transparent',
                    borderRadius: 4,
                    minWidth: 0,
                    color: 'text.primary',
                    '&:hover': {
                      backgroundColor: anchorEl || highlightProfile
                        ? getStateLayerColor(StateLayer.Layer8, theme.palette.secondaryContainer.main, theme.palette.onSecondaryContainer.main)
                        : getStateLayerColor(StateLayer.Layer8, theme.palette.surfaceContainerLowest.main, theme.palette.onSurfaceVariant.main),
                    },
                  }}
                  onClick={handleUserMenuClick}
                  data-testid="top-menu-user-menu"
                >
                  <ContextImage context={context} size={40} borderRadius={4} />
                  {anchorEl ? <KeyboardArrowUpIcon sx={{ fontSize: '18px' }} /> : <KeyboardArrowDownIcon sx={{ fontSize: '18px' }} />}
                </Box>
                <ProfileBox anchorEl={anchorEl} anchorHandler={setAnchorEl} />
                <Button variant="text" onClick={handleNeedHelpClick}>
                  <HelpOutlineIcon />
                </Button>
              </Box>
            </Box>
          </TopBar>
        </Box>
      )}
    </>
  );
}
