/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Card, Container, Step, StepLabel, Stepper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Provider, useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useMatch, useSearchParams } from 'react-router';
import {
  Availability_Status_Enum,
  Base_Unit_Type_Enum,
  Billing_Type_Enum,
  Duration_Type_Enum,
  Product_Label_Name_Enum,
  Product_Sub_Family_Name_Enum,
  Trade_Item_Unit_Descriptor_Enum,
} from 'kheops-graphql';
import { BaseUnit, FormatPackagingUnitPrice, FormatPackagingVolumePrice, LogisticalPackaging, round } from 'kheops-utils';
import { currentContextAtom } from '../../state';
import useBaseStore from '../../hooks/useBaseStore';
import { useProductByIdLazyQuery } from '../../queries/__generated__/productById.generated';
import { RoutePaths } from '../../routes/AppRoutes';
import { PageWrapper } from '../../common/components/PageWrapper';
import BackBar from '../../common/components/BackBar';
import ProductAdd from './ProductAdd';
import { companyIdAtom, DraftProduct, draftProductAtom, DraftProductPackagings, draftProductPackagingsAtom, editModeAtom, ProductPackagingEditMode } from './state';
import BackButton from '../../common/components/BackButton';

export default function ProductAddPage(): React.JSX.Element {
  const { t } = useTranslation(['products', 'price']);
  const matchProductAddInfo = useMatch(RoutePaths.PRODUCT_ADD_INFO);
  const { companyId } = useAtomValue(currentContextAtom);
  const [searchParams] = useSearchParams();
  const [getProductById, { data: productData }] = useProductByIdLazyQuery();
  const productId = searchParams.get('productId');
  const baseUnitSku = parseInt(searchParams.get('baseUnitSku') || '', 10);
  const [baseStoreReady, setBaseStoreReady] = useState(false);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [formTitle, setFormTitle] = useState('');
  const location = useLocation();
  const baseStore = useBaseStore();
  const stepsPaths = [
    RoutePaths.PRODUCT_ADD_INFO,
    RoutePaths.PRODUCT_ADD_PACKAGING,
  ];

  const backUrl = useMemo((): string => {
    const product = productData?.product_by_pk;

    return product
      ? generatePath(RoutePaths.PRODUCT, { productSku: `${product.sku}`, companyId: product.company_id })
      : RoutePaths.COMPANY_PRODUCTS;
  }, [productData]);

  const getBaseUnitPrice = (baseUnit: BaseUnit, packaging: LogisticalPackaging): number => {
    let initialBaseUnitPrice: number;

    if (baseUnit.billing_type === Billing_Type_Enum.WeightDependent || baseUnit.unit_type === Base_Unit_Type_Enum.Bulk) {
      const packagingVolumePrice = FormatPackagingVolumePrice(
        packaging,
        baseUnit,
      );

      initialBaseUnitPrice = packagingVolumePrice.value;
    } else {
      initialBaseUnitPrice = FormatPackagingUnitPrice(packaging);
    }

    return round(initialBaseUnitPrice);
  };

  useEffect(() => {
    setActiveStepIndex(stepsPaths.indexOf(location.pathname));
  }, [location]);

  useEffect(() => {
    let draftProduct: DraftProduct | undefined;
    const draftProductPackagings: DraftProductPackagings[] = [];

    if (productData?.product_by_pk) {
      const product = productData.product_by_pk;

      draftProduct = {
        sku: product.sku,
        name: product.name,
        company_id: product.company_id,
        labels: product.labels.map((label) => label.label) as Product_Label_Name_Enum[],
        sub_family: product.sub_family as Product_Sub_Family_Name_Enum,
        vat_rate: product.vat_rate!,
        ingredients: product.ingredients,
        allergens: product.allergens,
        calories: product.calories,
        calories_kj: product.calories_kj,
        fat: product.fat,
        saturated_fat: product.saturated_fat,
        carbohydrate: product.carbohydrate,
        sugars: product.sugars,
        sodium: product.sodium,
        protein: product.protein,
        description: product.description,
        shelf_life_duration: product.shelf_life_duration,
        shelf_life_duration_type: product.shelf_life_duration_type || Duration_Type_Enum.Days,
      };

      product.base_units.forEach((base_unit) => {
        const packagings = base_unit.packagings.map((packaging) => ({
          sku: packaging.sku,
          trade_item_unit_descriptor: packaging.trade_item_unit_descriptor as Trade_Item_Unit_Descriptor_Enum,
          unit_quantity: packaging.unit_quantity,
          net_content: packaging.net_content,
          content_measurement_unit: packaging.content_measurement_unit,
          price: packaging.price,
          base_unit_price: getBaseUnitPrice(base_unit, packaging),
        }));

        draftProductPackagings.push({
          csu: {
            sku: base_unit.sku!,
            csu_type: base_unit.unit_type === Base_Unit_Type_Enum.Bulk ? 'BULK' : 'UNIT',
            unit_quantity: base_unit.unit_quantity!,
            unit_type: base_unit.unit_type === Base_Unit_Type_Enum.Bulk ? '' as Base_Unit_Type_Enum : base_unit.unit_type!,
            unit_net_content: base_unit.unit_net_content!,
            content_measurement_unit: base_unit.content_measurement_unit,
            gtin: base_unit.gtin,
            billing_type: base_unit.billing_type,
            availability: base_unit.availability === Availability_Status_Enum.Available,
            main_photo: base_unit.main_photo,
            suggested_retail_price: base_unit.suggested_retail_price,
            photos: base_unit.photos.map(({ photo }) => ({
              domain: photo.domain,
              path: photo.path,
            })),
          },
          packagings,
        });
      });

      baseStore.set(draftProductAtom, draftProduct);
      baseStore.set(draftProductPackagingsAtom, draftProductPackagings);
      setBaseStoreReady(true);
    }
  }, [productData]);

  useEffect(() => {
    let editMode: ProductPackagingEditMode;

    baseStore.set(companyIdAtom, companyId!);

    if (productId) {
      editMode = 'EDIT';

      if (matchProductAddInfo) {
        setFormTitle(t('products:modify_my_product'));
      } else if (baseUnitSku) {
        setFormTitle(t('products:modify_a_base_unit'));
      } else {
        editMode = 'ADD';
        setFormTitle(t('products:add_a_base_unit'));
      }

      getProductById({
        variables: {
          productId,
        },
      });
    } else {
      editMode = 'CREATE';
      setFormTitle(t('products:add_a_product'));
      setBaseStoreReady(true);
    }

    baseStore.set(editModeAtom, editMode);
  }, [matchProductAddInfo]);

  return (
    <>
      <BackBar to={backUrl} />
      <PageWrapper>
        {baseStoreReady
          && (
            <Provider store={baseStore}>
              <Container
                maxWidth="md"
                fixed
                disableGutters
              >
                <Card sx={{ p: 4, mb: 2 }}>
                  <Box sx={{ mb: 4, display: 'flex', gap: 1 }}>
                    <BackButton
                      variant="tonal"
                      to={backUrl}
                      compact
                      sx={{
                        display: {
                          xs: 'none',
                          lg: 'inline-flex',
                        },
                      }}
                    />
                    <Typography variant="titleLarge" sx={{ alignSelf: 'center' }}>
                      {formTitle}
                    </Typography>
                  </Box>
                  {!productId && (
                    <Stepper
                      activeStep={activeStepIndex}
                      alternativeLabel
                      sx={{
                        width: '100%',
                        backgroundColor: 'surfaceContainerLow.main',
                        py: 3,
                        borderRadius: 4,
                        mb: 4,
                        '.Mui-active .MuiStepConnector-line, .Mui-completed .MuiStepConnector-line': { borderColor: 'primary.main' },
                        '.MuiStepConnector-line': { borderTopWidth: '2px' },
                        '.MuiStepConnector-root': { top: '11px' },
                        '.MuiSvgIcon-root:not(.Mui-completed, .Mui-active) text': { fill: '#535D5F' },
                      }}
                    >
                      <Step>
                        <StepLabel>{t('products:general_specifications')}</StepLabel>
                      </Step>
                      <Step>
                        <StepLabel>{t('products:csu_and_packaging')}</StepLabel>
                      </Step>
                    </Stepper>
                  )}
                  <ProductAdd />
                </Card>
              </Container>
            </Provider>
          )}
      </PageWrapper>
    </>
  );
}
