import { Box, Chip, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SuggestedOrderIcon from '../../assets/icons/suggested_order.svg?react';

interface OrderSuggestionChipProps {
  short?: boolean;
}

export default function OrderSuggestionChip({ short }: OrderSuggestionChipProps): React.JSX.Element {
  const { t } = useTranslation(['order']);

  if (!short) {
    return (
      <Tooltip title={t('order:supplier_suggestion')}>
        <Chip
          variant="tag"
          label={t('order:supplier_suggestion')}
          icon={<SuggestedOrderIcon />}
        />
      </Tooltip>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mr: 0.5,
      }}
    >
      <SuggestedOrderIcon width={18} height={18} />
    </Box>
  );
}
