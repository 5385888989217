import { Box, Button, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Global_Product_Family_Enum, Product_Family_Name_Enum, Product_Sub_Family_Name_Enum, User_Product_Family_Insert_Input } from 'kheops-graphql';
import { productFamilyMap } from 'kheops-utils';
import { useInsertUserProductFamiliesMutation } from '../../mutations/__generated__/insertUserProductFamilies.generated';
import { UserRoleDocument } from '../../queries/__generated__/userRole.generated';
import { ProductFamilySettings, productFamiliesSettingsAtom } from '../state';
import { SettingsDialogViewProps } from './SettingsDialog';
import ProductFamiliesAccordion from './ProductFamiliesAccordion';
import { commonSnackbarPropsAtom } from '../../common/state/state';
import { currentContextAtom } from '../../state';

export default function ProductFamiliesSettings({ next, standAlone }: SettingsDialogViewProps): React.JSX.Element {
  const { t } = useTranslation(['product-family', 'settings']);
  const context = useAtomValue(currentContextAtom);
  const [insertUserProductFamilies, { data, loading }] = useInsertUserProductFamiliesMutation({
    refetchQueries: [UserRoleDocument],
    awaitRefetchQueries: true,
  });
  const [productFamilySettings, setProductFamilySettings] = useAtom(productFamiliesSettingsAtom);
  const [defaultProductFamilySettings, setDefaultProductFamilySettings] = useState<ProductFamilySettings>({});
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);

  const handleSubmit = useCallback((): void => {
    const { global_setting, product_sub_families } = productFamilySettings;
    const userProductFamilyInsertInput: User_Product_Family_Insert_Input[] = [];

    if (global_setting) {
      userProductFamilyInsertInput.push({
        role_assignment_id: context.roleAssignmentId,
        global_setting,
      });
    } else {
      (Object.keys(productFamilyMap) as Product_Family_Name_Enum[]).forEach((productFamily) => {
        const hasFullFamily = productFamilyMap[productFamily].every(
          (subFamily) => product_sub_families!.has(subFamily),
        );

        if (hasFullFamily) {
          userProductFamilyInsertInput.push({
            role_assignment_id: context.roleAssignmentId,
            product_family: productFamily,
          });
        } else {
          productFamilyMap[productFamily].forEach((subFamily) => {
            if (product_sub_families!.has(subFamily)) {
              userProductFamilyInsertInput.push({
                role_assignment_id: context.roleAssignmentId,
                product_sub_family: subFamily,
              });
            }
          });
        }
      });
    }

    insertUserProductFamilies({
      variables: {
        role_assignment_id: context.roleAssignmentId!,
        userProductFamilies: userProductFamilyInsertInput,
      },
    });
  }, [productFamilySettings]);

  const toggleGlobalSetting = useCallback((value: Global_Product_Family_Enum): void => {
    const newProductFamilySettings = productFamilySettings.global_setting === value
      ? {} as ProductFamilySettings
      : { global_setting: value as Global_Product_Family_Enum };

    setProductFamilySettings(newProductFamilySettings);
  }, [productFamilySettings]);

  const isGlobalSettingActive = useCallback((value: Global_Product_Family_Enum): boolean => {
    return productFamilySettings.global_setting === value;
  }, [productFamilySettings]);

  const validationDisabled = useMemo(() => {
    const { global_setting, product_sub_families } = productFamilySettings;
    const isGlobalSettingDirty = defaultProductFamilySettings.global_setting !== global_setting;
    const areProductSubFamiliesDirty = product_sub_families?.size !== defaultProductFamilySettings.product_sub_families?.size
      || ![...product_sub_families || []].every((subFamily) => defaultProductFamilySettings.product_sub_families?.has(subFamily));

    return (!global_setting && !product_sub_families?.size) || (!isGlobalSettingDirty && !areProductSubFamiliesDirty);
  }, [productFamilySettings, defaultProductFamilySettings]);

  useEffect((): void => {
    if (data?.insert_user_product_family?.affected_rows) {
      setCommonSnackbarProps({
        label: t('settings:your_profile_has_been_updated'),
        snackbarProps: {
          open: true,
        },
      });

      next();
    }
  }, [next, data]);

  useEffect((): void => {
    if (!context.productFamilies?.length) {
      return;
    }

    const newProductFamilySettings: ProductFamilySettings = {};

    if (context.productFamilies[0].global_setting) {
      newProductFamilySettings.global_setting = context.productFamilies[0].global_setting;
    } else {
      newProductFamilySettings.product_sub_families = new Set<Product_Sub_Family_Name_Enum>();

      context.productFamilies.forEach(({ product_family, product_sub_family }) => {
        if (product_sub_family) {
          newProductFamilySettings.product_sub_families!.add(product_sub_family);
        } else if (product_family) {
          productFamilyMap[product_family].forEach((subFamily) => newProductFamilySettings.product_sub_families!.add(subFamily));
        }
      });
    }

    setProductFamilySettings(newProductFamilySettings);
    setDefaultProductFamilySettings({
      global_setting: newProductFamilySettings.global_setting,
      product_sub_families: new Set(newProductFamilySettings.product_sub_families),
    });
  }, [context]);

  return (
    <>
      <DialogTitle>
        <Typography>
          {t('settings:your_departments')}
        </Typography>
        <Typography>
          {t('settings:your_departments_description')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {
          Object.values(Product_Family_Name_Enum).map((productFamily): React.JSX.Element => (
            <ProductFamiliesAccordion key={productFamily} productFamily={productFamily} />
          ))
        }
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            gap: 2,
            '& > .MuiBox-root': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              whiteSpace: 'nowrap',
              gap: 1.5,
              flex: 1,
              py: 3,
              border: '1px solid',
              borderColor: 'outlineVariant.main',
              borderRadius: 2,
              cursor: 'pointer',
            },
          }}
        >
          <Box
            onClick={() => toggleGlobalSetting(Global_Product_Family_Enum.All)}
            sx={
              isGlobalSettingActive(Global_Product_Family_Enum.All)
                ? {
                  backgroundColor: 'secondaryContainer.main',
                  color: 'onSecondaryContainer.main',
                  border: '0 !important',
                }
                : {}
            }
          >
            <Typography variant="bodyLarge">
              {t('settings:all_departments')}
            </Typography>
            <Tooltip title={t('settings:all_departments_tooltip')}>
              <HelpOutlineIcon sx={{ color: 'text.secondary' }} />
            </Tooltip>
          </Box>
          <Box
            onClick={() => toggleGlobalSetting(Global_Product_Family_Enum.None)}
            sx={
              isGlobalSettingActive(Global_Product_Family_Enum.None)
                ? {
                  backgroundColor: 'secondaryContainer.main',
                  color: 'onSecondaryContainer.main',
                  border: '0 !important',
                }
                : {}
            }
          >
            <Typography variant="bodyLarge">
              {t('settings:no_departments')}
            </Typography>
            <Tooltip title={t('settings:no_departments_tooltip')}>
              <HelpOutlineIcon sx={{ color: 'text.secondary' }} />
            </Tooltip>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {
          standAlone
            ? (
              <Button onClick={() => next()}>
                {t('common:cancel')}
              </Button>
            )
            : (
              <Button onClick={() => next(true)} variant="outlined">
                {t('settings:complete_later')}
              </Button>
            )
        }
        <Button
          disabled={validationDisabled}
          loading={loading}
          onClick={handleSubmit}
          variant="contained"
        >
          {t('common:validate')}
        </Button>
      </DialogActions>
    </>
  );
}
