"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatAddressComponents = formatAddressComponents;
exports.computeDistanceInKm = computeDistanceInKm;
exports.computeIntraCommunityVatFR = computeIntraCommunityVatFR;
exports.round = round;
exports.convertSqlTimeToDate = convertSqlTimeToDate;
exports.getFormattedHour = getFormattedHour;
exports.isNumberEqualToTargetWithinTolerance = isNumberEqualToTargetWithinTolerance;
exports.getOrderTotalsTolerances = getOrderTotalsTolerances;
const format_1 = require("date-fns/format");
const kheops_graphql_1 = require("kheops-graphql");
const googleMapsAddressComponentsMap = new Map([
    ['postal_code', 'postalCode'],
    ['locality', 'locality'],
    ['route', 'route'],
    ['administrative_area_level_1', 'administrativeAreaLevel1'],
    ['administrative_area_level_2', 'administrativeAreaLevel2'],
    ['administrative_area_level_3', 'administrativeAreaLevel3'],
    ['administrative_area_level_4', 'administrativeAreaLevel4'],
    ['administrative_area_level_5', 'administrativeAreaLevel5'],
    ['street_number', 'streetNumber'],
    ['country', 'countryCode'],
    ['archipelago', 'archipelago'],
    ['colloquial_area', 'colloquialArea'],
    ['continent', 'continent'],
    ['intersection', 'intersection'],
    ['neighborhood', 'neighborhood'],
    ['post_box', 'postBox'],
    ['postal_town', 'postalTown'],
    ['postal_code_prefix', 'postalCodePrefix'],
    ['postal_code_suffix', 'postalCodeSuffix'],
    ['premise', 'premise'],
    ['subpremise', 'subpremise'],
    ['town_square', 'townSquare'],
    ['street_address', 'streetAddress'],
    ['sublocality', 'sublocality'],
    ['sublocality_level_1', 'sublocalityLevel1'],
    ['sublocality_level_2', 'sublocalityLevel2'],
    ['sublocality_level_3', 'sublocalityLevel3'],
    ['sublocality_level_4', 'sublocalityLevel4'],
    ['sublocality_level_5', 'sublocalityLevel5'],
]);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
/**
 * This function accepts the array address_components returned by Google Maps API and parse it to database compatible object
 */
function formatAddressComponents(addressComponents) {
    const formattedAddressComponent = {};
    addressComponents.forEach((component) => {
        if (!googleMapsAddressComponentsMap.get(component.types[0])) {
            return;
        }
        if (component.types[0] === 'country') {
            formattedAddressComponent[googleMapsAddressComponentsMap.get(component.types[0])] = component.short_name;
        }
        else {
            formattedAddressComponent[googleMapsAddressComponentsMap.get(component.types[0])] = component.long_name;
        }
    });
    return formattedAddressComponent;
}
// Function based on Haversine formula. Copied from: https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
function computeDistanceInKm(lat1, lon1, lat2, lon2) {
    // function to convert angle's value from degrees to radians.
    function deg2rad(deg) {
        return deg * (Math.PI / 180);
    }
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
        + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2))
            * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
}
/**
 * Made following the calculation described in this article: https://www.l-expert-comptable.com/calculateurs/trouver-le-numero-de-tva-intracommunautaire-partir-de-votre-numero-de-siren.html
 * This does only apply to the VAT for french company.
 */
function computeIntraCommunityVatFR(siret) {
    const siren = parseInt(siret.substring(0, 9), 10);
    const vatKey = (12 + 3 * (siren % 97)) % 97;
    // We need to add a 0 before the key if it evaluates to a number with a single digit.
    // This way we keep the key 2 characters long.
    const paddedVatKey = `${vatKey}`.padStart(2, '0');
    return `FR${paddedVatKey}${siren}`;
}
/**
 * round a number to 2 decimals, this function aims to handle cases where Math.random does not return good results, for example Math.round of n.008 returns n instead of n.01
 */
function round(number, precision = 2) {
    const numberTimes100 = Math.round(Number(`${number}e+${precision}`));
    return Number(`${numberTimes100}e-${precision}`);
}
function convertSqlTimeToDate(time) {
    // example of sql time format: 09:30:00+00
    const timeSplits = time.split(':');
    const today = new Date();
    today.setHours(parseInt(timeSplits[0], 10));
    today.setMinutes(parseInt(timeSplits[1], 10));
    return today;
}
function getFormattedHour(hour) {
    return (0, format_1.format)(hour, hour.getMinutes() ? 'H\'h\'mm' : 'H\'h\'');
}
function isNumberEqualToTargetWithinTolerance(source, target, tolerance) {
    const absDifference = Math.abs(source - target);
    return absDifference <= tolerance;
}
/**
 * This functions computes order disparity tolerances in euros for tax free and tax included totals.
 * It applie the following formula
 * taxFreeTolerance = 0.005 * (n + 1)
 * taxIncludedTolerance = 0.006 * (n + 1) + 0.005 * (m + 1)
 * Where n is number of uvc and m is number of packagings
 * @returns object with taxFreeTolerance and taxIncludedTolerance in euros
 */
function getOrderTotalsTolerances(orderItems) {
    const firstToleranceParam = 0.005;
    const secondToleranceParam = 0.006;
    const { countOfPackagings, countOfBaseUnits } = orderItems.reduce((acc, orderItem) => {
        const countOfPackagings = orderItem.quantity;
        acc.countOfPackagings += countOfPackagings;
        // count of base unit is computed differently based on if its of bulk type or not
        if (orderItem.packaging.base_unit?.unit_type === kheops_graphql_1.Base_Unit_Type_Enum.Bulk) {
            const countOfBaseUnits = orderItem.quantity;
            acc.countOfBaseUnits += countOfBaseUnits;
        }
        else {
            const countOfBaseUnits = countOfPackagings * orderItem.packaging.unit_quantity;
            acc.countOfBaseUnits += countOfBaseUnits;
        }
        return acc;
    }, { countOfPackagings: 0, countOfBaseUnits: 0 });
    return {
        taxFreeTolerance: round(firstToleranceParam * (countOfBaseUnits + 1)),
        taxIncludedTolerance: round(secondToleranceParam * (countOfBaseUnits + 1) + firstToleranceParam * (countOfPackagings + 1)),
    };
}
