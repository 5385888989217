import { Dialog, dialogClasses } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { Role_Assignment, Company, Maybe, Additional_Address, Delivery_Hours } from 'kheops-graphql';
import BuyerInfoGeneralView from './BuyerInfoGeneralView';
import { useCompanyByIdQuery } from '../../../queries/__generated__/companyById.generated';
import BuyerInfoDeliveryView from './BuyerInfoDeliveryView';
import { DisplayPhoto } from '../../../common/common';
import { CompanyAddress } from '../../companyInfo/CompanyInfoCard';
import { Contact } from '../../CompanyContactList';
import BuyerInfoContactsView from './BuyerInfoContactsView';
import BuyerInfoInvoicingView from './BuyerInfoInvoicingView';

type ContactInfo = Pick<Role_Assignment, 'is_delivery_contact' | 'is_invoicing_contact'> & {
  user: Contact;
};

export interface BuyerInfoViewProps {
  company: Pick<Company, 'id' | 'contactPhoneNumber' | 'tradeName' | 'legalName' | 'brand' | 'delivery_email' | 'delivery_instructions' | 'delivery_phone_number' | 'delivery_description' | 'invoicing_email' | 'invoicing_instructions' | 'invoicing_phone_number' | 'national_company_registration_number'> & {
    addresses: CompanyAddress[];
    photos?: Maybe<DisplayPhoto[]>;
    role_assignments: ContactInfo[];
    delivery_address?: Maybe<Additional_Address>;
    delivery_hours?: Maybe<Pick<Delivery_Hours, 'opening_hour' | 'closing_hour' | 'week_day'>[]>;
    invoicing_address?: Maybe<Additional_Address>;
  };
  close: () => void;
  changeView: (view: BuyerView) => void;
  hideBackArrow?: boolean;
}

export type BuyerView = 'GENERAL' | 'DELIVERY' | 'INVOICING' | 'CONTACTS';

export interface BuyerInfoDialogProps {
  companyId: string;
  close: () => void;
  isOpen: boolean;
  openView?: BuyerView;
}

const views = {
  GENERAL: (props: BuyerInfoViewProps) => <BuyerInfoGeneralView {...props} />,
  DELIVERY: (props: BuyerInfoViewProps) => <BuyerInfoDeliveryView {...props} />,
  INVOICING: (props: BuyerInfoViewProps) => <BuyerInfoInvoicingView {...props} />,
  CONTACTS: (props: BuyerInfoViewProps) => <BuyerInfoContactsView {...props} />,
};

export default function BuyerInfoDialog({ companyId, close, isOpen, openView }: BuyerInfoDialogProps): React.JSX.Element {
  const [viewName, setViewName] = useState<BuyerView>('GENERAL');
  const { data } = useCompanyByIdQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const company = data?.company_by_pk;

  const view = useMemo(() => {
    return views[openView || viewName];
  }, [openView, viewName]);

  const handleClose = useCallback(() => {
    close();
    setViewName('GENERAL');
  }, []);

  if (!company) {
    return <></>;
  }

  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      PaperProps={{
        sx: {
          m: {
            xs: 1,
            sm: 0,
          },
          height: 620,
        },
      }}
      sx={{
        [`& .${dialogClasses.container}`]: {
          alignItems: {
            xs: 'flex-end',
            sm: 'center',
          },
        },
      }}
    >
      {
        view({ company, close: handleClose, changeView: setViewName, hideBackArrow: !!openView })
      }
    </Dialog>
  );
}
