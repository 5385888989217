import { Box, Card, Chip } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import { useSetAtom } from 'jotai';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import SizedImage from '../../../../common/components/SizedImage';
import FileUtils from '../../../../common/utils/file.utils';
import BillingTypeCard from '../../../BillingTypeCard';
import PackagingInfo from './PackagingInfo';
import { RoutePaths } from '../../../../routes/AppRoutes';
import BaseUnitStatus from '../../../common/BaseUnitStatus';
import useOpenable from '../../../../hooks/useOpenable';
import DeleteBaseUnitDialog from '../../../common/DeleteBaseUnitDialog';
import { ProductFragment, ProductFragmentBaseUnit } from '../../../products';
import { commonSnackbarPropsAtom } from '../../../../common/state/state';
import { useInsertProductMutation } from '../../../../mutations/__generated__/insertProduct.generated';
import { PackagingsByCompanyDocument } from '../../../../queries/__generated__/packagingsByCompany.generated';
import ProductUtils from '../../../common/products.utils';
import BaseUnitGtin from '../../../common/BaseUnitGtin';

interface BaseUnitProps {
  baseUnit: ProductFragmentBaseUnit;
  baseUnitIndex: number;
  product: ProductFragment;
}

export default function BaseUnit({ baseUnit, baseUnitIndex, product }: BaseUnitProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'packaging', 'products']);
  const [insertProduct, insertProductOutput] = useInsertProductMutation({ refetchQueries: ['ProductVersionById', PackagingsByCompanyDocument] });
  const { open, close, isOpen } = useOpenable();
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);

  const handleDeleteClick = useCallback((): void => {
    open();
  }, []);

  const handleDeleteConfirm = useCallback((confirm: boolean): void => {
    if (confirm) {
      const productInsertInput = ProductUtils.convertProductFragmentIntoInsertInput(product);

      productInsertInput.base_units!.data.splice(baseUnitIndex, 1);

      insertProduct({
        variables: { product: productInsertInput },
        update: (cache) => {
          cache.evict({ fieldName: 'product' });
        },
      });
    } else {
      close();
    }
  }, []);

  useEffect((): void => {
    if (insertProductOutput.data?.insert_product) {
      setCommonSnackbarProps({
        label: t('common:update_successful'),
        snackbarProps: {
          open: true,
        },
      });

      close();
    }
  }, [insertProductOutput]);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: 3,
        p: 2,
        height: 'calc(100% - 24px)',
        backgroundColor: 'surfaceContainerLow.main',
      }}
    >
      <SizedImage
        src={FileUtils.BuildPackagingImageUrl({
          photo: baseUnit.main_photo,
          type: product.sub_family,
          size: { height: 256, fit: 'contain' },
        })}
        borderRadius={0}
        alt={t('products:csu')}
        width={{
          xs: 200,
          xl: 256,
        }}
        height={{
          xs: 200,
          xl: 256,
        }}
        sx={{ mx: 'auto' }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 2,
        }}
      >
        <BaseUnitStatus baseUnit={baseUnit} baseUnitIndex={baseUnitIndex} product={product} variant="bodyMedium" />
        {!!baseUnit.gtin && (
          <BaseUnitGtin gtin={baseUnit.gtin} />
        )}
        {baseUnit.packagings.map((packaging) => (
          <PackagingInfo packaging={packaging} baseUnit={baseUnit} key={packaging.sku} />
        ))}
        <BillingTypeCard billingType={baseUnit.billing_type} />
      </Box>
      <Box sx={{ flex: 1, mt: -3 }} />
      <Box sx={{ display: 'flex', justifyContent: 'end', gap: 1 }}>
        <Chip
          variant="assistive"
          label={t('common:modify')}
          icon={<EditIcon />}
          component={Link}
          to={`${RoutePaths.PRODUCT_ADD_PACKAGING}?productId=${product.id}&baseUnitSku=${baseUnit.sku}`}
        />
        <Chip
          variant="assistive"
          label={t('common:delete')}
          disabled={product.base_units.length === 1}
          icon={<DeleteOutlinedIcon />}
          onClick={handleDeleteClick}
        />
      </Box>
      {isOpen && (
        <DeleteBaseUnitDialog
          open={isOpen}
          onClose={handleDeleteConfirm}
          subFamily={(product.sub_family as Product_Sub_Family_Name_Enum)}
          photo={baseUnit.main_photo}
        />
      )}
    </Card>
  );
}
