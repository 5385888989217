import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetAtom } from 'jotai';
import { Availability_Status_Enum, Base_Unit_Type_Enum } from 'kheops-graphql';
import { FormatBaseUnitQuantity } from 'kheops-utils';
import i18n from '../../i18n';
import { commonSnackbarPropsAtom } from '../../common/state/state';
import { ProductFragment } from '../products';
import { useInsertProductMutation } from '../../mutations/__generated__/insertProduct.generated';
import { PackagingsByCompanyDocument } from '../../queries/__generated__/packagingsByCompany.generated';
import ProductUtils from '../common/products.utils';

export interface EditAvailabilityDialogProps {
  product: ProductFragment;
  baseUnitIndex: number;
  open: boolean;
  onClose: (success?: boolean) => void;
}

export default function EditAvailabilityDialog({ product, baseUnitIndex, open, onClose }: EditAvailabilityDialogProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'products']);
  const [insertProduct, insertProductOutput] = useInsertProductMutation({ refetchQueries: ['ProductVersionById', PackagingsByCompanyDocument] });
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const numberFormatter = new Intl.NumberFormat(i18n.resolvedLanguage, { minimumFractionDigits: 0, maximumFractionDigits: 2 });
  const baseUnit = product.base_units[baseUnitIndex];
  // Not intuitive but UX want the switch to be the opposite value of the current one to avoid a click for the user
  const [available, setAvailable] = useState(!(baseUnit.availability === Availability_Status_Enum.Available));
  const bulk = baseUnit.unit_type === Base_Unit_Type_Enum.Bulk;

  const handleConfirm = (): void => {
    const productInsertInput = ProductUtils.convertProductFragmentIntoInsertInput(product);

    productInsertInput.base_units!.data[baseUnitIndex].availability = available ? Availability_Status_Enum.Available : Availability_Status_Enum.Unavailable;

    insertProduct({
      variables: { product: productInsertInput },
      update: (cache) => {
        cache.evict({ fieldName: 'product' });
      },
    });
  };

  const handleCancel = (): void => {
    onClose();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setAvailable(event.target.checked);
  };

  useEffect(() => {
    if (insertProductOutput.data?.insert_product) {
      onClose(true);
      setCommonSnackbarProps({
        label: t('common:update_successful'),
        snackbarProps: {
          open: true,
        },
      });
    }
  }, [insertProductOutput]);

  return (
    <Dialog open={open} onClose={handleCancel} onClick={(event) => event.stopPropagation()}>
      <DialogTitle>
        <Typography>
          {t('products:your_base_unit_availability')}
        </Typography>
        <Typography>{t('products:your_base_unit_availability_description')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="bodyMedium" sx={{ mt: 2, mb: '0.25rem' }}>
          {bulk ? t('packaging:bulk') : FormatBaseUnitQuantity(baseUnit, 'fr', numberFormatter)}
        </Typography>
        <FormControlLabel
          sx={{ ml: 0 }}
          control={<Switch checked={available} onChange={handleChange} />}
          label={(available ? t('products:this_base_unit_is_available') : t('products:this_base_unit_is_unavailable')) as string}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant="text">{t('common:cancel')}</Button>
        <Button onClick={handleConfirm} variant="contained" loading={insertProductOutput.loading}>
          {t('common:update')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
