import { Accordion, AccordionDetails, AccordionSummary, accordionClasses, accordionSummaryClasses, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAtom } from 'jotai';
import { ReactElement } from 'react';
import { currentExpandedAccordionAtom, FiltersName } from '../state/state';
import SelectFilterChip from '../../common/components/SelectFilterChip';

export type FilterDisplayMode = 'POPOVER' | 'ACCORDION';

interface FilterDisplayProps {
  label: string;
  activeItemCount: number;
  content: React.JSX.Element;
  mode?: FilterDisplayMode;
  name?: FiltersName;
  onClose?: () => void;
  icon?: ReactElement;
}

export default function FilterDisplay({ icon, label, activeItemCount, content, mode, name, onClose }: FilterDisplayProps): React.JSX.Element {
  const [currentExpandedAccordion, setCurrentExpandedAccordion] = useAtom(currentExpandedAccordionAtom);

  const handleAccordionChange = (): void => {
    setCurrentExpandedAccordion(currentExpandedAccordion === name ? null : name!);
  };

  if (mode === 'ACCORDION') {
    return (
      <Accordion
        expanded={currentExpandedAccordion === name}
        onChange={handleAccordionChange}
        disableGutters
        elevation={0}
        square
        sx={{
          borderRadius: 3,
          my: 2,
          '&:before': {
            display: 'none',
          },
          [`&.${accordionClasses.root}.${accordionClasses.expanded}`]: {
            my: 2,
          },
        }}
      >
        <AccordionSummary
          expandIcon={<KeyboardArrowDownIcon />}
          sx={{
            [`& .${accordionSummaryClasses.content}`]: {
              alignItems: 'center',
            },
          }}
        >
          {icon}
          <Typography variant="titleMedium" sx={{ ml: 0.5 }}>
            {label}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {content}
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <SelectFilterChip
      isFilterActive={!!activeItemCount}
      label={label}
      content={content}
      icon={icon}
      onClose={onClose}
    />

  );
}
