import { Card, Chip, Tooltip, Typography } from '@mui/material';
import { Billing_Type_Enum } from 'kheops-graphql';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useTranslation } from 'react-i18next';

interface BillingTypeCardProps {
  billingType: Billing_Type_Enum,
  inlineVersion?: boolean,
}

interface BillingTypeInformation {
  title: string,
  helperText: string,
}

const billingTypeToInformationMap = new Map<Billing_Type_Enum, BillingTypeInformation>([
  [Billing_Type_Enum.Fixed, { title: 'products:billed_per_unit', helperText: 'products:billed_per_unit_helper' }],
  [Billing_Type_Enum.WeightDependent, { title: 'products:billed_per_weight', helperText: 'products:billed_per_weight_helper' }],
]);

export default function BillingTypeCard({ billingType, inlineVersion }: BillingTypeCardProps): React.JSX.Element {
  const { t } = useTranslation(['products']);
  const billingTypeInformation = billingTypeToInformationMap.get(billingType);

  return (
    <Tooltip title={t(billingTypeInformation!.helperText)}>
      {inlineVersion
        ? (
          <Chip
            variant="tag"
            color="primaryContainer"
            icon={<ReceiptIcon />}
            label={t(billingTypeInformation!.title)}
          />
        )
        : (
          <Card
            sx={{
              width: 124,
              height: 70,
              p: 0,
              borderRadius: 2,
              textAlign: 'center',
              color: (theme) => theme.palette.onSurface.main,
              backgroundColor: (theme) => theme.palette.surfaceContainerLow.main,
            }}
          >
            <ReceiptIcon
              sx={{ mt: 1.5 }}
              fontSize="medium"
            />
            <Typography variant="bodyMedium">{t(billingTypeInformation!.title)}</Typography>
          </Card>
        )}
    </Tooltip>
  );
}
