import { Box, FormControlLabel, Switch, Tooltip, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProductPackagingsFormData } from '../ProductFormPackagings';

interface AvailabilityFormProps {
  index: number;
}

export default function BaseUnitAvailabilityForm({ index }: AvailabilityFormProps): React.JSX.Element {
  const { control } = useFormContext<ProductPackagingsFormData>();
  const { t } = useTranslation(['products']);

  return (
    <Box>
      <Typography variant="titleLarge">{t('products:your_base_unit_availability')}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
        <Controller
          name={`productPackagings.${index}.csu.availability`}
          control={control}
          render={({ field }) => (
            <FormControlLabel
              sx={{ ml: 0 }}
              control={<Switch checked={field.value} {...field} />}
              label={(field.value ? t('products:this_base_unit_is_available') : t('products:this_base_unit_is_unavailable')) as string}
            />
          )}
        />
        <Tooltip title={t('products:your_base_unit_availability_description')} placement="right-start">
          <HelpOutlineIcon fontSize="small" sx={{ color: 'text.secondary', fontSize: '0.875rem' }} />
        </Tooltip>
      </Box>
    </Box>
  );
}
