import { Container, Chip, List, ListItemButton, ListItemText, TextField, Typography } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRefinementList } from 'react-instantsearch';
import useNormalizedCurrentRefinements from '../../hooks/useNormalizedCurrentRefinements';
import FilterUtils from './filter.utils';

interface AdministrativeAreasByLevel {
  [key: string]: AreaInfo[];
}

interface AreaInfo {
  value: string;
  label: string;
}

export default function GeoLocationAdministrativeAreaFilter(): React.JSX.Element {
  const { items, refine, searchForItems } = useRefinementList({ limit: 1000, attribute: 'administrative_areas' });
  const currentItems = useNormalizedCurrentRefinements('administrative_areas') as string[];
  const [searchItemsValue, setSearchItemsValue] = useState('');
  const { t } = useTranslation(['discovery']);

  const handleAdministrativeAreaTextFieldChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchItemsValue(event.target.value);
    searchForItems(event.target.value);
  };

  const handleAdministrativeAreaClick = (value: string): void => {
    setSearchItemsValue('');
    refine(value);
  };

  const administrativeAreasOptions = useMemo(() => {
    if (!items.length) {
      return undefined;
    }

    const administrativeAreasByLevel = items.reduce((acc, area) => {
      const areaData = FilterUtils.ExtractAdministrativeAreaInfo(area.value);
      const areaInfo: AreaInfo = {
        value: area.value,
        label: areaData[1],
      };
      const level = acc[areaData[0]];

      if (level) {
        level.push(areaInfo);
      } else {
        acc[areaData[0]] = [areaInfo];
      }

      return acc;
    }, {} as AdministrativeAreasByLevel);

    return (
      <>
        {Object.keys(administrativeAreasByLevel).sort((a, b) => a.localeCompare(b)).map((key) => (
          <Container key={key}>
            <Typography variant="bodySmall">
              {t(`discovery:${key}`)}
            </Typography>
            <List dense>
              {
                administrativeAreasByLevel[key]
                  .sort(({ label: labelA }, { label: labelB }) => labelA.toLowerCase().localeCompare(labelB.toLowerCase()))
                  .map(({ value, label }) => (
                    <ListItemButton key={value} sx={{ pl: 1 }} onClick={() => handleAdministrativeAreaClick(value)}>
                      <ListItemText
                        primary={label}
                        primaryTypographyProps={{ variant: 'bodySmall' }}
                      />
                    </ListItemButton>
                  ))
              }
            </List>
          </Container>
        ))}
      </>
    );
  }, [items]);

  const selectedChips = useMemo(() => {
    return currentItems.map((area) => (
      <Chip
        sx={{ borderRadius: 2, m: 0.5 }}
        size="small"
        label={FilterUtils.ExtractAdministrativeAreaInfo(area)[1]}
        key={area}
        onDelete={() => refine(area)}
        deleteIcon={<ClearIcon />}
      />
    ));
  }, [currentItems]);

  return (
    <>
      <TextField
        value={searchItemsValue}
        variant="outlined"
        size="small"
        placeholder={t('discovery:administrative_area_placeholder')}
        sx={{ my: 2 }}
        slotProps={{
          input: {
            startAdornment: (
              <>
                <LocationOnOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
                {selectedChips}
              </>
            ),
            sx: {
              flexWrap: 'wrap',
              flex: 1,
              gap: 0,
            },
            inputProps: {
              sx: {
                p: 1,
                flex: 1,
                minWidth: 200,
              },
            },
          },
        }}
        onChange={handleAdministrativeAreaTextFieldChange}
      />
      {!!items.length && administrativeAreasOptions}
    </>
  );
}
