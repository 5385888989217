import { Avatar, Box, Typography } from '@mui/material';
import { Member } from '../state';
import PhoneNumberDisplay from '../../common/components/PhoneNumberDisplay';
import { useGetUserContactInformationQuery } from '../../queries/__generated__/getUserContactInformation.generated';
import PhotosUtils from '../../common/utils/photos.utils';

export interface ChannelCompanyMemberProps {
  member: Member;
}

export default function ChannelCompanyMember({ member }: ChannelCompanyMemberProps): React.JSX.Element {
  const { data: userContactInfoData } = useGetUserContactInformationQuery({
    variables: {
      userId: member.id,
    },
  });
  const user = userContactInfoData?.getUserContactInformation;

  if (!user) {
    return <></>;
  }

  return (
    <Box sx={{ display: 'flex', my: 1, pl: 2, pb: 1, gap: 1 }}>
      <Avatar variant="square" size={32} src={PhotosUtils.getUserAvatarPicture(member)} />
      <Box sx={{ alignSelf: 'center' }}>
        <Typography variant="bodyMedium" sx={{ mb: 0.5 }}>
          {member.first_name}&nbsp;{member.last_name}
        </Typography>
        {user.email && (
          <Typography variant="bodySmall" color="secondary" sx={{ my: 0.5 }}>
            {user.email}
          </Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            gap: {
              xs: 1,
              sm: 3,
            },
            mt: 0.5,
          }}
        >
          {user.mobilePhoneNumber && (
            <PhoneNumberDisplay
              typographyVariant="bodySmall"
              phoneNumber={user.mobilePhoneNumber}
              shouldDisplayFlag={false}
              phoneTypeEmoji="📱"
            />
          )}
          {user.landlinePhoneNumber && (
            <PhoneNumberDisplay
              typographyVariant="bodySmall"
              phoneNumber={user.landlinePhoneNumber}
              shouldDisplayFlag={false}
              phoneTypeEmoji="☎️"
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}
