import { Box, Button, Divider, Drawer, Typography, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
import { useInstantSearch } from 'react-instantsearch';
import { useTranslation } from 'react-i18next';
import FullTextSearch from '../../common/components/FullTextSearch';
import useOpenable from '../../hooks/useOpenable';
import GeoLocationFilter from '../../search/filters/GeoLocationFilter';
import ContractStatusFilter from '../../search/filters/ContractStatusFilter';
import ProductLabelsFilter from '../filters/ProductLabelsFilter';
import { SearchMode } from '../../hooks/useDiscoveryDefaultFilters';
import DiscoveryViewSwitch from '../DiscoveryViewSwitch';

export interface DiscoveryFiltersProps {
  contractFilterAttribute: string;
  fullTextSearchPlaceholder: string;
  hideLabelsFilter?: boolean;
  searchMode: SearchMode;
  index: string;
}

export default function DiscoveryFilters({ contractFilterAttribute, fullTextSearchPlaceholder, hideLabelsFilter, searchMode, index }: DiscoveryFiltersProps): React.JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation(['common', 'discovery', 'search']);
  const { indexUiState } = useInstantSearch();
  const { isOpen, open, close } = useOpenable();

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          gap: { xs: 1, lg: 2 },
        }}
      >
        <FullTextSearch
          inputProps={{
            placeholder: fullTextSearchPlaceholder,
            fullWidth: true,
            sx: {
              minWidth: {
                lg: '500px',
              },
            },
          }}
          value={indexUiState.query}
        />
        <Box
          sx={{
            display: {
              xs: 'none',
              lg: 'flex',
            },
          }}
        >
          <DiscoveryViewSwitch />
        </Box>
        <Button
          variant="outlined"
          startIcon={<TuneIcon />}
          onClick={open}
        >
          {t('common:filters')}
        </Button>
      </Box>
      <Drawer
        keepMounted
        open={isOpen}
        onClose={close}
        anchor={isMobile ? 'bottom' : 'right'}
        PaperProps={{
          sx: {
            gap: 2,
            width: {
              xs: undefined,
              lg: 375,
              borderRadius: '24px 0 0 24px',
            },
          },
        }}
      >
        <Box sx={{ px: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 2, position: 'sticky', top: 1, zIndex: 10, backgroundColor: 'white' }}>
            <Typography variant="headlineSmall" sx={{ lineHeight: '40px' }}>
              {t('common:filters')}
            </Typography>
            <Button variant="text" onClick={close}>
              <CloseIcon />
            </Button>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <GeoLocationFilter mode="ACCORDION" index={index} />
            {!hideLabelsFilter && <ProductLabelsFilter mode="ACCORDION" />}
            <ContractStatusFilter attribute={contractFilterAttribute} mode="ACCORDION" searchMode={searchMode} />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            position: 'sticky',
            zindex: 10,
            bottom: 0,
            mt: 'auto',
          }}
        >
          <Divider flexItem sx={{ width: '100%' }} />
          <Button variant="contained" onClick={close} sx={{ mx: 'auto', my: 2 }}>
            {t('search:validate_changes')}
          </Button>
        </Box>
      </Drawer>
    </Box>
  );
}
