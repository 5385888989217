import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Chip, Button, Tooltip, Typography } from '@mui/material';
import i18n from 'i18next';
import { useSetAtom } from 'jotai';
import { FormatBaseUnitQuantity, FormatLogisticalPackaging } from 'kheops-utils';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QuantitySelector from '../common/components/quantitySelector/QuantitySelector';
import FileUtils from '../common/utils/file.utils';
import useCurrencyFormat from '../hooks/useCurrencyFormat';
import { BasketItem } from './state/basket';
import { currentBasketItemsAtom } from './state/state';
import useBasketItem from '../hooks/useBasketItem';
import { KheopsTypographyVariant } from '../providers/CustomThemeProvider';
import { ellipsisStyle } from '../common/utils/style.utils';

export interface ItemSummaryProps {
  item: BasketItem;
  editable?: boolean;
  priceVariant?: KheopsTypographyVariant;
}

function BasketItemSummary({ item, editable, priceVariant = 'labelMedium' }: ItemSummaryProps): React.JSX.Element {
  const { t } = useTranslation('common');
  const currencyFormat = useCurrencyFormat();
  const currentItem = useBasketItem(`${item.packaging.id}_${item.contract_id}`)?.quantity || 0;
  const editBasketItem = useSetAtom(currentBasketItemsAtom);
  const packagingPhotoUrl = FileUtils.BuildPackagingImageUrl({
    photo: item.packaging.base_unit?.main_photo,
    type: item.packaging.product!.sub_family,
    size: { height: 112 },
  });
  const totalPrice = currentItem * item.packaging.price;
  const numberFormatter = new Intl.NumberFormat(i18n.resolvedLanguage, { minimumFractionDigits: 0, maximumFractionDigits: 2 });

  const packagingDetails = useMemo(() => {
    return {
      name: item.packaging.product!.name,
      baseUnit: FormatBaseUnitQuantity(item.packaging.base_unit!, 'fr', numberFormatter),
      logisticalPackaging: FormatLogisticalPackaging(item.packaging, 'fr', item.packaging.base_unit!),
    };
  }, [item]);

  const handleChange = (newQuantity: number): void => {
    editBasketItem({
      id: item.id,
      packagingId: item.packaging.id,
      quantity: newQuantity,
      contractId: item.contract_id,
    });
  };

  return (
    <Box sx={{ display: 'flex', mb: 2 }}>
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            height: 56,
            width: 56,
            textAlign: 'center',
            borderRadius: 3,
            overflow: 'hidden',
          }}
        >
          <Box
            component="img"
            src={packagingPhotoUrl}
            alt={t('common:packaging_one')}
            sx={{
              maxWidth: 56,
              maxHeight: 56,
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mx: 1,
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <Tooltip title={packagingDetails.name} placement="top-start">
          <Typography
            variant="bodyMedium"
            sx={{
              ...ellipsisStyle,
            }}
          >
            {packagingDetails.name}
          </Typography>
        </Tooltip>
        <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between', mt: 0.5 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
            <Chip variant="tag" label={packagingDetails.baseUnit} />
            <Typography variant="bodySmall" color="secondary" sx={{ ml: 1, mt: 0.5 }}>
              {packagingDetails.logisticalPackaging}
            </Typography>
          </Box>
          {editable
            ? (
              <Button variant="text" onClick={() => handleChange(0)} sx={{ marginLeft: 'auto' }}>
                <DeleteOutlineIcon />
              </Button>
            )
            : (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', gap: 0.5 }}>
                <Typography variant={priceVariant} color="primary">
                  {currencyFormat.format(totalPrice)}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 0.5,
                  }}
                >
                  <Typography variant="bodySmall" color="secondary" sx={{ whiteSpace: 'nowrap' }}>
                    {`${t('basket:abbreviated_quantity')} :`}
                  </Typography>
                  <QuantitySelector
                    quantity={currentItem}
                    onQuantityChange={handleChange}
                    shouldDisplayButtons={false}
                  />
                </Box>
              </Box>
            )}
        </Box>
        {editable && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mb: 1 }}>
            <Typography variant={priceVariant} color="primary" sx={{ mr: 1 }}>
              {currencyFormat.format(totalPrice)}
            </Typography>
            <QuantitySelector quantity={currentItem} onQuantityChange={handleChange} />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default React.memo(BasketItemSummary);
