import { useTranslation } from 'react-i18next';
import { Brand_Enum, Business_Profile_Enum, Maybe } from 'kheops-graphql';
import SizedImage from '../common/components/SizedImage';
import FileUtils, { FileParameters } from '../common/utils/file.utils';
import CompanyLogo from '../common/components/CompanyLogo';

export interface ChannelCompany {
  businessProfile: Business_Profile_Enum;
  photo: FileParameters;
  brand?: Maybe<Brand_Enum>;
}

export interface ChannelCompanyImageProps {
  company: ChannelCompany;
}

export default function ChannelCompanyImage({ company }: ChannelCompanyImageProps): React.JSX.Element {
  const { t } = useTranslation(['common']);

  return (
    <SizedImage
      src={
        FileUtils.BuildCompanyImageUrl({
          photo: company.photo,
          size: { height: 40, width: 40 },
          type: company.businessProfile,
        })
      }
      overlay={
        company.brand && (
          <CompanyLogo
            brand={company.brand}
            variant="transparent"
            size="small"
            sx={{
              position: 'absolute',
              bottom: -8,
              right: -4,
            }}
          />
        )
      }
      alt={t('common:company')}
      height={40}
      width={40}
      borderRadius={4}
      sx={{
        overflow: 'visible',
        '& img': {
          borderRadius: 4,
        },
        '& .Kheops-brand': {
          width: 24,
          height: 24,
          position: 'absolute',
          bottom: -4,
          right: 0,
          borderRadius: 4,
        },
        '& .Kheops-brandLogo': {
          width: 16,
        },
      }}
    />
  );
}
