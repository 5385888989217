import { Box, Button, SxProps } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router';
import { useFormContext } from 'react-hook-form';
import { RoutePaths } from '../../routes/AppRoutes';
import { DraftCustomPriceList } from './state';

export interface CustomPriceListFormButtonsProps {
  copy?: boolean;
  edit?: boolean;
  fullWidth?: boolean;
  parentBoxSx?: SxProps;
  shouldDisplayCancelButton?: boolean;
}

export default function CustomPriceListFormButtons({ edit, copy, fullWidth, parentBoxSx, shouldDisplayCancelButton = true }: CustomPriceListFormButtonsProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'price']);
  const [searchParams] = useSearchParams();
  const contractId = searchParams.get('contractId') || undefined;
  const navigate = useNavigate();
  const { formState: { isDirty, isValid, isSubmitted } } = useFormContext<DraftCustomPriceList>();

  const handleCancel = (): void => {
    if (contractId) {
      window.history.back();
    } else {
      navigate(RoutePaths.COMPANY_CUSTOM_PRICE_LISTS);
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, py: 2, ...parentBoxSx }}>
      {shouldDisplayCancelButton && (
        <Button
          onClick={handleCancel}
          variant="text"
          sx={{ flexGrow: fullWidth ? 1 : 0 }}
        >
          {t('common:cancel')}
        </Button>
      )}
      <Button
        disabled={(!isDirty && !copy) || (!isValid && isSubmitted)}
        variant="contained"
        type="submit"
        startIcon={edit ? <EditIcon /> : <AddIcon />}
        sx={{ flexGrow: fullWidth ? 1 : 0 }}
      >
        {t(edit ? 'price:edit_the_custom_price_list' : 'price:create_the_custom_price_list')}
      </Button>
    </Box>
  );
}
