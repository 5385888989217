import { Box, Breakpoint, Chip, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { Link, generatePath } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { AlgoliaHit } from 'instantsearch.js';
import { Base_Unit_Type_Enum, Billing_Type_Enum, Business_Profile_Enum, Contract_Status_Enum, Measurement_Unit_Enum } from 'kheops-graphql';
import { formatSuggestedRetailPrice, getConvertedSuggestedRetailPrice } from 'kheops-utils';
import { RoutePaths } from '../../routes/AppRoutes';
import { ProductHit } from '../../search/search';
import SearchUtils from '../../common/utils/search.utils';
import SizedImage from '../../common/components/SizedImage';
import FileUtils, { PhotoSize } from '../../common/utils/file.utils';
import { ellipsisStyle, linkStyle } from '../../common/utils/style.utils';
import useCurrentBreakpoint from '../../hooks/useCurrentBreakpoint';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';
import { imageSizeByBreakpoint } from '../state';
import { contractsAtom, currentContextAtom } from '../../state';
import { getProductUrlFromProductHit } from '../discovery.utils';
import QualityLabels from '../../search/product/QualityLabels';
import ImageDarkOverlay from '../../common/components/ImageDarkOverlay';
import usePackagingCTAButton from '../../hooks/usePackagingCTAButton';
import { getBaseUnitExcludedTaxMargin } from '../../common/utils/common.utils';

export interface DiscoverProductHitProps {
  hit: AlgoliaHit<ProductHit>;
  shouldDisplayCompany?: boolean;
  shouldDisplayCTAButon?: boolean;
  shouldDisplayPVCMargin?: boolean;
  productImageSizeByBreakPoint?: Record<Breakpoint, PhotoSize>;
  productBoxSx?: SxProps;
  parentBoxSx?: SxProps;
}

export default function DiscoveryProductHit({
  hit,
  shouldDisplayCTAButon = false,
  shouldDisplayCompany = false,
  shouldDisplayPVCMargin = false,
  productImageSizeByBreakPoint = imageSizeByBreakpoint,
  productBoxSx,
  parentBoxSx,
}: DiscoverProductHitProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'contracts', 'price']);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const currencyFormat = useCurrencyFormat();
  const breakpoint = useCurrentBreakpoint();
  const contracts = useAtomValue(contractsAtom);
  const packagingUrl = getProductUrlFromProductHit(hit);
  const { companyAddress } = useAtomValue(currentContextAtom);

  const contract = useMemo(() => {
    return contracts.find(({ supplyingCompanyId }) => hit.company_id === supplyingCompanyId);
  }, [contracts]);

  const canDeliver = useMemo(() => {
    if (contract?.status !== Contract_Status_Enum.Signed && hit.delivery_zones?.length) {
      return SearchUtils.canItDeliverAnArea(hit.delivery_zones, companyAddress.administrativeAreaLevel2!);
    }

    return true;
  }, [hit, contract]);

  const ctaButton = usePackagingCTAButton({
    packagingId: hit.packaging_id,
    supplierCompanyId: hit.company_id,
    variant: 'outlined',
  });
  const suggestedRetailPriceMargin = shouldDisplayPVCMargin && hit.suggestedRetailPrice
    ? getBaseUnitExcludedTaxMargin(
      hit.billingType === Billing_Type_Enum.WeightDependent && hit.baseUnitType !== Base_Unit_Type_Enum.Bulk
        ? getConvertedSuggestedRetailPrice(hit.suggestedRetailPrice, hit.baseUnitNetContent!, hit.baseUnitMeasurementUnit!)
        : hit.suggestedRetailPrice,
      hit.baseUnitType === Base_Unit_Type_Enum.Bulk ? hit.volumePrice.value : hit.price / hit.unitQuantity,
      hit.productVatRate,
    )
    : undefined;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        height: '100%',
        borderRadius: 6,
        backgroundColor: 'surfaceContainerLowest.main',
        overflow: 'hidden',
        ...parentBoxSx,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: {
            xs: 'row-reverse',
            sm: 'column',
          },
          alignItems: {
            xs: 'center',
            sm: 'unset',
          },
          gap: 1,
          flex: 1,
          ...productBoxSx,
        }}
      >
        {!isXs && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 0.5,
              position: 'absolute',
              top: 24,
              left: 24,
              zIndex: 5,
            }}
          >
            <QualityLabels
              labels={hit.labels}
              size={32}
              iconSx={{
                height: 32,
                width: 32,
                textAlign: 'center',
              }}
            />
          </Box>
        )}
        {suggestedRetailPriceMargin
          && (
          <Chip
            variant="tag"
            label={t('price:percentage_of_margin', { margin: suggestedRetailPriceMargin })}
            sx={{
              position: 'absolute',
              zIndex: 5,
              top: 16,
              right: 16,
            }}
          />
          )}
        <Link
          to={packagingUrl}
          state={{ origin: 'DISCOVERY' }}
        >
          <SizedImage
            src={FileUtils.BuildPackagingImageUrl({ photo: hit.photo, type: hit.family, size: { ...productImageSizeByBreakPoint[breakpoint], fit: 'contain' } })}
            alt={t('common:product_one')}
            height={productImageSizeByBreakPoint[breakpoint].width!}
            width={productImageSizeByBreakPoint[breakpoint].height!}
            borderRadius={0}
            sx={{
              mx: 'auto',
            }}
            overlay={
              !canDeliver && (
                <ImageDarkOverlay label={t('discovery:is_not_delivered_in_your_area')} />
              )
            }
          />
        </Link>
        <Box
          sx={{
            minWidth: 0,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            my: 2,
            mr: {
              xs: 0.5,
              sm: 2,
            },
            ml: 2,
          }}
        >
          {shouldDisplayCompany && (
            <Box
              component={Link}
              to={generatePath(RoutePaths.CONTRACT_COMPANY, { companyId: hit.company_id })}
              sx={{
                maxWidth: '100%',
                display: 'flex',
                flexWrap: 'nowrap',
                alignItems: 'center',
                ...linkStyle,
                mb: 0.5,
              }}
            >
              <Box
                component="img"
                src={FileUtils.BuildCompanyImageUrl({ photo: hit.companyPhoto, type: Business_Profile_Enum.Supplier, size: { height: 24, fit: 'contain' } })}
                sx={{
                  height: 24,
                  width: 24,
                  borderRadius: '50%',
                  marginRight: 0.5,
                }}
                alt={t('common:company')}
              />
              <Typography variant="bodySmall" sx={ellipsisStyle}>
                {hit.company}
              </Typography>
            </Box>
          )}
          <Box component={Link} to={packagingUrl} sx={{ mb: 1, ...linkStyle }}>
            <Typography variant="bodyMedium" sx={{ ...ellipsisStyle }}>
              {hit.name}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 0.5, mb: 1 }}>
            <Chip variant="tag" label={hit.baseUnit} sx={{ mr: 0.5 }} />
            {isXs && (
              <QualityLabels
                labels={hit.labels}
                size={24}
                iconSx={{
                  height: 24,
                  width: 24,
                  textAlign: 'center',
                }}
              />
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="titleLarge" color="primary">
                {SearchUtils.getFormatedUnitPrice(hit, currencyFormat.format)}
              </Typography>
              {hit.suggestedRetailPrice && (
                <Typography variant="bodySmall" color="onSurfaceVariant">
                  {t('price:suggest_retail_price_acronym')} : {
                    formatSuggestedRetailPrice({
                      suggestedRetailPrice: hit.suggestedRetailPrice,
                      billingType: hit.billingType,
                      unitType: hit.baseUnitType,
                      netContent: (hit.baseUnitNetContent || hit.packagingNetContent) as number,
                      measurementUnit: (hit.baseUnitMeasurementUnit || hit.packagingMeasurementUnit) as Measurement_Unit_Enum,
                    })
                  }
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {shouldDisplayCTAButon && (
        <Box sx={{ display: 'flex', justifyContent: 'center', px: 2, pb: 2 }}>
          {ctaButton}
        </Box>
      )}
    </Box>
  );
}
