import { Box, Button, Card, TextField, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAtomValue } from 'jotai';
import { sendbirdSelectors, useSendbirdStateContext } from '@sendbird/uikit-react';
import { BusinessProfile, Business_Profile_Enum } from 'kheops-graphql';
import { RoutePaths } from '../routes/AppRoutes';
import { useCreateContractRequestMutation } from '../mutations/__generated__/createContractRequest.generated';
import { CreateGroupChannelMutation, useCreateGroupChannelMutation } from '../mutations/__generated__/createGroupChannel.generated';
import { currentContextAtom, userAtom } from '../state';
import { ContractPreview } from '../hooks/useContracts';
import { ContractsAsSupplierDocument } from '../queries/__generated__/contractsAsSupplier.generated';
import { CompanyByIdDocument } from '../queries/__generated__/companyById.generated';
import { ContractsAsBuyerDocument } from '../queries/__generated__/contractsAsBuyer.generated';
import ContactsByDepartment from './buyer/contacts/ContactsByDepartment';

export interface CompanyContactsProps {
  companyId: string;
  contract?: ContractPreview;
}

export default function CompanyContacts({ companyId, contract }: CompanyContactsProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'contracts']);
  const navigate = useNavigate();
  const context = useAtomValue(currentContextAtom);
  const { id: userId } = useAtomValue(userAtom);
  const [createContractRequest, { data: createContractRequestData, loading: loadingContractCreation }] = useCreateContractRequestMutation({
    refetchQueries: [CompanyByIdDocument, ContractsAsSupplierDocument, ContractsAsBuyerDocument],
    awaitRefetchQueries: true,
  });
  const [createGroupChannel, { data: dataChannel, loading: loadingChannelCreation }] = useCreateGroupChannelMutation({ refetchQueries: [ContractsAsSupplierDocument] });
  const [message, setMessage] = useState('');

  const globalStore = useSendbirdStateContext();
  const sdkInstance = sendbirdSelectors.getSdk(globalStore);

  const handleContractRequestSubmit = useCallback((): void => {
    createContractRequest({
      variables: {
        userId,
        buyingCompanyId: companyId,
        supplyingCompanyId: context.companyId!,
        fromBusinessProfile: context.realm as unknown as BusinessProfile,
        message,
      },
    });
  }, [context, message]);

  const handleSeeDiscussionClick = useCallback(async (): Promise<void> => {
    let data: CreateGroupChannelMutation | null | undefined;

    if (!contract?.channel_url) {
      let buyingCompanyId: string;
      let supplyingCompanyId: string;

      if (context.realm === Business_Profile_Enum.Buyer) {
        buyingCompanyId = context.companyId!;
        supplyingCompanyId = companyId;
      } else {
        buyingCompanyId = companyId;
        supplyingCompanyId = context.companyId!;
      }

      data = (await createGroupChannel({ variables: { buyingCompanyId, supplyingCompanyId, fromBusinessProfile: context.realm as unknown as BusinessProfile } })).data;
    }

    if (data?.createGroupChannel?.channelUrl) {
      navigate(`${RoutePaths.CHAT_LIST}?channelUrl=${data.createGroupChannel.channelUrl}`);
    } else {
      navigate(`${RoutePaths.CHAT_LIST}?channelUrl=${contract!.channel_url}`);
    }
  }, [contract]);

  useEffect(() => {
    if (createContractRequestData?.createContractRequest?.channelUrl) {
      navigate(`${RoutePaths.CHAT_LIST}?channelUrl=${createContractRequestData.createContractRequest.channelUrl}`);
    }
  }, [createContractRequestData]);

  useEffect(() => {
    const sendMessage = async (): Promise<void> => {
      const createdChannelUrl = dataChannel!.createGroupChannel.channelUrl;
      const channel = await sdkInstance?.groupChannel.getChannel(createdChannelUrl);
      const messageRequestHandler = channel!.sendUserMessage({
        message,
      });

      messageRequestHandler.onSucceeded(() => {
        navigate(`${RoutePaths.CHAT_LIST}?channelUrl=${createdChannelUrl}`);
      });
    };

    if (dataChannel?.createGroupChannel.channelUrl) {
      sendMessage();
    }
  }, [dataChannel, message]);

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box>
        <Typography variant="displaySmall" sx={{ mb: 1 }}>
          {t('contracts:contact_this_store')}
        </Typography>
        <Typography variant="bodySmall" color="secondary">
          {t('contracts:contact_this_store_description')}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Typography variant="titleMedium">
          {t('contracts:your_recipients')}
        </Typography>
        <ContactsByDepartment companyId={companyId} />
      </Box>
      {!contract?.status && (
        <>
          <Typography variant="bodyMedium">
            {t('contracts:your_message')}
          </Typography>
          <TextField
            placeholder={t('contracts:type_your_message')}
            onChange={(event) => setMessage(event.target.value)}
            multiline
            minRows={3}
          />
          <Button
            onClick={handleContractRequestSubmit}
            loading={loadingContractCreation}
            variant="contained"
            disabled={!message}
            startIcon={<SendIcon />}
            sx={{
              alignSelf: {
                sm: 'center',
              },
            }}
          >
            {t('common:send')}
          </Button>
        </>
      )}
      {contract && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            justifyContent: {
              sm: 'center',
            },
            gap: 2,
            py: 2,
          }}
        >
          <Button
            variant="contained"
            onClick={() => handleSeeDiscussionClick()}
            loading={loadingChannelCreation}
            startIcon={<AddBusinessOutlinedIcon />}
          >
            {t('contracts:resume_negociation')}
          </Button>
        </Box>
      )}
    </Card>
  );
}
