import { Alert, Box, Snackbar, useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAtom, useAtomValue } from 'jotai';
import * as Sentry from '@sentry/react';
import { Camera, DataCaptureContext, configure } from 'scandit-web-datacapture-core';
import { BarcodeCapture, BarcodeCaptureSettings, Symbology, barcodeCaptureLoader } from 'scandit-web-datacapture-barcode';
import { SendBirdProvider } from '@sendbird/uikit-react';
import { GroupChannelListProvider } from '@sendbird/uikit-react/GroupChannelList/context';
import { GroupChannelListOrder } from '@sendbird/chat/groupChannel';
import { fr } from 'date-fns/locale';
import { Business_Profile_Enum } from 'kheops-graphql';
import useAuth from '../hooks/useAuth/useAuth';
import { barcodeCaptureAtom } from '../search/state/state';
import { setAxiosConfiguration } from '../common/utils/axios.utils';
import { Analytics } from '../common/utils/analytics';
import { RoutePaths } from './AppRoutes';
import TopMenu from '../topMenu/TopMenu';
import SettingsDialog from '../user/settingsDialog/SettingsDialog';
import SendbirdFrStringSet from '../assets/locales/fr/sendbird.json';
import { channelListQueryParamsAtom } from '../chats/state';
import NavigationTabs from '../topMenu/NavigationTabs';
import { SNACKBAR_DEFAULT_HIDE_DURATION, currentContextAtom, userAtom } from '../state';
import useCurrentUser from '../hooks/useCurrentUser';
import useBasket from '../basket/useBasket';
import useContracts from '../hooks/useContracts';
import { commonSnackbarPropsAtom } from '../common/state/state';

export function RequireAuth(): React.JSX.Element {
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const matchNavigationPage = useMatch(`${RoutePaths.NAVIGATION}/*`);
  const matchUserCompanyPage = useMatch(`${RoutePaths.COMPANY}/*`);
  const matchUserMyCompanyPage = useMatch(RoutePaths.MY_COMPANY);
  const matchProductAddPage = useMatch(`${RoutePaths.PRODUCT_ADD}/*`);
  const matchProductPage = useMatch(RoutePaths.PRODUCT);
  const matchUserPage = useMatch(RoutePaths.USER);
  const matchCustomPriceListForm = useMatch(RoutePaths.CUSTOM_PRICE_LIST);
  const matchSubCatalogForm = useMatch(RoutePaths.SUB_CATALOG);
  const { isAuthenticated, isLoading, logout } = useAuth();
  const user = useAtomValue(userAtom);
  const context = useAtomValue(currentContextAtom);
  const [barcodeCapture, setBarcodeCapture] = useAtom(barcodeCaptureAtom);
  const [channelListQueryParams, setChannelListQueryParams] = useAtom(channelListQueryParamsAtom);
  const [commonSnackbarProps, setCommonSnackbarProps] = useAtom(commonSnackbarPropsAtom);
  const location = useLocation();
  const matchAnyUserCompanyPage = matchNavigationPage || matchUserCompanyPage || matchUserPage || matchSubCatalogForm || matchCustomPriceListForm || matchUserMyCompanyPage;
  const matchAnyProductPage = matchProductAddPage || matchProductPage;
  const displayNavigation = isDownLg && !matchAnyUserCompanyPage && !matchAnyProductPage;
  const displayTopMenu = !isDownLg || !matchAnyUserCompanyPage;

  useCurrentUser();
  useBasket();

  const contractsReady = useContracts();

  const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason: string): void => {
    if (reason === 'timeout') {
      setCommonSnackbarProps(undefined);
    }
  };

  useEffect(() => {
    setAxiosConfiguration(logout);
  }, []);

  useEffect(() => {
    if (user) {
      Analytics.identify(user.id);

      Sentry.setUser({
        id: user.id,
        email: user.email,
        ip_address: '{{auto}}',
      });

      if (!barcodeCapture) {
        const initScandit = async (): Promise<void> => {
          await configure({
            licenseKey: import.meta.env.REACT_APP_SCANDIT_LICENCE_KEY,
            libraryLocation: 'scandit/',
            moduleLoaders: [barcodeCaptureLoader()],
          });

          const dataCaptureContext = await DataCaptureContext.create();

          const camera = Camera.default;
          await dataCaptureContext.setFrameSource(camera);

          const settings = new BarcodeCaptureSettings();

          settings.enableSymbologies([
            Symbology.EAN13UPCA,
          ]);

          const newBarcodeCapture = await BarcodeCapture.forContext(dataCaptureContext, settings);
          await newBarcodeCapture.setEnabled(false);

          setBarcodeCapture(newBarcodeCapture);
        };

        initScandit();
      }
    } else {
      Analytics.reset();
    }
  }, [user, barcodeCapture]);

  useEffect(() => {
    if (context.companyId && (!channelListQueryParams?.metadataValues?.includes(context.companyId))) {
      setChannelListQueryParams({
        metadataKey: context.realm === Business_Profile_Enum.Buyer ? 'buyingCompanyId' : 'supplyingCompanyId',
        metadataValues: [context.companyId!],
        includeEmpty: true,
        includeMetaData: true,
        order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
      });
    }
  }, [context.companyId, channelListQueryParams]);

  useEffect((): void => {
    if (context.companyId === window.history.state.newContextId) {
      setCommonSnackbarProps({
        label: t('common:you_are_now_connected_with_context', { companyName: context.companyName }),
        snackbarProps: {
          open: true,
        },
      });
    }
  }, [context]);

  if (!isAuthenticated && !isLoading) {
    return <Navigate to={RoutePaths.LOGIN} state={location} />;
  }

  if (isLoading || !user.id || !contractsReady) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <>
      <SendBirdProvider
        appId={import.meta.env.REACT_APP_SENDBIRD_APP_ID}
        userId={user.id}
        accessToken={user.sendbirdAccessToken}
        dateLocale={fr}
        stringSet={SendbirdFrStringSet}
        colorSet={{
          '--sendbird-light-primary-300': theme.palette.secondaryContainer.main,
          '--sendbird-light-primary-400': theme.palette.secondaryContainer.main,
          '--sendbird-light-secondary-300': theme.palette.primary.main,
          '--sendbird-light-onlight-02': theme.palette.onSurfaceVariant.main,
          '--sendbird-light-ondark-01': theme.palette.onSurface.main,
          '--sendbird-light-background-100': theme.palette.surfaceContainer.main,
          '--sendbird-light-background-200': theme.palette.surfaceContainerHigh.main,
        }}
      >
        <GroupChannelListProvider
          channelListQueryParams={channelListQueryParams}
          onChannelSelect={() => { /* noop */ }}
          onChannelCreated={() => { /* noop */ }}
        >
          <>
            {displayTopMenu && <TopMenu />}
            <Box sx={{ flex: 1 }}>
              <Outlet />
            </Box>
            {!user.isStaff && (<SettingsDialog />)}
            {displayNavigation && (
              <NavigationTabs
                sx={{
                  px: 1,
                  zIndex: 10,
                  position: 'sticky',
                  bottom: 0,
                  borderTop: '1px solid',
                  borderColor: 'divider',
                  backgroundColor: 'white',
                  '& .MuiTabs-flexContainer': {
                    justifyContent: 'space-between',
                    gap: 1,
                    my: 0.5,
                  },
                  '& .MuiTab-root': {
                    flex: 1,
                    minWidth: 'unset',
                  },
                }}
              />
            )}
          </>
        </GroupChannelListProvider>
      </SendBirdProvider>
      <Snackbar
        open={commonSnackbarProps?.snackbarProps.open}
        autoHideDuration={SNACKBAR_DEFAULT_HIDE_DURATION}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{
          left: 'unset',
          mr: {
            xs: 1,
            sm: 10,
          },
          ml: 2,
          mb: 1,
        }}
        onClose={handleSnackbarClose}
        {...commonSnackbarProps?.snackbarProps}
      >
        <Alert
          variant="filled"
          severity="success"
          sx={{ width: '100%' }}
          {...commonSnackbarProps?.alertProps}
        >
          {commonSnackbarProps?.label}
        </Alert>
      </Snackbar>
    </>
  );
}
