import CloseIcon from '@mui/icons-material/Close';
import { Button, ButtonOwnProps } from '@mui/material';

interface DialogCloseButtonProps {
  onClick: () => void;
  variant?: ButtonOwnProps['variant'];
}

export default function DialogCloseButton({ onClick, variant = 'text' }: DialogCloseButtonProps): React.JSX.Element {
  return (
    <Button
      variant={variant}
      onClick={onClick}
      sx={{
        position: 'absolute',
        top: 16,
        right: 16,
        zIndex: 100,
      }}
    >
      <CloseIcon />
    </Button>
  );
}
