import { Autocomplete, Box, TextField } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CountrySetFr from '../../assets/world.json';

interface Country {
  id: number;
  alpha2: string;
  alpha3: string;
  name: string;
}

export interface CountryAutocompleteProps {
  value?: string;
  onChange: (value: string | undefined) => void;
}

const CountryToDisplayCodes = [276, 20, 56, 724, 250, 380, 442, 492, 756];

// From https://mui.com/material-ui/react-autocomplete/#country-select
export default function CountryAutocomplete({ value, onChange }: CountryAutocompleteProps): React.JSX.Element {
  const { t } = useTranslation(['common']);
  const Countries = CountrySetFr.filter(({ id }) => CountryToDisplayCodes.includes(id));
  const [currentCountry, setCurrentCountry] = useState<Country | null>(Countries.find(({ name }) => name === value) || null);

  const handleChange = useCallback((newValue: Country | null): void => {
    const newCountry = (newValue && Countries.find(({ name }) => name === newValue!.name)) || null;

    setCurrentCountry(newCountry);
    onChange(newCountry?.name);
  }, [onChange]);

  return (
    <Autocomplete
      value={currentCountry}
      onChange={(_, newValue) => handleChange(newValue)}
      options={Countries}
      autoHighlight
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => {
        return (
          <Box
            {...props}
            key={option.id}
            component="li"
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          >
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.alpha2}.png`}
              alt=""
            />
            {option.name}
          </Box>
        );
      }}
      noOptionsText={t('common:no_result')}
      renderInput={(params) => {
        const inputParams = {
          ...params,
          InputProps: {
            ...params.InputProps,
            startAdornment: currentCountry && (
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${currentCountry.alpha2}.png`}
                alt=""
              />
            ),
          },
        };

        return (
          <TextField
            {...inputParams}
            placeholder={t('common:country')}
            slotProps={{
              htmlInput: {
                ...inputParams.inputProps,
              },
            }}
          />
        );
      }}
    />
  );
}
