import { Box, Button, ButtonTypeMap, SxProps, Typography } from '@mui/material';
import { Link } from 'react-router';

export interface EmptyPagePlaceholderProps {
  title: string;
  description?: string;
  imageSrc: string;
  buttonLabel?: string;
  // linkDestination and onClick cannot be set at the same time
  linkDestination?: string;
  onClick?: () => void;
  sx?: SxProps;
  buttonProps?: ButtonTypeMap<object, 'button'>['props'];
}

export default function EmptyPagePlaceholder({ title, description, imageSrc, buttonLabel, linkDestination, onClick, sx, buttonProps }: EmptyPagePlaceholderProps): React.JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: 2,
        pt: 2,
        textAlign: 'center',
        ...sx,
      }}
    >
      <Typography variant="displayLarge">
        {title}
      </Typography>
      <Box
        component="img"
        src={imageSrc}
        sx={{
          width: {
            xs: 196,
            lg: 256,
          },
        }}
      />
      {description && (
        <Typography variant="bodyMedium">
          {description}
        </Typography>
      )}
      {linkDestination && (
        <Button
          variant="contained"
          component={Link}
          to={linkDestination}
          {...buttonProps}
        >
          {buttonLabel}
        </Button>
      )}
      {onClick && (
        <Button
          variant="contained"
          onClick={onClick}
          {...buttonProps}
        >
          {buttonLabel}
        </Button>
      )}
    </Box>
  );
}
