import { TextField, Box, InputAdornment, Button, Avatar } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useAtomValue } from 'jotai';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInsertCommentMutation } from '../../../mutations/__generated__/insertComment.generated';
import { userAtom } from '../../../state';
import PhotosUtils from '../../../common/utils/photos.utils';

export interface AddCommentProps {
  orderReferenceId: string;
}

export default function AddComment({ orderReferenceId }: AddCommentProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'comments']);
  const user = useAtomValue(userAtom);
  const [insertComment, { data, loading }] = useInsertCommentMutation({ refetchQueries: ['commentsByOrder'] });
  const [comment, setComment] = useState('');

  const handlePublishClick = (): void => {
    insertComment({
      variables: {
        comment: {
          content: comment,
          user_id: user.id,
          order_reference_id: orderReferenceId,
        },
      },
    });
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setComment(event.target.value);
  };

  useEffect(() => {
    if (data?.insert_comment) {
      setComment('');
    }
  }, [data]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      <Avatar size={40} src={PhotosUtils.getUserAvatarPicture(user.avatars)} />
      <TextField
        sx={{
          width: '100%',
        }}
        multiline
        placeholder={t('comments:write_a_comment')}
        value={comment}
        onChange={handleCommentChange}
        autoFocus
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  onClick={handlePublishClick}
                  disabled={loading || !comment}
                  variant="contained"
                >
                  <SendIcon />
                </Button>
              </InputAdornment>
            ),
          },
        }}
      />
    </Box>
  );
}
