import { Button } from '@mui/material';
import { SxProps } from '@mui/system';
import { useAtomValue } from 'jotai';
import { Business_Profile_Enum } from 'kheops-graphql';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import { RoutePaths } from '../routes/AppRoutes';
import { isBasketLoadingAtom, isRemoteBasketEmptyAtom } from '../basket/state/state';
import { currentContextAtom } from '../state';

interface CTAButtonProp {
  to: string;
  labelKey: string;
}

interface TopMenuCTAButtonProps {
  sx?: SxProps;
  onClick?: () => void;
}

type CTAButtonPropMap = {
  [profile in Business_Profile_Enum]: CTAButtonProp
}

const basketCTAButtonProps: CTAButtonPropMap = {
  [Business_Profile_Enum.Buyer]: {
    to: RoutePaths.BASKET,
    labelKey: 'basket:verify_my_basket',
  },
  [Business_Profile_Enum.Supplier]: {
    to: RoutePaths.BASKET,
    labelKey: 'basket:verify_my_order_suggestion',
  },
};

const emptyBasketCTAButtonProps: CTAButtonPropMap = {
  [Business_Profile_Enum.Buyer]: {
    to: RoutePaths.SEARCH_PRODUCT,
    labelKey: 'basket:perform_order',
  },
  [Business_Profile_Enum.Supplier]: {
    to: RoutePaths.CONTRACT_LIST,
    labelKey: 'basket:suggest_an_order',
  },
};

export default function TopMenuCTAButton({ sx, onClick }: TopMenuCTAButtonProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'basket', 'products']);
  const { realm } = useAtomValue(currentContextAtom);
  const isRemoteBasketEmpty = useAtomValue(isRemoteBasketEmptyAtom);
  const isBasketLoading = useAtomValue(isBasketLoadingAtom);

  const ctaButtonProps = useMemo(() => {
    return !isRemoteBasketEmpty ? basketCTAButtonProps : emptyBasketCTAButtonProps;
  }, [isRemoteBasketEmpty]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!!ctaButtonProps && (
        <Button
          loading={isBasketLoading}
          variant="tonal"
          component={Link}
          to={ctaButtonProps[realm].to}
          onClick={onClick}
          sx={{
            flexShrink: 0,
            ...sx,
          }}
        >
          {t(ctaButtonProps[realm].labelKey)}
        </Button>
      )}
    </>
  );
}
