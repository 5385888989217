import { TextField, TextFieldProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';

type SearchInputPropsWithoutOnChange = Omit<TextFieldProps, 'onChange' | 'slotProps'>;

interface SearchInputProps extends SearchInputPropsWithoutOnChange {
  onChange: (s: string) => void;
}

export default function SearchInput({ value, onChange, ...props }: SearchInputProps): React.JSX.Element {
  const { t } = useTranslation(['common']);
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onChange(event.target.value);
  };

  return (
    <TextField
      value={value}
      variant="filled"
      placeholder={t('common:search')}
      onChange={handleChange}
      slotProps={{
        input: {
          sx: {
            borderRadius: '100px',
            pl: 1,
          },
          endAdornment: !!value && (
            <CloseIcon
              onClick={() => onChange('')}
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            />
          ),
          startAdornment: (
            <SearchIcon
              sx={{
                padding: 0.5,
                borderRadius: 4,
                color: 'inverseOnSurface.main',
                backgroundColor: 'onSurface.main',
              }}
            />
          ),
        },
      }}
      {...props}
    />
  );
}
