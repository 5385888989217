import { TimePicker, TimePickerProps } from '@mui/x-date-pickers-pro';

export interface DeliveryTimePickerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  timePickerProps: TimePickerProps<any>;
  error?: boolean;
}

export default function DeliveryTimePicker({ timePickerProps, error }: DeliveryTimePickerProps): React.JSX.Element {
  return (
    <TimePicker
      {...timePickerProps}
      slotProps={{
        inputAdornment: {
          position: 'start',
        },
        textField: {
          error,
        },
      }}
      sx={{
        '& .MuiInputBase-input': {
          color: timePickerProps.value ? 'onSurface.main' : 'onSurfaceVariant.main',
        },
        '& .MuiInputAdornment-root': {
          '& .MuiButtonBase-root': {
            ml: 0,
            padding: 0,
          },
          '& .MuiSvgIcon-root': {
            color: 'onSurface.main',
          },
        },
      }}
    />
  );
}
