import { Box, Chip, ChipOwnProps, LinearProgress, SvgIcon, SxProps, Theme, Tooltip } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MinimumOrderValue from '../../assets/icons/minimum_order_value.svg?react';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';

interface MinimumOrderValueChipProps {
  minimumOrderValue: number;
  disableTooltip?: boolean;
  enableProgress?: boolean;
  totalPrice?: number;
  sx?: SxProps;
  color?: ChipOwnProps['color'];
}

interface ChipStatus {
  color?: 'success' | 'error';
  icon: {
    background: (theme: Theme) => string;
    cube?: (theme: Theme) => string;
  }
}

export default function MinimumOrderValueChip({ minimumOrderValue, disableTooltip, enableProgress, totalPrice, sx, color }: MinimumOrderValueChipProps): React.JSX.Element {
  const { t } = useTranslation(['contracts', 'common']);
  const currencyFormatWithFraction = useCurrencyFormat({ minimumFractionDigits: 0, maximumFractionDigits: 2 });
  const currencyFormat = useCurrencyFormat({ maximumFractionDigits: 0 });
  const formattedMoV = currencyFormat.format(minimumOrderValue);

  const progress = useMemo(() => {
    if (!totalPrice) {
      return 0;
    }

    return (totalPrice / minimumOrderValue) * 100;
  }, [totalPrice, minimumOrderValue]);

  const chipStatus = useMemo((): ChipStatus => {
    if (!enableProgress) {
      return {
        icon: {
          background: (theme: Theme) => theme.palette.marketing3.main,
        },
      };
    }

    return progress >= 100
      ? {
        color: 'success',
        icon: {
          background: (theme: Theme) => theme.palette.success.main,
          cube: (theme: Theme) => theme.palette.onSuccess.main,
        },
      }
      : {
        color: 'error',
        icon: {
          background: (theme: Theme) => theme.palette.error.main,
          cube: (theme: Theme) => theme.palette.onError.main,
        },
      };
  }, [enableProgress, progress]);

  const title = useMemo(() => {
    if (enableProgress) {
      return t('search:minimum_order_value_progress', { basketPrice: currencyFormatWithFraction.format(totalPrice!), minimumOrderValue });
    }

    return t('common:minimum_order_value');
  }, [enableProgress, totalPrice]);

  return (
    <Tooltip title={title} disableHoverListener={disableTooltip}>
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '100px',
          ...sx,
        }}
      >
        <Chip
          variant="tag"
          icon={(
            <SvgIcon
              component={MinimumOrderValue}
              sx={{
                '#background': {
                  fill: chipStatus.icon.background,
                },
                '#cube': {
                  fill: chipStatus.icon.cube,
                },
              }}
              inheritViewBox
            />
          )}
          label={formattedMoV}
          color={chipStatus.color || color}
          sx={{
            pr: 1.5,
          }}
        />
        {!!enableProgress && progress < 100 && (
          <LinearProgress
            sx={{
              bgcolor: 'marketing3.main',
              height: '2px',
              position: 'absolute',
              width: '100%',
              bottom: '0px',
            }}
            color="accent"
            variant="determinate"
            value={progress}
          />
        )}
      </Box>
    </Tooltip>
  );
}
