import { Box, InputAdornment, SxProps } from '@mui/material';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import { Business_Profile_Enum, Product, Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import useSortedProductFamiliesMap, { ProductFamily } from '../../hooks/useSortedProductFamiliesMap';
import SearchInput from './SearchInput';
import CheckboxAutocomplete from './CheckboxAutocomplete';
import { currentContextAtom } from '../../state';
import ClearAllButton from './ClearAllButton';

interface ProductFilterToolbarProps {
  products: Pick<Product, 'sub_family'>[];
  initialQuery?: string;
  initialProductFamilies?: Product_Sub_Family_Name_Enum[];
  onChange: (query: string, productFamilies: Product_Sub_Family_Name_Enum[]) => void;
  shouldDisplayClearAllButton?: boolean;
  sx?: SxProps;
}

export default function ProductFilterToolbar({ products, onChange, initialQuery, initialProductFamilies, sx, shouldDisplayClearAllButton = true }: ProductFilterToolbarProps): React.JSX.Element {
  const { t } = useTranslation(['discovery']);
  const { realm } = useAtomValue(currentContextAtom);
  const [query, setQuery] = useState(initialQuery || '');
  const preferredProductFamiliesMap = useSortedProductFamiliesMap(initialProductFamilies || []);
  const [productFamilies, setProductFamilies] = useState<ProductFamily[]>(preferredProductFamiliesMap);

  const productFamiliesOptions = useMemo(() => {
    const options = new Set<Product_Sub_Family_Name_Enum>();

    products.forEach((product) => options.add(product.sub_family as Product_Sub_Family_Name_Enum));

    return [...options.values()];
  }, [products]);
  const productFamiliesMap = useSortedProductFamiliesMap(productFamiliesOptions);

  const handleQueryChange = useCallback((inputQuery: string): void => {
    setQuery(inputQuery);
    onChange(inputQuery, productFamilies.map((productFamily) => productFamily.value));
  }, [onChange, productFamilies]);

  const handleProductFamiliesChange = useCallback((newProductFamilies: ProductFamily[]): void => {
    setProductFamilies(newProductFamilies);
    onChange(query, newProductFamilies.map((productFamily) => productFamily.value));
  }, [onChange, query]);

  const clearAll = (): void => {
    setQuery('');
    setProductFamilies([]);
    onChange('', []);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
        gap: 2,
        ...sx,
      }}
    >
      <SearchInput
        value={query}
        onChange={handleQueryChange}
        sx={{
          width: {
            xs: '100%',
            sm: 420,
          },
        }}
      />
      {realm === Business_Profile_Enum.Buyer && (
        <CheckboxAutocomplete
          options={productFamiliesMap}
          getOptionLabel={(productFamily: ProductFamily) => productFamily.label}
          label={t('discovery:departments')}
          onChange={handleProductFamiliesChange}
          isOptionEqualToValue={(option, { value }) => option.value === value}
          values={productFamilies}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ mr: 0.5 }}>
                <ShoppingBasketOutlinedIcon sx={{ ml: 0.5, fontSize: '1.125rem' }} />
              </InputAdornment>
            ),
          }}
          sx={{
            mb: {
              xs: 1,
              sm: 0,
            },
          }}
        />
      )}
      {
        query.length + productFamilies.length > 0 && shouldDisplayClearAllButton && (
        <ClearAllButton
          onClick={clearAll}
        />
        )
      }
    </Box>
  );
}
