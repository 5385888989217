import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';
import useOpenable from '../../hooks/useOpenable';
import { UserDeliveryZone } from '../../common/models/user.models';
import DeliveryZoneDisplay from './DeliveryZoneDisplay';
import { useDeleteDeliveryZoneMutation } from '../../mutations/__generated__/deleteDeliveryZone.generated';
import { UserRoleDocument } from '../../queries/__generated__/userRole.generated';
import { commonSnackbarPropsAtom } from '../../common/state/state';

export interface DeleteDeliveryZoneDialogProps {
  deliveryZone?: UserDeliveryZone;
  index?: number;
  numberOfZones?: number;
  onClose: () => void;
}

export default function DeleteDeliveryZoneDialog({ deliveryZone, index, numberOfZones, onClose }: DeleteDeliveryZoneDialogProps): React.JSX.Element {
  const { t } = useTranslation(['settings']);
  const { open, close: closeDialog, isOpen } = useOpenable();
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const [deleteZone, { data }] = useDeleteDeliveryZoneMutation(
    {
      refetchQueries: [UserRoleDocument],
    },
  );

  const close = useCallback(() => {
    closeDialog();
    onClose();
  }, []);

  useEffect(() => {
    if (deliveryZone) {
      open();
    }
  }, [deliveryZone]);

  useEffect(() => {
    if (data?.delete_delivery_zone_by_pk) {
      setCommonSnackbarProps({
        label: t('settings:your_profile_has_been_updated'),
        snackbarProps: {
          open: true,
        },
      });

      close();
    }
  }, [data]);

  if (!deliveryZone || index === undefined || !numberOfZones) {
    return <></>;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={close}
    >
      <DialogTitle>
        <Typography>{t('settings:delete_delivery_zone')}</Typography>
        <Typography>
          {t('settings:delete_delivery_zone_description')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DeliveryZoneDisplay
          key={deliveryZone.id}
          deliveryZone={deliveryZone}
          index={index}
          zoneListLength={numberOfZones}
          readOnly
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close} variant="outlined">
          {t('common:cancel')}
        </Button>
        <Button
          onClick={() => deleteZone({ variables: { id: deliveryZone.id } })}
          variant="contained"
        >
          {t('common:delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
