import { FilledInput, FormHelperText } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProductPackagingsFormData } from '../../ProductFormPackagings';

interface UnitNetContentInputProps {
  name: `productPackagings.${number}.csu.unit_net_content`
    | `productPackagings.${number}.packagings.${number}.unit_quantity`
    | `productPackagings.${number}.packagings.${number}.net_content`;
}

export default function UnitNetContentInput({ name }: UnitNetContentInputProps): React.JSX.Element {
  const { t } = useTranslation(['products', 'validation']);
  const { control } = useFormContext<ProductPackagingsFormData>();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: t('validation:this_field_is_required') as string,
        validate: {
          min: (value) => !!value && (value > 0 || (t('validation:this_value_should_be_higher_than_N', { value: 0 }) as string)),
        },
      }}
      render={({ field, fieldState }) => (
        <>
          <FilledInput
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            error={!!fieldState.error}
            fullWidth
            inputProps={{
              min: '1',
            }}
            type="number"
            placeholder={t('products:net_content_placeholder')}
          />
          {fieldState.error
            && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
        </>
      )}
    />
  );
}
