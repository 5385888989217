import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, FormControlLabel, Switch, Typography } from '@mui/material';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRefinementList } from 'react-instantsearch';
import { Link } from 'react-router';
import { useAtomValue } from 'jotai';
import { Business_Profile_Enum, Contract_Status_Enum } from 'kheops-graphql';
import { RoutePaths } from '../../routes/AppRoutes';
import useDiscoveryDefaultFilters, { SearchMode } from '../../hooks/useDiscoveryDefaultFilters';
import useRefine from '../../hooks/useRefine';
import useNormalizedCurrentRefinements from '../../hooks/useNormalizedCurrentRefinements';
import { DiscoveryWordingsKeys } from '../discovery.utils';
import { CompanyPageSearchParams } from '../../company/delivery_zones/CompanyDeliveryZones';
import { currentContextAtom } from '../../state';
import FilterUtils from './filter.utils';
import FilterDisplay, { FilterDisplayMode } from './FilterDisplay';

type ContractStatusFilterValue = Contract_Status_Enum | 'all';

interface ContractStatusFilterProps {
  attribute: string;
  mode?: FilterDisplayMode;
  searchMode?: SearchMode;
}

export default function ContractStatusFilter({ attribute, mode, searchMode }: ContractStatusFilterProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'discovery']);
  const { companyId, realm, companyAddress, deliveryZones } = useAtomValue(currentContextAtom);
  const { refine: refineBuyerDiscoveryFilter } = useRefinementList({ limit: 1000, attribute: 'administrative_areas' });
  const currentAreas = useNormalizedCurrentRefinements('administrative_areas') as string[];
  const { refine, uiState } = useRefine();
  const defaultFilters = useDiscoveryDefaultFilters(searchMode);
  const signedContractFilter = FilterUtils.GetSignedContractFilter(attribute, companyId);
  const deliveryZoneFilter = FilterUtils.GetSupplierCanDeliverFilter(companyAddress.administrativeAreaLevel2!);
  const [contractStatusValue, setContractStatusValue] = useState<ContractStatusFilterValue>(Contract_Status_Enum.Signed);
  const [filterDeliveryZones, setFilterDeliveryZones] = useState<boolean>(false);

  const disableDeliveryZoneFilter = realm === Business_Profile_Enum.Supplier && !deliveryZones?.length;
  const hasDeliveryZoneForAllAreas = !!deliveryZones?.some(({ administrative_areas }) => administrative_areas.some(({ area }) => area === 'ALL_AREAS'));
  const supplierDeliveryFilters = useMemo(() => {
    return deliveryZones!.reduce<string[]>(
      (acc, { administrative_areas }) => {
        const areas = administrative_areas.map(({ area }) => `administrative_area_level_2|${area}`);

        return acc.concat(areas);
      },
      [],
    );
  }, [deliveryZones]);

  const defaultFiltersList = useMemo(() => {
    return defaultFilters ? [defaultFilters] : [];
  }, [defaultFilters]);

  const activeItemCount = useMemo((): number => {
    let count = 0;

    if (uiState.filters !== undefined && !uiState.filters.includes(signedContractFilter)) {
      count++;
    }

    if (filterDeliveryZones) {
      count++;
    }

    return count;
  }, [uiState, filterDeliveryZones]);

  const handleContractStatusChange = useCallback((event: SyntheticEvent<Element, Event>, checked: boolean): void => {
    const filtersList = [...defaultFiltersList];

    if (checked) {
      filtersList.push(signedContractFilter);
    }

    if (filterDeliveryZones && realm === Business_Profile_Enum.Buyer) {
      filtersList.push(deliveryZoneFilter);
    }

    const filters = filtersList.join(' AND ');

    setContractStatusValue(checked ? Contract_Status_Enum.Signed : 'all');
    refine({ filters });
  }, [defaultFiltersList, refine, filterDeliveryZones]);

  const handleFilterDeliveryZonesChange = useCallback((event: SyntheticEvent<Element, Event>, checked: boolean): void => {
    if (realm === Business_Profile_Enum.Supplier) {
      const filters = supplierDeliveryFilters.filter((area) => (checked ? !currentAreas.find((item) => item === area)?.length : true));

      if (checked) {
        filters.push(...currentAreas.filter((area) => !supplierDeliveryFilters.includes(area)));
      }

      filters.forEach((filter) => {
        refineBuyerDiscoveryFilter(filter);
      });

      setFilterDeliveryZones(checked);

      return;
    }

    const filtersList = [...defaultFiltersList];

    if (contractStatusValue === Contract_Status_Enum.Signed) {
      filtersList.push(signedContractFilter);
    }

    if (checked) {
      filtersList.push(deliveryZoneFilter);
    }

    const filters = filtersList.join(' AND ');

    setFilterDeliveryZones(checked);
    refine({ filters });
  }, [defaultFiltersList, refine, deliveryZoneFilter, contractStatusValue, currentAreas]);

  useEffect(() => {
    const contractStatus = uiState.filters?.includes(signedContractFilter)
      ? Contract_Status_Enum.Signed
      : 'all';

    setContractStatusValue(contractStatus);

    if (realm === Business_Profile_Enum.Buyer) {
      const hasFilterDeliveryZones = !!uiState.filters?.includes(deliveryZoneFilter);

      setFilterDeliveryZones(hasFilterDeliveryZones);
    } else if (supplierDeliveryFilters.length) {
      const hasFilterDeliveryZones = supplierDeliveryFilters.length === currentAreas.length
        && supplierDeliveryFilters.every((area) => currentAreas.includes(area));

      setFilterDeliveryZones(hasFilterDeliveryZones);
    }
  }, [uiState, currentAreas]);

  return (
    <FilterDisplay
      icon={<StorefrontOutlinedIcon />}
      activeItemCount={activeItemCount}
      label={t(DiscoveryWordingsKeys[realm].contract_status_label)}
      mode={mode}
      name="company_status"
      content={(
        <>
          <Typography variant="bodyMedium" sx={{ mb: 1 }}>
            {t('discovery:contractualization')}
          </Typography>
          <FormControlLabel
            checked={contractStatusValue === Contract_Status_Enum.Signed}
            onChange={handleContractStatusChange}
            label={t(DiscoveryWordingsKeys[realm].contract_status_contractualized_option)}
            control={<Switch />}
            sx={{
              mx: 0,
              pt: 2,
            }}
          />
          {(realm === Business_Profile_Enum.Buyer || !hasDeliveryZoneForAllAreas) && (
            <>
              <Typography
                variant="bodyMedium"
                sx={{
                  mt: 4,
                  mb: 1,
                }}
              >
                {t('common:delivery')}
              </Typography>
              <FormControlLabel
                checked={filterDeliveryZones}
                onChange={handleFilterDeliveryZonesChange}
                disabled={disableDeliveryZoneFilter}
                label={t(DiscoveryWordingsKeys[realm].delivery_zone_option)}
                control={<Switch />}
                sx={{
                  mx: 0,
                  py: 2,
                }}
              />
              {disableDeliveryZoneFilter && (
                <Box
                  sx={{
                    p: 2,
                    borderRadius: 4,
                    border: '1px solid',
                    borderColor: 'divider',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ display: 'flex' }}>
                    <InfoIcon />
                    <Typography variant="bodyMedium" sx={{ ml: 1 }}>
                      {t('discovery:define_delivery_zone_to_use_filter')}
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    component={Link}
                    to={`${RoutePaths.COMPANY_DELIVERY_ZONES}?${CompanyPageSearchParams.OpenDialog}=true`}
                    sx={{ mt: 1.5 }}
                  >
                    {t('discovery:fill_out_my_delivery_zones')}
                  </Button>
                </Box>
              )}
            </>
          )}
        </>
      )}
    />
  );
}
