import i18next, { TFunction } from 'i18next';
import { Business_Profile_Enum, Trade_Item_Unit_Descriptor_Enum } from 'kheops-graphql';
import { TradeItemUnitDescriptorIntlMap, round } from 'kheops-utils';

export default class Utils {
  // Copied from https://blog.webdevsimplified.com/2020-07/relative-time-format/
  static formatTimeAgo(date: Date, options?: Intl.RelativeTimeFormatOptions): string {
    const formatter = new Intl.RelativeTimeFormat(i18next.language, {
      numeric: 'auto',
      ...options,
    });
    const DIVISIONS: { amount: number, name: Intl.RelativeTimeFormatUnit }[] = [
      { amount: 60, name: 'seconds' },
      { amount: 60, name: 'minutes' },
      { amount: 24, name: 'hours' },
      { amount: 7, name: 'days' },
      { amount: 4.34524, name: 'weeks' },
      { amount: 12, name: 'months' },
      { amount: Number.POSITIVE_INFINITY, name: 'years' },
    ];

    let duration = (date.getTime() - new Date().getTime()) / 1000;
    let result = '';

    for (let i = 0; i <= DIVISIONS.length; i++) {
      const division = DIVISIONS[i];

      if (Math.abs(duration) < division.amount) {
        result = formatter.format(Math.round(duration), division.name);
        break;
      }
      duration /= division.amount;
    }

    return result;
  }
}

// Function based on Haversine formula. Copied from: https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
export function computeDistanceInKm(lat1: number, lon1: number, lat2: number, lon2: number): number {
  // function to convert angle's value from degrees to radians.
  function deg2rad(deg: number): number {
    return deg * (Math.PI / 180);
  }

  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2))
    * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
}

export function getCompanyShortName(name: string, realm: Business_Profile_Enum): string {
  if (realm === Business_Profile_Enum.Supplier) {
    return name;
  }

  const [, shortName] = name.split(' - ');

  return shortName || name;
}

export function getArraysIntersection<T>(listOfListToSearch: Array<Array<T>>): Array<T> {
  return listOfListToSearch.reduce(
    (prevArray, currArray) => prevArray.filter((element) => currArray.includes(element)),
  );
}

/**
 * This function returns whether a string is included in another.
 * It is not case or accent sensitive.
 */
export function insensitiveStringIncludes(stringToSearch: string, stringToFind: string): boolean {
  const formattedStringToSearch = stringToSearch.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase();
  const formattedStringToFind = stringToFind.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase();

  return formattedStringToSearch.includes(formattedStringToFind);
}

export function insensitiveStringCompare(stringA: string, stringB: string): boolean {
  const formattedStringA = stringA.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase();
  const formattedStringB = stringB.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase();

  return formattedStringA === formattedStringB;
}

export function isRichTextEmpty(richText: string): boolean {
  return richText === '' || richText.replaceAll(/(<.*?>)/g, '') === '';
}

export function areArraysEqual<T>(a: T[], b: T[]): boolean {
  return a === b || (a.length === b.length && a.every((value) => b.includes(value)));
}

export function areObjectsShallowEqual<T extends Record<string, unknown>>(a: T, b: T): boolean {
  return a === b || (
    areArraysEqual(Object.keys(a), Object.keys(b))
    && Object.entries(a).every(([key, value]) => Object.hasOwn(b, key) && b[key] === value)
  );
}

export function getBaseUnitExcludedTaxMargin(suggestedRetailPrice: number, baseUnitPrice: number, vatRate: number): number {
  const excludedTaxPrice = suggestedRetailPrice / (1 + vatRate);
  const rawMargin = excludedTaxPrice - baseUnitPrice;

  return round((rawMargin / baseUnitPrice) * 100, 0);
}

export function formatPackagingPriceIncludingDescriptor(t: TFunction, price: string, descriptor: Trade_Item_Unit_Descriptor_Enum): string {
  const unit = descriptor === Trade_Item_Unit_Descriptor_Enum.Unit
    ? t('packaging:unit_one')
    : t(`packaging:${TradeItemUnitDescriptorIntlMap[descriptor]}`);

  return `${price}/${unit.toLowerCase()}`;
}

export function arrayToggle<T>(array: T[], value: T): void {
  const index = array.indexOf(value);

  if (index === -1) {
    array.push(value);
  } else {
    array.splice(index, 1);
  }
}

export function getGMapUrlForAddress(address: string): string {
  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
}
