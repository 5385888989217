import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useAtomValue } from 'jotai';
import { Link } from 'react-router';
import TopBar from '../common/components/TopBar';
import BackButton from '../common/components/BackButton';
import { RoutePaths } from '../routes/AppRoutes';
import { PageWrapper } from '../common/components/PageWrapper';
import { currentContextAtom } from '../state';
import CompanyProductSearch from './CompanyProductSearch';
import MyCompanyInfoCard from './MyCompanyInfoCard';

export default function MyCompanyPage(): React.JSX.Element {
  const { t } = useTranslation(['common']);
  const { companyId } = useAtomValue(currentContextAtom);

  return (
    <>
      <TopBar
        sx={{
          display: {
            xs: 'flex',
            lg: 'none',
          },
          px: {
            xs: 2,
            sm: 4,
          },
        }}
      >
        <BackButton
          to={RoutePaths.COMPANY_PROFILE}
          shouldCheckHistory={false}
          compact
        />
        <Typography variant="titleLarge" sx={{ ml: 1 }}>
          {t('settings:company')}
        </Typography>
      </TopBar>
      <PageWrapper sx={{ mt: 9 }}>
        <MyCompanyInfoCard />
        <Box
          sx={{
            p: { xs: 0, lg: 2 },
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 1,
              alignItems: { xs: 'start', lg: 'center' },
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
            }}
          >
            <Typography variant="titleLarge">
              {t('common:product_other')}
            </Typography>
            <Button
              variant="tonal"
              component={Link}
              to={RoutePaths.COMPANY_PRODUCTS}
              startIcon={<EditIcon />}
            >
              {t('settings:update_my_products')}
            </Button>
          </Box>

          <Box>
            <CompanyProductSearch contextCompanyId={companyId} companyId={companyId} />
          </Box>
        </Box>
      </PageWrapper>
    </>
  );
}
