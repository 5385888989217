import { Avatar, Box, Typography } from '@mui/material';
import { CompanyRoleAssignment } from '../../../common/models/user.models';
import PhotosUtils from '../../../common/utils/photos.utils';

export interface ContactsProps {
  contacts: CompanyRoleAssignment[];
}

export default function ContactList({ contacts }: ContactsProps): React.JSX.Element {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
      {contacts.map(({ user: { first_name, last_name, auth_provider_avatar_url, default_avatar } }) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Avatar src={PhotosUtils.getUserAvatarPicture({ auth_provider_avatar_url, default_avatar })} size={40} />
          <Typography variant="bodyMedium">
            {first_name} {last_name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
