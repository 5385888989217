import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrdersDeliveryDates } from '../../basket/state/state';
import SizedImage from '../../common/components/SizedImage';
import { BasketOrdersDict } from '../../hooks/useBasketOrders';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';
import DeliveryDatePicker from '../../common/components/DeliveryDatePicker';

interface OrderSuggestionsDeliveryDatesDialogProps {
  orders: BasketOrdersDict;
  deliveryDates: OrdersDeliveryDates;
  open: boolean;
  onClose: (deliveryDates?: OrdersDeliveryDates) => void;
}

export default function OrderSuggestionsDeliveryDatesDialog({ orders, deliveryDates, open, onClose }: OrderSuggestionsDeliveryDatesDialogProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'discovery', 'order']);
  const currencyFormat = useCurrencyFormat();
  const [dialogDeliverayDates, setDialogDeliveryDates] = useState(deliveryDates);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const ordersCount = useMemo((): number => Object.keys(orders).length, [orders]);

  const handleDateChange = useCallback((contractId: string, date: Date | null): void => {
    if (!date && dialogDeliverayDates.has(contractId)) {
      dialogDeliverayDates.delete(contractId);
    } else {
      const newOrdersDeliveryDates = new Map(dialogDeliverayDates);

      setDialogDeliveryDates(newOrdersDeliveryDates.set(contractId, date));
    }
  }, [dialogDeliverayDates]);

  const handleCancel = useCallback((): void => {
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback((): void => {
    if (dialogDeliverayDates.size !== Object.keys(orders).length) {
      setShowErrorMessage(true);
    } else {
      onClose(dialogDeliverayDates);
    }
  }, [onClose, dialogDeliverayDates, orders]);

  useEffect(() => {
    setDialogDeliveryDates(new Map(deliveryDates));
  }, [deliveryDates]);

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      sx={{
        mx: {
          xs: 2,
          sm: 0,
        },
        '& .MuiPaper-root': {
          minWidth: {
            xs: '100%',
            sm: 600,
            md: 724,
            lg: 800,
          },
        },
      }}
    >
      <DialogTitle>
        <Typography>
          {t('basket:validate_suggestions', { count: ordersCount })}
        </Typography>
        <Typography>
          {t('basket:validate_suggestions_description', { count: ordersCount })}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            '& .MuiDivider-root:last-child': {
              display: 'none',
            },
          }}
        >
          {Object.keys(orders).sort((a, b) => orders[a].name.localeCompare(orders[b].name)).map((contractId) => {
            const { items, photoUrl, name, companyId, totalPrice } = orders[contractId];
            const productTotals = t('discovery:N_product', { count: items.length, formattedCount: items.length });
            const formattedTotalPrice = currencyFormat.format(totalPrice);

            return (
              <>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                    <SizedImage
                      src={photoUrl}
                      alt={t('common:company')}
                      height={56}
                      width={56}
                      borderRadius={3}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="bodyMedium">
                        {name}
                      </Typography>
                      <Typography variant="bodySmall">
                        {productTotals} - {t('common:total')}: {formattedTotalPrice}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="bodyMedium" sx={{ mb: 1 }}>
                    {t('order:delivery_date')}
                  </Typography>
                  <DeliveryDatePicker
                    companyId={companyId}
                    value={dialogDeliverayDates.get(contractId)}
                    onChange={(date) => handleDateChange(contractId, date)}
                  />
                </Box>
                <Divider />
              </>
            );
          })}
        </Box>
        {showErrorMessage && (
          <Typography variant="bodyMedium" color="error" sx={{ mt: 1 }}>
            {t('basket:delivery_dates_should_be_defined', { count: ordersCount })}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant="text">
          {t('common:cancel')}
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          {t('common:validate')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
