import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useAtomValue } from 'jotai';
import { Order_Status_Enum, TaskAction } from 'kheops-graphql';
import useOrderActions from '../../hooks/useOrderActions';
import OrderDeliveryDateDialog, { DeliveryDateOrderStatus } from './OrderDeliveryDateDialog';
import { isPerformingActionAtom } from '../state/state';

type Mode = 'edit' | 'insert';
interface OrderDeliveryDateButtonProps {
  orderReferenceId: string;
  orderId: string;
  orderStatus: DeliveryDateOrderStatus;
  mode: Mode;
  useEditIcon?: boolean;
}

export default function OrderDeliveryDateButton({ orderReferenceId, orderId, orderStatus, mode, useEditIcon }: OrderDeliveryDateButtonProps): React.JSX.Element {
  const { t } = useTranslation(['order']);
  const [isDeliveryDateDialogOpen, setIsDeliveryDateDialogOpen] = useState(false);

  const { updateOrder, isLoading } = useOrderActions({ orderReferenceId, orderId });
  const isPerformingAction = useAtomValue(isPerformingActionAtom);

  const handleDeliveryDialog = (isConfirmed: boolean): void => {
    if (isConfirmed) {
      updateOrder(orderStatus === Order_Status_Enum.OrderToBeValidatedBySupplier ? TaskAction.Approve : undefined);
    }
    setIsDeliveryDateDialogOpen(false);
  };

  const buttonComponent = useMemo(() => {
    if (mode === 'insert') {
      return (
        <Button
          startIcon={<AddIcon />}
          onClick={() => setIsDeliveryDateDialogOpen(true)}
        >
          {t('order:add_delivery_date')}
        </Button>
      );
    }

    if (useEditIcon) {
      return (
        <Button variant="text" onClick={() => setIsDeliveryDateDialogOpen(true)}>
          <EditOutlinedIcon />
        </Button>
      );
    }
    return (
      <Button
        variant="outlined"
        onClick={() => setIsDeliveryDateDialogOpen(true)}
        startIcon={<EditOutlinedIcon />}
        loading={isLoading || isPerformingAction}
        sx={{ flex: 'auto' }}
      >
        {t('order:update_delivery_date')}
      </Button>
    );
  }, [useEditIcon, orderStatus, isLoading, isPerformingAction, mode]);

  return (
    <>
      {buttonComponent}
      <OrderDeliveryDateDialog
        open={isDeliveryDateDialogOpen}
        orderStatus={orderStatus as DeliveryDateOrderStatus}
        onClose={(date) => handleDeliveryDialog(!!date)}
        actionType={mode === 'edit' ? TaskAction.Modify : TaskAction.Approve}
      />
    </>
  );
}
