import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetAtom } from 'jotai';
import { round } from 'kheops-utils';
import { GlobalDiscountInfo } from './GlobalDiscountList';
import i18n from '../../i18n';
import { ContractsAsSupplierDocument } from '../../queries/__generated__/contractsAsSupplier.generated';
import { useUpdateContractsMutation } from '../../mutations/__generated__/updateContracts.generated';
import ConfirmDialog from '../../common/components/ConfirmDialog';
import { commonSnackbarPropsAtom } from '../../common/state/state';
import useOpenable from '../../hooks/useOpenable';
import GlobalDiscountFormDialog, { GlobalDiscountFormDialogResponse } from './GlobalDiscountFormDialog';
import { CustomPriceListsByCompanyIdDocument } from '../../queries/__generated__/customPriceListsByCompanyId.generated';
import PropertyCard from '../../common/components/PropertyCard';
import StoresChip from '../StoresChip';

export interface GlobalDiscountRowProps {
  discount: number;
  globalDiscountInfo: GlobalDiscountInfo[];
}

export default function GlobalDiscountRow({ discount, globalDiscountInfo }: GlobalDiscountRowProps): React.JSX.Element {
  const { t } = useTranslation(['catalogs', 'price']);
  const percentFormatter = new Intl.NumberFormat(i18n.resolvedLanguage, { style: 'percent', maximumFractionDigits: 2 });
  const { open: openDeleteConfirm, close: closeDeleteConfirm, isOpen: isDeleteConfirmOpen } = useOpenable();
  const { open: openUpdateDialog, close: closeUpdateDialog, isOpen: isUpdateDialogOpen } = useOpenable();
  const [updateContracts, { data }] = useUpdateContractsMutation({ refetchQueries: [CustomPriceListsByCompanyIdDocument, ContractsAsSupplierDocument] });
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const [performedAction, setPerformedAction] = useState<'UPDATE' | 'DELETE' | undefined>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleDeleteConfirm = (confirm: boolean): void => {
    if (confirm) {
      setPerformedAction('DELETE');
      updateContracts({
        variables: {
          contractIds: globalDiscountInfo.map(({ contractId }) => contractId),
          contractInput: {
            discount: 0,
          },
        },
      });
    }

    closeDeleteConfirm();
  };

  const handleUpdateDialogClose = (response?: GlobalDiscountFormDialogResponse): void => {
    closeUpdateDialog();

    if (!response) {
      return;
    }

    const deletedContractIds = globalDiscountInfo
      .map(({ contractId }) => contractId)
      .filter((contractId) => !response.contractIds.includes(contractId));

    setPerformedAction('UPDATE');
    updateContracts({
      variables: {
        contractIds: response.contractIds,
        contractInput: {
          discount: response.discount / 100,
          custom_price_list_id: null,
        },
      },
    });
    updateContracts({
      variables: {
        contractIds: deletedContractIds,
        contractInput: {
          discount: 0,
        },
      },
    });
  };

  useEffect((): void => {
    if (data?.update_contract_many?.length) {
      setCommonSnackbarProps({
        label: t(performedAction === 'DELETE' ? 'price:global_discount_deleted' : 'price:global_discount_edited'),
        snackbarProps: {
          open: true,
        },
      });
    }
  }, [data]);

  const handleOptionsButtonClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  return (
    <>
      <PropertyCard
        title={percentFormatter.format(discount)}
        subTitles={[
          <StoresChip
            storesList={globalDiscountInfo}
          />,
        ]}
        actionButton={(
          <Button
            variant="text"
            onClick={handleOptionsButtonClick}
            sx={{ mt: -0.5 }}
          >
            <MoreVertIcon />
          </Button>
          )}
      />
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setAnchorEl(null)}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: 4,
            gap: 2,
          },
          '& .MuiMenuItem-root': {
            py: 1,
          },
          '& .MuiListItemIcon-root': {
            minWidth: 26,
          },
          '& .MuiSvgIcon-root': {
            fontSize: '1.125rem',
            color: 'black',
          },
        }}
      >
        <MenuItem onClick={openUpdateDialog}>
          <ListItemIcon>
            <EditOutlinedIcon />
          </ListItemIcon>
          <ListItemText>
            {t('common:modify')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={openDeleteConfirm}>
          <ListItemIcon>
            <DeleteOutlinedIcon />
          </ListItemIcon>
          <ListItemText>
            {t('common:delete')}
          </ListItemText>
        </MenuItem>
      </Menu>
      {isDeleteConfirmOpen && (
        <ConfirmDialog
          open
          onClose={handleDeleteConfirm}
          cancelLabel={t('common:cancel')}
          confirmLabel={t('common:delete')}
          confirmIcon={<DeleteOutlinedIcon />}
          title={t('price:delete_the_global_discount')}
          subTitle={t('price:confirm_global_discount_delete')}
        >
          <Box
            sx={{
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 4,
              p: 2,
            }}
          >
            <Typography variant="titleMedium">
              {percentFormatter.format(discount)}
            </Typography>
            <Typography variant="bodySmall" color="secondary">
              {globalDiscountInfo.map(({ tradeName }) => tradeName).join(', ')}
            </Typography>
          </Box>
        </ConfirmDialog>
      )}
      {isUpdateDialogOpen && (
        <GlobalDiscountFormDialog
          open
          onClose={handleUpdateDialogClose}
          initialDiscount={round(discount * 100)}
          initialSelectedContracts={globalDiscountInfo.map(({ contractId }) => contractId)}
        />
      )}
    </>
  );
}
