import { Box, Tooltip, TooltipProps, Typography } from '@mui/material';

interface RichTooltipProps extends Omit<TooltipProps, 'title' | 'variant'> {
  subHeadText?: string;
  supportingText?: string;
  primaryAction?: React.ReactNode;
  secondaryAction?: React.ReactNode;
}

export default function RichTooltip({ subHeadText, supportingText, primaryAction, secondaryAction, ...defaultTooltipProps }: RichTooltipProps): React.JSX.Element {
  return (
    <Tooltip
      {...defaultTooltipProps}
      variant="rich"
      title={(
        <Box sx={{ pt: 1.5, px: 2, pb: 1 }}>
          <Typography variant="titleSmall" sx={{ color: 'onSurfaceVariant.main' }}>{subHeadText}</Typography>
          <Typography variant="bodyMedium" sx={{ color: 'onSurfaceVariant.main', mt: 0.5 }}>{supportingText}</Typography>
          <Box sx={{ display: 'flex ', gap: 1, pt: 1 }}>
            {primaryAction}
            {secondaryAction}
          </Box>
        </Box>
      )}
    />
  );
}
