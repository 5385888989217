import { FormHelperText, Grid2, FilledInput, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DraftProduct } from '../state';

export default function NutritionFactsForm(): React.JSX.Element {
  const { control } = useFormContext<DraftProduct>();
  const { t } = useTranslation(['products', 'validation']);

  return (
    <Grid2
      size={{ xs: 12 }}
      container
      rowSpacing={2}
      sx={{
        '& > .MuiGrid-container .MuiFilledInput-root': {
          width: '100%',
        },
      }}
    >
      <Grid2
        size={{ xs: 12 }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="titleMedium">
          {t('products:nutritional_values_per_100g_100ml')}
        </Typography>
        <Typography variant="bodySmall">
          {t('products:nutritional_values_description')}
        </Typography>
      </Grid2>
      <Grid2 container size={{ xs: 12 }} spacing={2}>
        <Grid2 size={{ xs: 6, sm: 3 }}>
          <Typography variant="bodySmall" sx={{ mb: 0.5 }}>
            {t('products:calories_kcal')}
          </Typography>
          <Controller
            name="calories"
            control={control}
            rules={{
              min: { value: 0, message: t('validation:this_value_should_be_higher_than_or_equals_to_N', { value: 0 }) },
            }}
            render={({ field, fieldState }) => (
              <>
                <FilledInput
                  size="small"
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  error={!!fieldState.error}
                  inputProps={{
                    step: '.01',
                    min: '0',
                  }}
                  type="number"
                  placeholder={t('products:nutritional_values_placeholder')}
                  endAdornment="kcal"
                />
                {fieldState.error
                  && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
              </>
            )}
          />
        </Grid2>
        <Grid2 size={{ xs: 6, sm: 3 }}>
          <Typography variant="bodySmall" sx={{ mb: 0.5 }}>
            {t('products:calories_kj')}
          </Typography>
          <Controller
            name="calories_kj"
            control={control}
            rules={{
              min: { value: 0, message: t('validation:this_value_should_be_higher_than_or_equals_to_N', { value: 0 }) },
            }}
            render={({ field, fieldState }) => (
              <>
                <FilledInput
                  size="small"
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  error={!!fieldState.error}
                  inputProps={{
                    step: '.01',
                    min: '0',
                  }}
                  type="number"
                  placeholder={t('products:nutritional_values_placeholder')}
                  endAdornment="kj"
                />
                {fieldState.error
                  && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
              </>
            )}
          />
        </Grid2>
        <Grid2 size={{ xs: 6, sm: 3 }}>
          <Typography variant="bodySmall" sx={{ mb: 0.5 }}>
            {t('products:fat')}
          </Typography>
          <Controller
            name="fat"
            control={control}
            rules={{
              min: { value: 0, message: t('validation:this_value_should_be_higher_than_or_equals_to_N', { value: 0 }) },
            }}
            render={({ field, fieldState }) => (
              <>
                <FilledInput
                  size="small"
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  error={!!fieldState.error}
                  inputProps={{
                    step: '.01',
                    min: '0',
                  }}
                  type="number"
                  placeholder={t('products:nutritional_values_placeholder')}
                  endAdornment="g"
                />
                {fieldState.error
                  && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
              </>
            )}
          />
        </Grid2>
        <Grid2 size={{ xs: 6, sm: 3 }}>
          <Typography variant="bodySmall" sx={{ mb: 0.5, whiteSpace: 'nowrap' }}>
            {t('products:saturated_fat')}
          </Typography>
          <Controller
            name="saturated_fat"
            control={control}
            rules={{
              min: { value: 0, message: t('validation:this_value_should_be_higher_than_or_equals_to_N', { value: 0 }) },
            }}
            render={({ field, fieldState }) => (
              <>
                <FilledInput
                  size="small"
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  error={!!fieldState.error}
                  inputProps={{
                    step: '.01',
                    min: '0',
                  }}
                  type="number"
                  placeholder={t('products:nutritional_values_placeholder')}
                  endAdornment="g"
                />
                {fieldState.error
                  && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
              </>
            )}
          />
        </Grid2>
        <Grid2 size={{ xs: 6, sm: 3 }}>
          <Typography variant="bodySmall" sx={{ mb: 0.5 }}>
            {t('products:carbohydrate')}
          </Typography>
          <Controller
            name="carbohydrate"
            control={control}
            rules={{
              min: { value: 0, message: t('validation:this_value_should_be_higher_than_or_equals_to_N', { value: 0 }) },
            }}
            render={({ field, fieldState }) => (
              <>
                <FilledInput
                  size="small"
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  error={!!fieldState.error}
                  inputProps={{
                    step: '.01',
                    min: '0',
                  }}
                  type="number"
                  placeholder={t('products:nutritional_values_placeholder')}
                  endAdornment="g"
                />
                {fieldState.error
                  && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
              </>
            )}
          />
        </Grid2>
        <Grid2 size={{ xs: 6, sm: 3 }}>
          <Typography variant="bodySmall" sx={{ mb: 0.5 }}>
            {t('products:sugars')}
          </Typography>
          <Controller
            name="sugars"
            control={control}
            rules={{
              min: { value: 0, message: t('validation:this_value_should_be_higher_than_or_equals_to_N', { value: 0 }) },
            }}
            render={({ field, fieldState }) => (
              <>
                <FilledInput
                  size="small"
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  error={!!fieldState.error}
                  inputProps={{
                    step: '.01',
                    min: '0',
                  }}
                  type="number"
                  placeholder={t('products:nutritional_values_placeholder')}
                  endAdornment="g"
                />
                {fieldState.error
                  && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
              </>
            )}
          />
        </Grid2>
        <Grid2 size={{ xs: 6, sm: 3 }}>
          <Typography variant="bodySmall" sx={{ mb: 0.5 }}>
            {t('products:sodium')}
          </Typography>
          <Controller
            name="sodium"
            control={control}
            rules={{
              min: { value: 0, message: t('validation:this_value_should_be_higher_than_or_equals_to_N', { value: 0 }) },
            }}
            render={({ field, fieldState }) => (
              <>
                <FilledInput
                  size="small"
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  error={!!fieldState.error}
                  inputProps={{
                    step: '.01',
                    min: '0',
                  }}
                  type="number"
                  placeholder={t('products:nutritional_values_placeholder')}
                  endAdornment="g"
                />
                {fieldState.error
                  && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
              </>
            )}
          />
        </Grid2>
        <Grid2 size={{ xs: 6, sm: 3 }}>
          <Typography variant="bodySmall" sx={{ mb: 0.5 }}>
            {t('products:protein')}
          </Typography>
          <Controller
            name="protein"
            control={control}
            rules={{
              min: { value: 0, message: t('validation:this_value_should_be_higher_than_or_equals_to_N', { value: 0 }) },
            }}
            render={({ field, fieldState }) => (
              <>
                <FilledInput
                  size="small"
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  error={!!fieldState.error}
                  inputProps={{
                    step: '.01',
                    min: '0',
                  }}
                  type="number"
                  placeholder={t('products:nutritional_values_placeholder')}
                  endAdornment="g"
                />
                {fieldState.error
                  && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
              </>
            )}
          />
        </Grid2>
      </Grid2>
    </Grid2>
  );
}
