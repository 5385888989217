import { Slider } from '@mui/material';
import { SyntheticEvent } from 'react';
import { Controller } from 'react-hook-form';
import useRefine from '../../hooks/useRefine';

export default function GeoLocationRadiusRange(): React.JSX.Element {
  const { refine } = useRefine();

  const handleRadiusChange = (newValue: number): void => {
    const aroundRadius = newValue * 1000;

    refine({ aroundRadius });
  };

  return (
    <Controller
      name="range"
      render={({ field }) => (
        <Slider
          min={0}
          max={200}
          marks={[
            { value: 0, label: '0km' },
            { value: 200, label: '200km' },
          ]}
          sx={{
            mt: 3,
            mb: 4.75,
          }}
          valueLabelDisplay="on"
          valueLabelFormat={(value) => (`${value}km`)}
          value={field.value}
          onChange={(event: Event) => {
            field.onChange(event);
          }}
          onChangeCommitted={(event: Event | SyntheticEvent<Element, Event>, newValue: number | number[]) => {
            handleRadiusChange(newValue as number);
          }}
        />
      )}
    />
  );
}
