import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, OutlinedInput, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useAtomValue } from 'jotai';
import { signedContractsAtom } from '../../state';
import ContractsSelect from '../../common/components/ContractsSelect';

export interface GlobalDiscountFormDialogResponse {
  discount: number;
  contractIds: string[];
}

export interface GlobalDiscountFormDialogProps {
  open: boolean;
  onClose: (response?: GlobalDiscountFormDialogResponse) => void;
  initialDiscount?: number;
  initialSelectedContracts?: string[];
}

export default function GlobalDiscountFormDialog({ open, onClose, initialDiscount, initialSelectedContracts }: GlobalDiscountFormDialogProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'price']);
  const [discount, setDiscount] = useState<number | undefined>(initialDiscount);
  const [selectedContracts, setSelectedContracts] = useState<string[]>(initialSelectedContracts || []);
  const contracts = useAtomValue(signedContractsAtom);

  const handleDiscountChange = (newDiscount: number): void => {
    setDiscount(newDiscount);
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      sx={{
        '& .MuiPaper-root.MuiDialog-paper': {
          minWidth: {
            xs: 'calc(100vw - 64px)',
            sm: 640,
          },
        },
      }}
    >
      <DialogTitle>
        <Typography>
          {t(initialDiscount ? 'price:edit_global_discount' : 'price:add_a_global_discount')}
        </Typography>
        <Typography>
          {t('price:add_a_global_discount_description')}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box>
          <Typography variant="bodySmall">
            {t('price:discount_value')}
          </Typography>
          <OutlinedInput
            type="number"
            fullWidth
            inputProps={{
              min: 0,
              max: 100,
            }}
            placeholder={t('contracts:enter_your_discount')}
            value={discount}
            endAdornment="%"
            onChange={(event) => handleDiscountChange(parseFloat(event.target.value))}
          />
        </Box>
        <Box>
          <Typography sx={{ mb: 0.5 }} variant="bodySmall">
            {t('price:store_choice')}
          </Typography>
          <ContractsSelect
            onChange={(values) => setSelectedContracts(values)}
            contracts={contracts}
            values={selectedContracts}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} variant="text">
          {t('common:cancel')}
        </Button>
        <Button
          variant="contained"
          startIcon={initialDiscount ? <EditOutlinedIcon /> : <AddIcon />}
          disabled={!discount || !selectedContracts.length}
          onClick={() => onClose({ discount: discount!, contractIds: selectedContracts })}
        >
          {t(initialDiscount ? 'common:modify' : 'common:add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
