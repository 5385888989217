import { Box, Checkbox, Divider, FormControlLabel, TextField, Typography } from '@mui/material';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { useMemo, useState } from 'react';
import { useRefinementList } from 'react-instantsearch';
import { useTranslation } from 'react-i18next';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import FilterDisplay, { FilterDisplayMode } from './FilterDisplay';
import useSortedProductFamiliesMap from '../../hooks/useSortedProductFamiliesMap';
import useNormalizedCurrentRefinements from '../../hooks/useNormalizedCurrentRefinements';

interface ProductFamiliesFilterProps {
  attribute: string;
  mode?: FilterDisplayMode;
}

export default function ProductFamiliesFilter({ attribute, mode }: ProductFamiliesFilterProps): React.JSX.Element {
  const { t } = useTranslation(['discovery', 'product-family']);
  const { items, refine } = useRefinementList({ limit: 100, attribute });
  const currentRefinements = useNormalizedCurrentRefinements(attribute) as string[];
  const [itemQuery, setItemQuery] = useState('');
  const productFamiliesMap = useSortedProductFamiliesMap(items.map((item) => item.value) as Product_Sub_Family_Name_Enum[]);

  const familyOptions = useMemo((): React.JSX.Element[] => {
    return productFamiliesMap
      .filter((value) => value.label.toLowerCase().indexOf(itemQuery.toLowerCase()) === 0)
      .map(({ value, label }, index, array) => (
        <>
          <FormControlLabel
            key={value}
            control={<Checkbox checked={currentRefinements.includes(value)} />}
            onChange={() => refine(value)}
            label={label}
            sx={{ ml: 0 }}
          />
          {index < array.length - 1 && <Divider />}
        </>
      ));
  }, [items, currentRefinements, itemQuery]);

  return (
    <FilterDisplay
      icon={<ShoppingBasketOutlinedIcon />}
      onClose={() => setItemQuery('')}
      label={t('discovery:departments')}
      activeItemCount={currentRefinements.length}
      mode={mode}
      name="product_families"
      content={(
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            size="small"
            slotProps={{
              input: {
                startAdornment: <SearchIcon fontSize="small" sx={{ mr: 1 }} />,
              },
            }}
            onChange={(event) => setItemQuery(event.target.value)}
            value={itemQuery}
            placeholder={t('discovery:search_a_particular_departement')}
            sx={{
              mb: 1.75,
            }}
          />
          <Typography sx={{ mb: 1 }} variant="titleMedium">{t('discovery:select_one_or_several_departments')}</Typography>
          {familyOptions}
        </Box>
      )}
    />
  );
}
