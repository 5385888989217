import { Dialog } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';
import { useAtomValue } from 'jotai';
import { Business_Profile_Enum } from 'kheops-graphql';
import useOpenable from '../../hooks/useOpenable';
import ProductFamiliesSettings from './ProductFamiliesSettings';
import SettingsDialogWelcome from './SettingsDialogWelcome';
import DeliveryZoneSettings from './DeliveryZoneSettings';
import ContactInformationSettings from './ContactInformationSettings';
import SettingsSaved from './SettingsSaved';
import { currentContextAtom, userAtom } from '../../state';
import { RoutePaths } from '../../routes/AppRoutes';

export interface SettingsDialogViewProps {
  next: (skipped?: boolean) => void;
  standAlone?: boolean;
}

export default function SettingsDialog(): React.JSX.Element {
  const navigate = useNavigate();
  const user = useAtomValue(userAtom);
  const context = useAtomValue(currentContextAtom);
  const { open, close, isOpen } = useOpenable();
  const [views, setViews] = useState<React.JSX.Element[]>([]);
  const [viewIndex, setViewIndex] = useState(0);
  const skippedSteps = useRef(0);
  const viewIndexRef = useRef(0);
  const maxViewIndexRef = useRef(0);
  const { t } = useTranslation(['common', 'settings']);

  const next = (skipped?: boolean): void => {
    if (skipped) {
      skippedSteps.current += 1;
    }

    if (viewIndexRef.current === maxViewIndexRef.current - 1
      || (viewIndexRef.current === maxViewIndexRef.current - 2 && skippedSteps.current)
    ) {
      close();

      if (skippedSteps.current) {
        navigate(generatePath(RoutePaths.USER));
      }
    } else {
      viewIndexRef.current += 1;
      setViewIndex(viewIndexRef.current);
    }
  };

  useEffect((): void => {
    if (views.length) {
      return;
    }
    setViewIndex(0);

    const settingsViews: React.JSX.Element[] = [<SettingsDialogWelcome next={next} />];

    const shouldDisplayDeliveryZoneSettings = !context.deliveryZones?.length && context.realm === Business_Profile_Enum.Supplier;
    const shouldDisplayProductFamiliesSettings = !context.productFamilies?.length && context.realm === Business_Profile_Enum.Buyer;
    const shouldDisplayProductOrDeliverySettings = shouldDisplayDeliveryZoneSettings || shouldDisplayProductFamiliesSettings;

    if (!user.contactInformation) {
      settingsViews.push(
        <ContactInformationSettings
          next={next}
          submitLabel={t(shouldDisplayProductOrDeliverySettings ? 'common:next' : 'common:validate')}
          cancelLabel={t('settings:complete_later')}
        />,
      );
    }

    if (shouldDisplayDeliveryZoneSettings) {
      settingsViews.push(<DeliveryZoneSettings next={next} />);
    }

    if (shouldDisplayProductFamiliesSettings) {
      settingsViews.push(<ProductFamiliesSettings next={next} />);
    }

    settingsViews.push(<SettingsSaved next={next} />);

    setViews(settingsViews);

    // Check if there's more views than the welcome and the final ones
    if (settingsViews.length > 2) {
      maxViewIndexRef.current = settingsViews.length;
      open();
    }
  }, [views]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!!views.length && (
        <Dialog
          open={isOpen}
          onClose={(): void => { /* noop */ }}
          sx={{
            mx: {
              xs: 2,
              sm: 0,
            },
            '& .MuiPaper-root.MuiDialog-paper': {
              minWidth: {
                xs: 'calc(100vw - 64px)',
                sm: 640,
              },
              maxHeight: {
                xs: 'calc(100vh - 64px)',
                sm: 'calc(100vh - 112px)',
                lg: 'calc(100vh - 124px)',
              },
            },
          }}
        >
          {views[viewIndex]}
        </Dialog>
      )}
    </>
  );
}
