import { Box, Checkbox, Divider, MenuItem, Select, SxProps, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useFormContext } from 'react-hook-form';
import SearchInput from '../../../common/components/SearchInput';
import { companyPackagingsAtom } from '../../state';
import { insensitiveStringIncludes } from '../../../common/utils/common.utils';
import { DraftSubCatalog, displayedCompanyPackagingsAtom, filteredCompanyPackagingsAtom } from './state';

enum SelectedPackagingsCategories {
  ALL_PACKAGINGS,
  SELECTED_PACKAGINGS,
  NOT_SELECTED_PACKAGINGS,
}

interface SubCatalogPackagingsFormFiltersProps {
  sx?: SxProps;
}

export default function SubCatalogPackagingsFormFilters({ sx }: SubCatalogPackagingsFormFiltersProps): React.JSX.Element {
  const theme = useTheme();
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const { watch, setValue } = useFormContext<DraftSubCatalog>();
  const [selectedPackagingsCategory, setSelectedPackagingsCategory] = useState(SelectedPackagingsCategories.ALL_PACKAGINGS);
  const packagingsAvailability = watch('packagingsAvailability');
  const catalogPackagings = useAtomValue(companyPackagingsAtom);
  const [filteredCatalogPackagings, setFilteredCatalogPackagings] = useAtom(filteredCompanyPackagingsAtom);
  const setDisplayedCatalogPackagings = useSetAtom(displayedCompanyPackagingsAtom);
  const { t } = useTranslation(['common', 'catalogs']);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const newFilteredProducts = catalogPackagings.filter((packaging) => {
      const matchSearch = !searchQuery || insensitiveStringIncludes(packaging.product.name, searchQuery) || packaging.base_unit.gtin?.includes(searchQuery);

      return matchSearch;
    });

    setFilteredCatalogPackagings(newFilteredProducts);
  }, [searchQuery]);

  const availableFiltredPackagings = filteredCatalogPackagings.filter((packaging) => packaging.sku in packagingsAvailability && packagingsAvailability[packaging.sku].isAvailable);
  const availableFilteredPackagingsSkusSet = new Set(availableFiltredPackagings.map(({ sku }) => sku));

  const handleGlobalCheckBox = (checked: boolean): void => {
    filteredCatalogPackagings.forEach((packaging) => {
      setValue(`packagingsAvailability.${packaging.sku}.isAvailable`, checked, { shouldDirty: true });
    });
  };

  const handleDisplayedPackagingsCategoryChange = (category: SelectedPackagingsCategories | null): void => {
    if (category !== null) {
      setSelectedPackagingsCategory(category);
    }
  };

  useEffect(() => {
    switch (selectedPackagingsCategory) {
      case SelectedPackagingsCategories.ALL_PACKAGINGS:
        setDisplayedCatalogPackagings(filteredCatalogPackagings);
        break;
      case SelectedPackagingsCategories.SELECTED_PACKAGINGS:
        setDisplayedCatalogPackagings(availableFiltredPackagings);
        break;
      case SelectedPackagingsCategories.NOT_SELECTED_PACKAGINGS:
        setDisplayedCatalogPackagings(filteredCatalogPackagings.filter(({ sku }) => !availableFilteredPackagingsSkusSet.has(sku)));
        break;
      default:
        setDisplayedCatalogPackagings([]);
    }
  }, [selectedPackagingsCategory, filteredCatalogPackagings]);

  const displayedCategoryChangeComponent = useMemo(() => {
    if (isDownLg) {
      return (
        <Select
          value={selectedPackagingsCategory}
          onChange={(event) => handleDisplayedPackagingsCategoryChange(event.target.value as SelectedPackagingsCategories)}
          sx={{
            height: 32,
          }}
        >
          <MenuItem value={SelectedPackagingsCategories.ALL_PACKAGINGS}>{t('catalogs:all_N', { count: filteredCatalogPackagings.length })}</MenuItem>
          <MenuItem value={SelectedPackagingsCategories.SELECTED_PACKAGINGS}>{t('catalogs:selected_N', { count: availableFiltredPackagings.length })}</MenuItem>
          <MenuItem value={SelectedPackagingsCategories.NOT_SELECTED_PACKAGINGS}>{t('catalogs:not_selected_N', { count: filteredCatalogPackagings.length - availableFiltredPackagings.length })}</MenuItem>
        </Select>
      );
    }

    return (

      <ToggleButtonGroup
        onChange={(_, category) => handleDisplayedPackagingsCategoryChange(category)}
        value={selectedPackagingsCategory}
        exclusive
        sx={{
          flex: 1,
        }}
      >
        <ToggleButton value={SelectedPackagingsCategories.ALL_PACKAGINGS}>
          <Typography variant="labelLarge">
            {t('catalogs:all_N', { count: filteredCatalogPackagings.length })}
          </Typography>
        </ToggleButton>
        <ToggleButton value={SelectedPackagingsCategories.SELECTED_PACKAGINGS}>
          <Typography variant="labelLarge">
            {t('catalogs:selected_N', { count: availableFiltredPackagings.length })}
          </Typography>
        </ToggleButton>
        <ToggleButton value={SelectedPackagingsCategories.NOT_SELECTED_PACKAGINGS}>
          <Typography variant="labelLarge">
            {t('catalogs:not_selected_N', { count: filteredCatalogPackagings.length - availableFiltredPackagings.length })}
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }, [isDownLg, selectedPackagingsCategory, filteredCatalogPackagings, availableFiltredPackagings]);

  return (
    <Box
      sx={{
        ...sx,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        width: '100%',
      }}
    >
      <Typography
        variant="titleMedium"
        sx={{
          display: {
            [theme.breakpoints.down('lg')]: {
              display: 'none',
            },
          },
        }}
      >
        {t('common:packaging_other')}
      </Typography>
      <SearchInput
        value={searchQuery}
        onChange={setSearchQuery}
        sx={{
          width: {
            xs: '100%',
            lg: 400,
          },
          mt: 0.5,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1.5,
          height: 40,
          py: 0.5,
        }}
      >
        <Checkbox
          indeterminate={availableFiltredPackagings.length < filteredCatalogPackagings.length && availableFiltredPackagings.length !== 0}
          onChange={(_, checked) => handleGlobalCheckBox(checked)}
          checked={availableFiltredPackagings.length === filteredCatalogPackagings.length}
        />
        {!isDownLg && (
          <Divider
            flexItem
            orientation="vertical"
            sx={{
              my: 1,
            }}
          />
        )}
        {displayedCategoryChangeComponent}
      </Box>
    </Box>
  );
}
