import { Box, Breakpoint, Button, CircularProgress, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';
import { useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { AlgoliaHit, SearchResponse } from 'instantsearch.js';
import { useAtomValue } from 'jotai';
import { productFamilyMap } from 'kheops-utils';
import { RoutePaths } from '../../routes/AppRoutes';
import { CompanyByIdQuery } from '../../queries/__generated__/companyById.generated';
import SizedImage from '../../common/components/SizedImage';
import FileUtils, { PhotoSize } from '../../common/utils/file.utils';
import CompanyInfoChips from '../../company/CompanyInfoChips';
import ExpandableText from '../../common/components/ExpandableText';
import { useSearchClient } from '../../hooks/useSearchClient';
import { ProductHit } from '../../search/search';
import DiscoveryProductHit from './DiscoveryProductHit';
import { currentContextAtom } from '../../state';

const PRODUCT_IMAGE_SIZE_BY_BREAKPOINT: Record<Breakpoint, PhotoSize> = {
  xs: {
    width: 200,
    height: 200,
  },
  sm: {
    width: 200,
    height: 200,
  },
  md: {
    width: 200,
    height: 200,
  },
  lg: {
    width: 240,
    height: 240,
  },
  xl: {
    width: 240,
    height: 240,
  },
};

interface DiscoverSupplierPackagingSorting {
  packagingsInUserScope: AlgoliaHit<ProductHit>[];
  packagingsWithGMVRank: AlgoliaHit<ProductHit>[];
  packagingsToSortAlphabetically: AlgoliaHit<ProductHit>[];
}

interface DiscoverSupplierDialogProps {
  companyData: CompanyByIdQuery;
  open: boolean;
  onClose: () => void;
  onContactClick: () => void;
}

export default function DiscoverSupplierDialog({ companyData, open, onContactClick, onClose }: DiscoverSupplierDialogProps): React.JSX.Element {
  const searchClient = useSearchClient();
  const [isFetchingPackagings, setIsFetchingPackagings] = useState(false);
  const [relevantPackagings, setRelevantPackagings] = useState<AlgoliaHit<ProductHit>[]>([]);
  const navigate = useNavigate();
  const { productFamilies } = useAtomValue(currentContextAtom);
  const { t } = useTranslation(['contracts', 'common']);
  const company = companyData.company_by_pk!;

  const userProductSubFamilies = useMemo(() => {
    if (!productFamilies?.length) {
      return;
    }

    if (productFamilies[0].global_setting) {
      return;
    }

    return productFamilies?.flatMap((userProductFamily) => {
      if (userProductFamily.product_sub_family) {
        return userProductFamily.product_sub_family;
      }

      return productFamilyMap[userProductFamily.product_family!];
    });
  }, [productFamilies]);

  const getRelevantPackagings = async (): Promise<void> => {
    setIsFetchingPackagings(true);
    const { results } = await searchClient.search<AlgoliaHit<ProductHit>>([{ indexName: import.meta.env.REACT_APP_ALGOLIA_PACKAGING_INDEX, params: { filters: `company_id:${company!.id}` } }]);
    const unsortedHits = (results[0] as SearchResponse<AlgoliaHit<ProductHit>>).hits;

    const sortedCategories = unsortedHits.reduce<DiscoverSupplierPackagingSorting>((acc, hit) => {
      if (userProductSubFamilies?.includes(hit.family)) {
        acc.packagingsInUserScope.push(hit);
      } else if (hit.customGMVRank) {
        acc.packagingsWithGMVRank.push(hit);
      } else {
        acc.packagingsToSortAlphabetically.push(hit);
      }

      return acc;
    }, { packagingsInUserScope: [], packagingsWithGMVRank: [], packagingsToSortAlphabetically: [] } as DiscoverSupplierPackagingSorting);

    sortedCategories.packagingsToSortAlphabetically = sortedCategories.packagingsToSortAlphabetically.sort((packagingA, packagingB) => packagingA.name.localeCompare(packagingB.name));

    const sortedPackagings = [
      sortedCategories.packagingsInUserScope,
      sortedCategories.packagingsWithGMVRank,
      sortedCategories.packagingsToSortAlphabetically,
    ].flat().slice(0, 3);

    setRelevantPackagings(sortedPackagings);
    setIsFetchingPackagings(false);
  };

  useEffect(() => {
    if (open) {
      getRelevantPackagings();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root.MuiDialog-paper': {
          minWidth: {
            xs: 'calc(100vw - 64px)',
            sm: 580,
            lg: 800,
          },
          minHeight: 300,
        },
      }}
    >
      <DialogContent
        sx={{
          gap: 2,
          display: 'flex',
          flexDirection: 'column',
          p: {
            xs: 2,
            sm: 3,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {company && (
          <Box
            sx={{
              display: 'flex',
              gap: 2,
            }}
          >
            <SizedImage
              src={FileUtils.BuildCompanyImageUrl({
                photo: company.photos ? company.photos[0]?.photo : undefined,
                type: company.business_profile,
                size: { height: 84, width: 84 },
              })}
              alt={t('common:company')}
              height={84}
              width={84}
            />
            <Box sx={{ pr: 7 }}>
              <Typography sx={{ mb: 1 }} variant="titleLarge">
                {company.tradeName}
              </Typography>
              <CompanyInfoChips company={company} />
              <Typography variant="bodyMedium">
                📍 {`${company.addresses[0].locality}, ${company.addresses[0].countryCode} (${company.addresses[0].postalCode})`}
              </Typography>
            </Box>
          </Box>
          )}
          <Button variant="text" onClick={onClose}>
            <CloseIcon />
          </Button>
        </Box>
        {company?.description && (<ExpandableText text={company?.description} isDangerousText lineClampThreshold={2} variant="bodySmall" />)}
        <Typography variant="titleMedium">{t('contracts:interesting_best_sellers')}</Typography>
        {isFetchingPackagings
          ? <CircularProgress sx={{ mx: 'auto', my: 3 }} />
          : (
            <Box
              sx={{
                display: 'flex',
                overflowX: 'auto',
                gap: 2,
              }}
            >
              {relevantPackagings.map((packagingHit) => (
                <DiscoveryProductHit
                  hit={packagingHit}
                  key={packagingHit.objectID}
                  shouldDisplayPVCMargin
                  productImageSizeByBreakPoint={PRODUCT_IMAGE_SIZE_BY_BREAKPOINT}
                  productBoxSx={{ flexDirection: 'column' }}
                  parentBoxSx={{
                    flexShrink: 0,
                    height: 'auto',
                    width: {
                      xs: 198,
                      lg: 238,
                    },
                  }}
                />
              ))}
            </Box>
          )}
      </DialogContent>
      <DialogActions
        sx={{
          flexDirection: {
            xs: 'column-reverse',
            sm: 'row',
          },
        }}
      >
        <Button
          onClick={() => navigate(generatePath(RoutePaths.CONTRACT_COMPANY, { companyId: company.id }))}
          variant="outlined"
          sx={{
            width: {
              xs: '100%',
              sm: 'auto',
            },
            flex: 'none',
          }}
        >
          {t('contracts:see_all_products')}
        </Button>
        <Button
          onClick={onContactClick}
          variant="contained"
          sx={{
            width: {
              xs: '100%',
              sm: 'auto',
            },
          }}
        >
          {t('contracts:contact_with_supplier')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
