import { Box, Button, SxProps, Theme, Typography, useTheme } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import { useAtomValue } from 'jotai';
import { Business_Profile_Enum } from 'kheops-graphql';
import { useLocation } from 'react-router';
import DiscountIcon from '../../assets/icons/discount.svg?react';
import MinimumOrderValueIcon from '../../assets/icons/minimum_order_value.svg?react';
import CatalogIcon from '../../assets/icons/catalog.svg?react';
import ContractConditionsDialog from '../../common/components/ContractConditionsDialog';
import useOpenable from '../../hooks/useOpenable';
import useMakeAnOffer from '../hooks/useMakeAnOffer';
import useCurrencyFormat from '../../hooks/useCurrencyFormat';
import { ContractsAsSupplierDocument } from '../../queries/__generated__/contractsAsSupplier.generated';
import { ContractsAsBuyerDocument } from '../../queries/__generated__/contractsAsBuyer.generated';
import { useAcceptContractOfferMutation } from '../../mutations/__generated__/acceptContractOffer.generated';
import { ContractPropositionInfoDocument } from '../../queries/__generated__/contractPropositionInfo.generated';
import ContractsUtils from '../../common/utils/contracts.utils';
import { currentContextAtom } from '../../state';
import { ContractConditions, showChannelMembersAtom } from '../state';
import AcceptOfferDialog from './AcceptOfferDialog';
import OpenPackagingPricesButton from './OpenPackagingPricesButton';

export interface OfferDataProps {
  contractId: string;
  offerInfo: ContractConditions;
  editable?: boolean;
  negotiable?: boolean;
  sx?: SxProps<Theme>;
}

export default function OfferData({ contractId, offerInfo, editable, negotiable, sx }: OfferDataProps): React.JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'catalogs', 'contracts']);
  const location = useLocation();
  const currencyFormat = useCurrencyFormat({ maximumFractionDigits: 0 });
  const { currentChannel } = useGroupChannelContext();
  const { realm, commissionRate } = useAtomValue(currentContextAtom);
  const makeAnOffer = useMakeAnOffer();
  const [acceptContractOffer] = useAcceptContractOfferMutation({
    refetchQueries: [ContractPropositionInfoDocument, ContractsAsSupplierDocument, ContractsAsBuyerDocument],
  });
  const { open: openContractConditionsDialog, close: closeContractConditionsDialog, isOpen: isContractConditionsDialogOpen } = useOpenable();
  const { open: openAcceptOfferDialog, close: closeAcceptOferDialog, isOpen: isAcceptOfferDialogOpen } = useOpenable();
  const showChannelMembers = useAtomValue(showChannelMembersAtom);

  const displayPackagingPricesButton = realm === Business_Profile_Enum.Buyer && (!!offerInfo.custom_price_list_id || !!offerInfo.discount);

  const handleContractOfferDialogClose = (response?: ContractConditions): void => {
    if (response) {
      makeAnOffer(currentChannel!, response, contractId);
    }

    closeContractConditionsDialog();
  };

  const handleAcceptOfferDialogConfirm = (confirm: boolean): void => {
    if (confirm) {
      acceptContractOffer({ variables: { contractId } });
    }

    closeAcceptOferDialog();
  };

  useEffect(() => {
    if (editable && (location.state?.newCustomPriceListId || location.state?.newSubCatalogId)) {
      openContractConditionsDialog();
    }
  }, [location]);

  const priceModeLabel = useMemo((): string => {
    return ContractsUtils.getPriceModeLabel(t, realm, offerInfo.discount, offerInfo.customPriceListName);
  }, [offerInfo]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: editable ? 'row' : 'column',
            md: showChannelMembers && !editable ? 'column' : 'row',
          },
          gap: {
            xs: editable ? 3 : 2,
            lg: 2,
          },
          justifyContent: 'space-between',
          backgroundColor: 'surfaceContainerHigh.main',
          borderRadius: 4,
          p: 2,
          ...sx,
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          {realm === Business_Profile_Enum.Supplier && (
            <Box>
              <Typography
                variant="bodySmall"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  mb: 0.5,
                }}
              >
                <CatalogIcon width={18} height={18} /> {t('catalogs:catalog')}
              </Typography>
              <Box>
                <Typography variant="bodyMedium">
                  {offerInfo.catalogName || t('catalogs:default_catalog')}
                </Typography>
              </Box>
            </Box>
          )}
          {ContractsUtils.displayPriceMode(realm, offerInfo) && (
            <Box>
              <Typography
                variant="bodySmall"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  mb: 0.5,
                }}
              >
                <DiscountIcon width={18} height={18} /> {t('common:prices')}
              </Typography>
              <Box sx={{ display: 'flex', height: 20, alignItems: 'center' }}>
                <Typography variant="bodyMedium" sx={{ whiteSpace: 'nowrap' }}>
                  {priceModeLabel}
                </Typography>
                {displayPackagingPricesButton && (
                  <OpenPackagingPricesButton offerInfo={offerInfo} />
                )}
              </Box>
            </Box>
          )}
          <Box>
            <Typography
              variant="bodySmall"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                mb: 0.5,
              }}
            >
              <MinimumOrderValueIcon fill={theme.palette.marketing3.main} width={18} height={18} /> {t('common:minimum_order_value')}
            </Typography>
            <Typography variant="bodyMedium">
              {currencyFormat.format(offerInfo.minimum_order_value!)}
            </Typography>
          </Box>
        </Box>
        {editable && (
          <Button variant="text" sx={{ alignSelf: 'start' }} onClick={openContractConditionsDialog}>
            <EditOutlinedIcon />
          </Button>
        )}
        {negotiable && (
          <Box sx={{ display: 'flex', alignSelf: 'start', gap: 2 }}>
            <Button variant="outlined" onClick={openContractConditionsDialog}>
              {t('contracts:negotiate')}
            </Button>
            <Button startIcon={<AddBusinessOutlinedIcon />} variant="contained" onClick={openAcceptOfferDialog}>
              {t('contracts:accept')}
            </Button>
          </Box>
        )}
      </Box>
      {isContractConditionsDialogOpen && (
        <ContractConditionsDialog
          onClose={(response) => handleContractOfferDialogClose(response)}
          open={isContractConditionsDialogOpen}
          title={t('contracts:make_an_offer')}
          description={t(`contracts:${realm === Business_Profile_Enum.Buyer ? 'offer_dialog_description_as_buyer' : 'offer_dialog_description_as_supplier'}`)}
          submitLabel={t('contracts:offer')}
          contractId={contractId}
          conditions={offerInfo}
          commissionRate={commissionRate}
        />
      )}
      {isAcceptOfferDialogOpen && (
        <AcceptOfferDialog
          open={isAcceptOfferDialogOpen}
          onClose={handleAcceptOfferDialogConfirm}
          offerInfo={offerInfo}
        />
      )}
    </>
  );
}
