import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface OrderCancellationDialogProps {
  open: boolean;
  onClose: (value: boolean, comment?: string) => void;
}

export default function OrderCancellationDialog({ open, onClose }: OrderCancellationDialogProps): React.JSX.Element {
  const [comment, setComment] = useState<string>();
  const { t } = useTranslation(['order']);

  const handleCancel = (): void => {
    onClose(false);
  };
  const handleConfirm = (): void => {
    onClose(true, comment);
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setComment(event.target.value);
  };

  return (
    <Dialog
      sx={{
        mx: {
          xs: 2,
          sm: 0,
        },
        '& .MuiPaper-root': {
          minWidth: {
            xs: '100%',
            sm: 600,
            md: 724,
            lg: 800,
          },
        },
      }}
      open={open}
    >
      <DialogTitle>
        <Typography>
          {t('order:cancel_order')}
        </Typography>
        <Typography>
          {t('order:cancel_order_message')}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
        <Typography variant="titleMedium">
          {t('order:leave_a_message')}
        </Typography>
        <TextField
          sx={{
            width: '100%',
          }}
          multiline
          placeholder={t('order:cancel_comment_placeholder')}
          value={comment}
          onChange={handleCommentChange}
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant="text">
          {t('common:quit')}
        </Button>
        <Button onClick={handleConfirm} variant="contained">
          {t('order:cancel_order')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
