import { Box, Divider, SxProps, Theme } from '@mui/material';
import { Product_Sub_Family_Name_Enum } from 'kheops-graphql';
import SearchInput from '../../common/components/SearchInput';
import ClearAllButton from '../../common/components/ClearAllButton';
import useSearchProducts from './useSearchProducts';
import ProductSubFamilyFilter from './ProductSubFamilyFilter';

export interface DefaultValues {
  productFamilies: Product_Sub_Family_Name_Enum[];
  query: string;
}

export interface ProductSearchBarProps {
  productFamiliesOptions: Product_Sub_Family_Name_Enum[];
  onChange: (productFamilies: Product_Sub_Family_Name_Enum[], query: string) => void;
  defaultValues?: Partial<DefaultValues>;
  sx?: SxProps<Theme>;
}

export default function ProductSearchBar({ productFamiliesOptions, onChange, sx, defaultValues }: ProductSearchBarProps): React.JSX.Element {
  const {
    setQuery,
    setProductFamilies,
    selectedProductFamilyMap,
    productFamilyMap,
    query,
  } = useSearchProducts({ productFamiliesOptions, onChange, defaultValues });

  const clearAll = (): void => {
    setQuery('');
    setProductFamilies([]);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: {
          xs: 'flex-start',
          sm: 'center',
        },
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
        gap: 1,
        mb: 2,
        ...sx,
      }}
    >
      <SearchInput
        onChange={(inputQuery) => setQuery(inputQuery)}
        value={query}
        sx={{
          width: {
            xs: '100%',
            lg: 400,
          },
        }}
      />
      <Divider
        orientation="vertical"
        sx={{
          height: 24,
          display: {
            xs: 'none',
            sm: 'flex',
          },
        }}
      />
      <ProductSubFamilyFilter
        productFamilyMap={productFamilyMap}
        selectedProductFamilyMap={selectedProductFamilyMap}
        setProductFamilies={setProductFamilies}
      />
      {
        query.length + selectedProductFamilyMap.length > 0 && (
        <ClearAllButton
          onClick={clearAll}
        />
        )
      }
    </Box>
  );
}
