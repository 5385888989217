import { Button, MenuItem, Select, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router';
import { RoutePaths } from '../../routes/AppRoutes';
import { currentContextAtom } from '../../state';
import { useCustomPriceListsInfoByCompanyQuery } from '../../queries/__generated__/customPriceListsInfoByCompany.generated';

export interface CustomPriceListsSelectProps {
  value: string;
  onChange: (id: string, name: string) => void;
  contractId: string;
}

export default function CustomPriceListsSelect({ value, onChange, contractId }: CustomPriceListsSelectProps): React.JSX.Element {
  const { t } = useTranslation(['price']);
  const location = useLocation();
  const { companyId } = useAtomValue(currentContextAtom);
  const { data } = useCustomPriceListsInfoByCompanyQuery({ variables: { companyId } });

  const options = useMemo((): React.JSX.Element[] => {
    return (data?.custom_price_list || []).map(({ id, name }) => {
      const isSelected = value === id;

      return (
        <MenuItem
          value={id}
          key={id}
          sx={{
            py: 2,
            px: 1,
            mx: 2,
            borderRadius: 4,
            justifyContent: 'center',
            fontWeight: isSelected ? 700 : 400,
            '&:not:(last-of-type)': {
              mb: 1,
            },
          }}
        >
          {name}
        </MenuItem>
      );
    });
  }, [data, value]);

  const getName = (customPriceListId: string): string => {
    return data?.custom_price_list.find(({ id }) => id === customPriceListId)?.name || '';
  };

  return (
    <Select
      size="small"
      displayEmpty
      value={value}
      onChange={(event) => onChange(event.target.value, getName(event.target.value))}
      sx={{
        backgroundColor: 'white',
        width: 280,
        py: 0.5,
      }}
      renderValue={(selected) => (
        !selected
          ? <Typography variant="bodyMedium">{t('price:select_a_custom_price_list')}</Typography>
          : getName(selected)
      )}
      MenuProps={{
        sx: {
          '& .MuiPaper-root': {
            mt: 1,
            borderRadius: 4,
          },
        },
      }}
    >
      {options}
      <MenuItem
        sx={{
          display: {
            xs: 'none',
            lg: 'flex',
          },
          '&:hover': {
            backgroundColor: 'unset',
            cursor: 'default',
          },
        }}
        disableRipple
      >
        <Button
          component={Link}
          to={`${RoutePaths.CUSTOM_PRICE_LIST}?contractId=${contractId}`}
          state={{ origin: location.pathname }}
          variant="outlined"
          startIcon={<AddIcon />}
          fullWidth
        >
          {t('price:create_a_new_custom_price_list')}
        </Button>
      </MenuItem>
    </Select>
  );
}
