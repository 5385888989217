import { Box, SxProps } from '@mui/material';
import { Brand_Enum, Business_Profile_Enum } from 'kheops-graphql';
import FileUtils from '../utils/file.utils';

type CompanyLogoSize = 'small' | 'medium' | 'large';
type CompanyLogoVariant = 'square' | 'circle' | 'transparent';

interface CompanyLogoComponentsClasses {
  logo: string;
  container: string
}

interface CompanyLogoProps {
  brand?: Brand_Enum;
  size: CompanyLogoSize;
  variant: CompanyLogoVariant;
  sx?: SxProps;
}

export default function CompanyLogo({ brand, sx, size, variant }: CompanyLogoProps): React.JSX.Element {
  const VARIANT_CLASSES_MAP = new Map<CompanyLogoVariant, string>([
    ['square', 'Kheops-companyLogo-container--square'],
    ['circle', 'Kheops-companyLogo-container--circle'],
    ['transparent', 'Kheops-companyLogo-container--transparent'],
  ]);

  const SIZE_CLASSES_MAP = new Map<CompanyLogoSize, CompanyLogoComponentsClasses>([
    ['small', {
      logo: 'Kheops-companyLogo--small',
      container: 'Kheops-companyLogo-container--small',
    }],
    ['medium', {
      logo: 'Kheops-companyLogo--medium',
      container: 'Kheops-companyLogo-container--medium',
    }],
    ['large', {
      logo: 'Kheops-companyLogo--large',
      container: 'Kheops-companyLogo-container--large',
    }],
  ]);

  return (
    <Box
      className={`${SIZE_CLASSES_MAP.get(size)?.container} ${VARIANT_CLASSES_MAP.get(variant)}`}
      sx={{
        display: 'flex',
        '&.Kheops-companyLogo-container--small': {
          maxHeight: 32,
          borderRadius: 2,
          maxWidth: 32,
          aspectRatio: '1 / 1',
        },
        '&.Kheops-companyLogo-container--medium': {
          borderRadius: 4,
          maxHeight: 48,
          minHeight: 40,
          maxWidth: 48,
          minWidth: 40,
          aspectRatio: '1 / 1',
        },
        '&.Kheops-companyLogo-container--large': {
          borderRadius: 6,
          minHeight: 56,
          minWidth: 56,
          aspectRatio: '1 / 1',
        },
        '&.Kheops-companyLogo-container--square': {
          backgroundColor: 'surfaceContainerLowest.main',
        },
        '&.Kheops-companyLogo-container--circle': {
          borderRadius: '100%',
          backgroundColor: 'surfaceContainerLowest.main',
        },
        '&.Kheops-companyLogo-container--transparent': {
          backgroundColor: 'transparent',
        },
        ...sx,
      }}
    >
      <Box
        className={SIZE_CLASSES_MAP.get(size)?.logo}
        component="img"
        src={brand ? FileUtils.BuildBrandLogoURL(brand) : FileUtils.GetBusinessPlaceHolderImage(Business_Profile_Enum.Buyer)}
        alt="logo"
        loading="lazy"
        sx={{
          '&.Kheops-companyLogo--small': {
            overflow: 'auto',
            paddingTop: 0.5,
            paddingLeft: 0.5,
            paddingBottom: 0.5,
            paddingRight: 0.5,
          },
          '&.Kheops-companyLogo--medium': {
            overflow: 'auto',
            paddingTop: 1,
            paddingLeft: 1,
            paddingBottom: 1,
            paddingRight: 1,
          },
          '&.Kheops-companyLogo--large': {
            overflow: 'auto',
            paddingTop: 1.5,
            paddingLeft: 1.5,
            paddingBottom: 1.5,
            paddingRight: 1.5,
          },
        }}
      />
    </Box>
  );
}
