import { Box, Button, SxProps } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router';
import { DraftSubCatalog } from './state';

export interface SubCatalogFormButtonsProps {
  fullWidth?: boolean;
  shouldDisplayCancelButton?: boolean;
  parentBoxSx?: SxProps;
}

export default function SubCatalogFormButtons({ fullWidth, parentBoxSx, shouldDisplayCancelButton = true }: SubCatalogFormButtonsProps): React.JSX.Element {
  const { t } = useTranslation(['common', 'catalogs']);
  const { formState: { isDirty, isValid, isSubmitted } } = useFormContext<DraftSubCatalog>();
  const [searchParams] = useSearchParams();
  const subCatalogId = searchParams.get('subCatalogId');
  const handleCancel = (): void => {
    window.history.back();
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, py: 2, ...parentBoxSx }}>
      {shouldDisplayCancelButton && (
        <Button
          onClick={handleCancel}
          variant="text"
          sx={{ flexGrow: fullWidth ? 1 : 0 }}
        >
          {t('common:cancel')}
        </Button>
      )}
      <Button
        disabled={(!isDirty) || (!isValid && isSubmitted)}
        variant="contained"
        type="submit"
        startIcon={<AddIcon />}
        sx={{ flexGrow: fullWidth ? 1 : 0 }}
      >
        {t(subCatalogId ? 'catalogs:update_the_catalog' : 'catalogs:create_the_catalog')}
      </Button>
    </Box>
  );
}
