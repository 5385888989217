import { Box, FormHelperText, TextField, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { useAtomValue } from 'jotai';
import { useSearchParams } from 'react-router';
import { Contract_Status_Enum } from 'kheops-graphql';
import { contractsAtom, currentContextAtom } from '../../state';
import { DraftCustomPriceList } from './state';
import { useCustomPriceListsByCompanyIdQuery } from '../../queries/__generated__/customPriceListsByCompanyId.generated';
import ContractsSelect from '../../common/components/ContractsSelect';
import { ContractPreview } from '../../hooks/useContracts';

export default function CustomPriceListInfoForm(): React.JSX.Element {
  const { t } = useTranslation(['common', 'validation']);
  const { control } = useFormContext<DraftCustomPriceList>();
  const { companyId } = useAtomValue(currentContextAtom);
  const contracts = useAtomValue(contractsAtom);
  const [signedContracts, unsignedContracts] = contracts.reduce<[ContractPreview[], ContractPreview[]]>((acc, contract) => {
    if (contract.status === Contract_Status_Enum.Signed) {
      acc[0].push(contract);
    } else {
      acc[1].push(contract);
    }

    return acc;
  }, [[], []]);
  const [searchParams] = useSearchParams();
  const customPriceListId = searchParams.get('customPriceListId');
  const { data: customPriceListsData } = useCustomPriceListsByCompanyIdQuery({
    variables: { companyId },
  });

  const { field: contractsField } = useController({
    name: 'contracts',
    control,
  });

  const existingNamesToCheck = useMemo((): string[] => (
    (customPriceListsData?.custom_price_list || []).reduce((namesToCheck, { name, id }) => {
      if (id !== customPriceListId) {
        namesToCheck.push(name);
      }

      return namesToCheck;
    }, [] as string[])
  ), [customPriceListsData]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <Typography variant="bodyMedium">
          {t('common:name')}
        </Typography>
        <Box>
          <Controller
            name="name"
            control={control}
            rules={{
              required: t('validation:this_field_is_required') as string,
              validate: {
                unique: (value) => !existingNamesToCheck.includes(value) || t('price:custom_price_list_name_already_exists'),
              },
            }}
            render={({ field, fieldState }) => (
              <>
                <TextField
                  fullWidth
                  {...field}
                  placeholder={t('price:select_custom_price_list_name')}
                  error={!!fieldState.error}
                />
                {fieldState.error
                  && <FormHelperText sx={{ ml: 2 }} error>{fieldState.error?.message}</FormHelperText>}
              </>
            )}
          />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <Typography variant="bodyMedium">
          {t('common:buyer_other')}
        </Typography>
        <ContractsSelect
          onChange={(values) => {
            contractsField.onChange(values);
          }}
          contracts={signedContracts}
          hiddenContracts={unsignedContracts}
          values={contractsField.value}
        />
      </Box>
    </Box>
  );
}
