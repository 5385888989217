import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KheopsTypographyVariant } from '../../providers/CustomThemeProvider';

const VARIANT_TO_CHAR_HEIGHT_AVG_MAP = new Map<KheopsTypographyVariant, number>([
  ['bodyMedium', 7],
  ['bodySmall', 5],
]);
interface ExpandableTextProps {
  text: string;
  isDangerousText?: boolean;
  variant?: KheopsTypographyVariant;
  lineClampThreshold: number;
}

/**
 * Displays text with body2 variant, clamps text when it takes more lines than the given threshold.
 */
export default function ExpandableText({ text, lineClampThreshold, isDangerousText, variant = 'bodyMedium' }: ExpandableTextProps): React.JSX.Element {
  const [shouldClampText, setShouldClampText] = useState(false);
  const { t } = useTranslation('common');
  const [isTextClamped, setIsTextClamped] = useState(false);
  const ref = useRef<HTMLDivElement>(undefined);

  useEffect(() => {
    if (ref.current) {
      // Count number of paragraphs tags, if count of tag is superior to threshold, then clamp.
      if (isDangerousText && ref.current.getElementsByTagName('p').length - 1 > lineClampThreshold) {
        setShouldClampText(true);
        setIsTextClamped(true);
        return;
      }

      // Count number of line breaks, if count is superior to threshold, then clamp.
      const lineBreaksCount = text.split(/\r\n|\r|\n/).length;

      if (lineBreaksCount > lineClampThreshold) {
        setShouldClampText(true);
        setIsTextClamped(true);
        return;
      }

      /*
        Here we try to estimate the number of lines that our text will occupy, the value 7 is chosen slightly
        above the estimated average width a character with Ambit typo and bodyMedium variant (6.5px), because the cost
        of the error of a false shouldClamp is less than a true shouldClamp UX wise.
      */
      if ((text.length * VARIANT_TO_CHAR_HEIGHT_AVG_MAP.get(variant)!) / ref.current.offsetWidth > lineClampThreshold) {
        setShouldClampText(true);
        setIsTextClamped(true);
        return;
      }

      setShouldClampText(false);
    }
  }, [ref, text]);

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: 1,
      }}
    >
      <Typography
        variant={variant}
        sx={{
          whiteSpace: 'break-spaces',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          display: '-webkit-box',
          WebkitLineClamp: isTextClamped ? lineClampThreshold : undefined,
          '& p:first-of-type': {
            mt: 0,
          },
          '& p:last-of-type': {
            mb: 0,
          },
        }}
        dangerouslySetInnerHTML={isDangerousText ? { __html: DOMPurify.sanitize(text) } : undefined}
      >
        {isDangerousText ? undefined : text}
      </Typography>
      { shouldClampText && (
        <Button
          variant="text"
          onClick={() => setIsTextClamped(!isTextClamped)}
          startIcon={!isTextClamped ? <RemoveIcon /> : <AddIcon />}
        >
          {t(`common:${!isTextClamped ? 'less_information' : 'more_information'}`)}
        </Button>
      )}
    </Box>
  );
}
