import { Avatar, Box, Chip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FileUtils, { productFamilyLogoMap } from '../common/utils/file.utils';
import { PackagingFamilyTypeIntl, ProductFamilyTypeIntl } from '../common/i18n/product-family.translation';
import PhoneNumberDisplay from '../common/components/PhoneNumberDisplay';
import { useGetUserContactInformationQuery } from '../queries/__generated__/getUserContactInformation.generated';
import { UserDisplay } from './CompanyUsers';
import PhotosUtils from '../common/utils/photos.utils';

interface UserCardProps {
  user: UserDisplay;
}
export default function UserCard({ user }: UserCardProps): React.JSX.Element {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation(['product-family', 'settings']);
  const { data: userContactInfoData } = useGetUserContactInformationQuery({
    variables: {
      userId: user.id,
    },
  });

  const userProductFamilliesTags = useMemo(() => {
    if (user.productFamiliesSettings.generalSetting) {
      // users with global settings set to none are not displayed
      return (
        <Chip
          label={t('settings:all_departments')}
          variant="tag"
        />
      );
    }
    const productSubfamiliesTags = user.productFamiliesSettings.productSubFamilies.map((subFamily) => (
      <Chip
        avatar={<Box component="img" src={FileUtils.BuildPackagingImageUrl({ type: subFamily as string })} />}
        label={t(`product-family:${PackagingFamilyTypeIntl[subFamily]}`)}
        variant="tag"
        key={subFamily}
      />
    ));

    const productFamiliesTags = user.productFamiliesSettings.productFamilies.map((productFamily) => (
      <Chip
        avatar={<Box component="img" src={productFamilyLogoMap[productFamily]} />}
        label={t(`product-family:${ProductFamilyTypeIntl[productFamily]}`)}
        variant="tag"
        key={productFamily}
      />
    ));

    return [...productFamiliesTags, ...productSubfamiliesTags];
  }, [user]);

  const {
    mobilePhoneComponent,
    landlinePhoneComponent,
    emailComponent,
  } = useMemo(() => {
    if (!userContactInfoData?.getUserContactInformation) {
      return {
        mobilePhoneComponent: undefined,
        landlinePhoneComponent: undefined,
        emailComponent: undefined,
      };
    }

    const { email, mobilePhoneNumber, landlinePhoneNumber } = userContactInfoData.getUserContactInformation;

    return {
      mobilePhoneComponent: (
        mobilePhoneNumber ? <PhoneNumberDisplay typographyVariant="bodySmall" phoneNumber={mobilePhoneNumber} shouldDisplayFlag={false} phoneTypeEmoji="📱" /> : undefined
      ),
      landlinePhoneComponent: (
        landlinePhoneNumber ? <PhoneNumberDisplay typographyVariant="bodySmall" phoneNumber={landlinePhoneNumber} shouldDisplayFlag={false} phoneTypeEmoji="☎️" /> : undefined
      ),
      emailComponent: (
        email ? <Typography variant="bodySmall" color="secondary" sx={{ wordBreak: 'break-all' }}>{email}</Typography> : undefined
      ),
    };
  }, [userContactInfoData]);

  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 4,
        p: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          gap: 1,
        }}
      >
        <Avatar src={PhotosUtils.getUserAvatarPicture(user.avatars)} size={64} />
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              flexDirection: {
                sm: 'row',
                xs: 'column',
              },
              gap: {
                xs: 1,
                sm: 2,
              },
            }}
          >
            <Box>
              <Typography variant="bodyMedium">{user.firstName}&nbsp;{user.lastName}</Typography>
              {!!isXs && emailComponent}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexShrink: 0,
                flexDirection: {
                  sm: 'row',
                  xs: 'column',
                },
                gap: {
                  sm: 3,
                  xs: 0,
                },
              }}
            >
              {mobilePhoneComponent}
              {landlinePhoneComponent}
            </Box>
          </Box>
          {!isXs && (
            <>
              {emailComponent}
              <Box sx={{ mt: 1 }}>{userProductFamilliesTags}</Box>
            </>
          )}
        </Box>
      </Box>
      {!!isXs && (<Box sx={{ mt: 1 }}>{userProductFamilliesTags}</Box>)}
    </Box>
  );
}
