import { Box, Divider, Button, Typography } from '@mui/material';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router';
import { useAtom, useAtomValue } from 'jotai';
import { Business_Profile_Enum, Contract_Status_Enum } from 'kheops-graphql';
import { currentContextAtom } from '../state';
import NoOfferHeader from './offer/NoOfferHeader';
import OfferInfoHeader from './offer/OfferInfoHeader';
import { BuyingCompanyInfo, CompanyOfferInfo, currentChannelAtom, currentContractAtom, showChannelMembersAtom } from './state';
import { RoutePaths } from '../routes/AppRoutes';
import { ellipsisStyle, linkStyle } from '../common/utils/style.utils';
import ToggleNotificationChannel from './ToggleNotificationChannel';
import LeaveChannelButton from './LeaveChannelButton';
import ChannelCompanyImage from './ChannelCompanyImage';

export default function ChannelHeader(): React.JSX.Element {
  const { t } = useTranslation(['chat', 'common']);
  const { realm } = useAtomValue(currentContextAtom);
  const [currentChannel, setCurrentChannel] = useAtom(currentChannelAtom);
  const currentContract = useAtomValue(currentContractAtom);
  const [showChannelMembers, setShowChannelMembers] = useAtom(showChannelMembersAtom);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleNotificationClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = (): void => {
    setAnchorEl(null);
  };

  const companyInfo = useMemo((): CompanyOfferInfo | BuyingCompanyInfo | undefined => {
    if (!currentContract) {
      return;
    }

    return realm === Business_Profile_Enum.Buyer ? currentContract.supplying_company : currentContract.buying_company;
  }, [currentContract]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        px: { xs: 2, md: 3 },
        pt: { xs: 2, md: 3 },
        pb: 2,
        '& .MuiDivider-root:not(:last-child)': {
          mb: {
            xs: 2,
            sm: 3,
          },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 2,
        }}
      >
        {
          companyInfo && (
            <>
              <Button
                variant="text"
                onClick={() => setCurrentChannel(undefined)}
                sx={{
                  display: {
                    xs: 'inline-flex',
                    sm: 'none',
                  },
                }}
              >
                <ArrowBackIcon />
              </Button>
              <ChannelCompanyImage
                company={{
                  businessProfile: realm === Business_Profile_Enum.Buyer ? Business_Profile_Enum.Supplier : Business_Profile_Enum.Buyer,
                  photo: companyInfo.photos[0]?.photo,
                  brand: 'brand' in companyInfo ? companyInfo.brand : undefined,
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  width: 'calc(100% - 56px)',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  ml: 1,
                }}
              >
                <Typography
                  variant="bodyLarge"
                  component={Link}
                  to={generatePath(RoutePaths.CONTRACT_COMPANY, { companyId: companyInfo.id })}
                  sx={{
                    ...linkStyle,
                  }}
                >
                  {companyInfo.tradeName}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'space-between' }}>
                  <Typography
                    variant="bodyMedium"
                    color="secondary"
                    sx={{ flex: 1, ...ellipsisStyle }}
                  >
                    {t('chat:N_member', { count: currentChannel!.memberCount })}
                  </Typography>
                </Box>
              </Box>
              <Button variant="text" onClick={handleNotificationClick}>
                {currentChannel?.myPushTriggerOption === 'off'
                  ? <NotificationsOffOutlinedIcon />
                  : <NotificationsOutlinedIcon />}
              </Button>
              <ToggleNotificationChannel anchorEl={anchorEl} onClose={handleNotificationClose} />
              <Button variant="text" onClick={() => setShowChannelMembers(!showChannelMembers)}>
                <PeopleAltOutlinedIcon />
              </Button>
              <LeaveChannelButton companyName={companyInfo.tradeName} />
            </>
          )
        }
      </Box>
      <Divider />
      {currentContract?.status === Contract_Status_Enum.Unsigned && currentChannel && (
        <>
          {
            currentContract?.proposer_business_profile
              ? <OfferInfoHeader contract={currentContract} />
              : <NoOfferHeader contract={currentContract} channel={currentChannel} />
          }
          <Divider />
        </>
      )}
    </Box>
  );
}
