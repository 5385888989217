import CloseIcon from '@mui/icons-material/CloseOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import AddIcon from '@mui/icons-material/Add';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, Card, Chip, Grid2, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Sub_Catalog_Packaging_Insert_Input } from 'kheops-graphql';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router';
import { useAtomValue, useSetAtom } from 'jotai';
import { Trans, useTranslation } from 'react-i18next';
import { PageWrapper } from '../../../common/components/PageWrapper';
import { DraftSubCatalog } from './state';
import SubCatalogFormButtons from './SubCatalogFormButtons';
import SubCatalogFormHeader from './SubCatalogFormHeader';
import SubCatalogInfoForm from './SubCatalogInfoForm';
import useSubCatalogFormDefaultValues from './hooks/useSubCatalogFormDefaultValues';
import SubCatalogPackagingsForm from './SubCatalogPackagingsForm';
import useUpsertSubCatalogMutation, { UpsertSubCatalogMutationVariables } from './hooks/useUpsertSubCatalogMutation';
import { currentContextAtom } from '../../../state';
import { RoutePaths } from '../../../routes/AppRoutes';
import { commonSnackbarPropsAtom } from '../../../common/state/state';
import TopBar from '../../../common/components/TopBar';
import BackButton from '../../../common/components/BackButton';
import SubCatalogInfoFormDrawerButton from './SubCatalogInfoFormDrawerButton';
import { useNavBlocker } from '../../../hooks/useNavBlocker';
import { ellipsisStyle } from '../../../common/utils/style.utils';
import SubCatalogPackagingsFormFilters from './SubCatalogPackagingsFormFilters';

export default function SubCatalogForm(): React.JSX.Element {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(['catalogs', 'common']);
  const setCommonSnackbarProps = useSetAtom(commonSnackbarPropsAtom);
  const { companyId } = useAtomValue(currentContextAtom);
  const [searchParams] = useSearchParams();
  const subCatalogId = searchParams.get('subCatalogId');
  const copySubCatalogId = searchParams.get('copySubCatalogId') || undefined;
  const contractId = searchParams.get('contractId') || undefined;
  const { upsertSubCatalogMutation } = useUpsertSubCatalogMutation();
  const isDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const [shouldDisplayPackagingsFormMobile, setShouldDisplayPackagingsFormMobile] = useState(!!(subCatalogId || copySubCatalogId));
  const defaultValues = useSubCatalogFormDefaultValues({
    subCatalogId: subCatalogId || copySubCatalogId,
    contractId,
    copy: !!copySubCatalogId,
  });
  const form = useForm<DraftSubCatalog>({ defaultValues, mode: 'onChange' });
  const {
    handleSubmit,
    reset,
    watch,
    formState: {
      isDirty,
      isValid,
      isSubmitting,
    },
  } = form;
  const [subCatalogFormName, subCatalogFormContracts] = [watch('name'), watch('contracts')];
  const [subCatalogContracts, setCustomPriceListContracts] = useState<string[]>(defaultValues.contracts);
  const [subCatalogName, setCustomPriceListName] = useState<string>(defaultValues.name);

  useEffect(() => {
    if (shouldDisplayPackagingsFormMobile && !subCatalogName) {
      setCustomPriceListContracts(subCatalogFormContracts);
      setCustomPriceListName(subCatalogFormName);
    }
  }, [shouldDisplayPackagingsFormMobile, defaultValues, subCatalogFormName]);

  const blockerMessage = {
    title: t('common:abort_creation'),
    message: <Trans i18nKey="catalogs:confirm_quit_edit_sub_catalog_message" />,
    confirmLabel: t('common:confirm'),
    cancelLabel: t('common:cancel'),
  };
  const shouldBlock = isDirty && !isSubmitting;
  const blockerModal = useNavBlocker(shouldBlock, blockerMessage);

  const onSubmit = useCallback(async (draftSubCatalog: DraftSubCatalog): Promise<void> => {
    let successLabel: string;
    const upsertSubCatalogMutationVariables = {} as UpsertSubCatalogMutationVariables;
    const subCatalogPackagingsPayload = Object.entries(draftSubCatalog.packagingsAvailability).reduce((acc, [packagingSku, availability]) => {
      if (availability.isAvailable) {
        acc.packagingsToInsert.push({
          packaging_sku: parseInt(packagingSku, 10),
          supplier_company_id: companyId,
          sub_catalog_id: subCatalogId || undefined,
        });
      } else {
        acc.packagingsToDelete.push(parseInt(packagingSku, 10));
      }

      return acc;
    }, { packagingsToInsert: [] as Sub_Catalog_Packaging_Insert_Input[], packagingsToDelete: [] as number[] });

    if (!subCatalogId) {
      upsertSubCatalogMutationVariables.upsertSubCatalogVariables = {
        sub_catalog: {
          name: draftSubCatalog.name,
          supplier_company_id: companyId,
          sub_catalog_packagings: {
            data: subCatalogPackagingsPayload.packagingsToInsert,
          },
        },
      };
      upsertSubCatalogMutationVariables.addedContractIds = draftSubCatalog.contracts;

      successLabel = 'catalogs:sub_catalog_created';
    } else {
      upsertSubCatalogMutationVariables.upsertSubCatalogVariables = {
        id: subCatalogId,
        name: draftSubCatalog.name,
        now: new Date().toISOString(),
        subCatalogPackagings: subCatalogPackagingsPayload.packagingsToInsert,
        unavailablePackagingSkus: subCatalogPackagingsPayload.packagingsToDelete,
      };
      upsertSubCatalogMutationVariables.addedContractIds = draftSubCatalog.contracts.filter((value) => !defaultValues.contracts.includes(value));
      upsertSubCatalogMutationVariables.deletedContractIds = defaultValues.contracts.filter((value) => !draftSubCatalog.contracts.includes(value));

      successLabel = 'catalogs:sub_catalog_updated';
    }

    const newSubCatalogId = await upsertSubCatalogMutation(upsertSubCatalogMutationVariables);

    if (location.state?.origin) {
      navigate(
        {
          pathname: location.state.origin,
          search: newSubCatalogId ? `?${createSearchParams({ newSubCatalogId })}` : undefined,
        },
        {
          state: { newSubCatalogId, newSubCatalogName: draftSubCatalog.name },
        },
      );
    } else {
      navigate(RoutePaths.COMPANY_CATALOGS);
    }

    setCommonSnackbarProps({
      label: t(successLabel),
      snackbarProps: {
        open: true,
      },
    });
  }, [defaultValues]);

  useEffect((): void => {
    reset(defaultValues);
  }, [defaultValues]);

  const formComponent = useMemo(() => {
    if (!isDownLg) {
      return (
        <Grid2
          container
          sx={{
            height: 'calc(100vh - 168px)',
          }}
          columnSpacing={2}
        >
          <Grid2 size={{ xs: 0, lg: 4 }} sx={{ display: { xs: 'none', lg: 'flex' } }}>
            <Card sx={{ display: 'flex', flexDirection: 'column', gap: 3, height: '100%' }}>
              <SubCatalogFormHeader isEditing={!!subCatalogId} />
              <SubCatalogInfoForm />
              <SubCatalogFormButtons />
            </Card>
          </Grid2>
          <Grid2 size={{ xs: 12, lg: 8 }}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100% + 24px)',
                pb: {
                  lg: 0,
                },
                p: 0,
                gap: 3,
                backgroundColor: 'transparent',
                borderRadius: 0,
              }}
            >
              <SubCatalogPackagingsFormFilters />
              <SubCatalogPackagingsForm />
            </Card>
          </Grid2>
        </Grid2>
      );
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 174px)',
          mt: 8,
        }}
      >
        {shouldDisplayPackagingsFormMobile
          ? (
            <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
              <SubCatalogPackagingsFormFilters
                sx={{
                  mb: 2,
                }}
              />
              <SubCatalogPackagingsForm />
              <SubCatalogFormButtons
                parentBoxSx={{
                  position: 'fixed',
                  bottom: 0,
                  width: 'calc(100vw - 32px)',
                  left: 0,
                  mx: 2,
                }}
                fullWidth
                shouldDisplayCancelButton={false}
              />
            </Box>
          )
          : (
            <Box sx={{ display: 'flex', flexDirection: 'column', px: 2 }}>
              <SubCatalogInfoForm />
              <Button
                disabled={!isValid}
                variant="contained"
                onClick={() => setShouldDisplayPackagingsFormMobile(true)}
                startIcon={<AddIcon />}
                sx={{
                  mt: 4,
                }}
              >
                {t('catalogs:add_packagings')}
              </Button>
            </Box>
          )}
      </Box>
    );
  }, [isDownLg, shouldDisplayPackagingsFormMobile, watch('name'), isValid]);

  return (
    <PageWrapper sx={{ mb: 0 }}>
      <FormProvider {...form}>
        {blockerModal}
        <TopBar
          sx={{
            display: {
              xs: 'flex',
              lg: 'none',
            },
            flexDirection: 'column',
            alignItems: 'start',
            p: 1.5,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              my: 'auto',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <BackButton
                to={RoutePaths.COMPANY_CATALOGS}
                shouldCheckHistory={false}
                Icon={CloseIcon}
                compact
              />
              {shouldDisplayPackagingsFormMobile
                ? (
                  <>
                    <Typography sx={{ maxWidth: 'calc(100vw - 228px)', ...ellipsisStyle }} variant="titleLarge">
                      {subCatalogName}
                    </Typography>
                    <Chip
                      variant="tag"
                      label={t('catalogs:N_store_count', { count: subCatalogContracts.length })}
                      icon={<StoreOutlinedIcon />}
                    />
                  </>
                )
                : (
                  <Typography variant="titleLarge">
                    {t('catalogs:create_a_sub_catalog')}
                  </Typography>
                )}
            </Box>
            {shouldDisplayPackagingsFormMobile && (
              <SubCatalogInfoFormDrawerButton
                name={subCatalogName}
                contracts={subCatalogContracts}
                setContracts={setCustomPriceListContracts}
                setName={setCustomPriceListName}
              />
            )}
          </Box>
        </TopBar>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          {formComponent}
        </Box>
      </FormProvider>
    </PageWrapper>
  );
}
