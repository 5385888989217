/* eslint-disable @typescript-eslint/no-explicit-any */
import { avatarClasses, buttonClasses, checkboxClasses, chipClasses, circularProgressClasses, formControlLabelClasses, inputAdornmentClasses, outlinedInputClasses, radioClasses, svgIconClasses, switchClasses, tabsClasses, ThemeProvider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import { frFR } from '@mui/material/locale';
import { alpha, createTheme, darken, ThemeOptions } from '@mui/material/styles';
import React from 'react';
import { ErrorPalette, NeutralPalette, NeutralVariantPalette, PrimaryPalette, SecondaryPalette, SuccessPalette, TertiaryPalette, WarningPalette } from '../theme/palettes';
import { getStateLayerColor, StateLayer } from '../theme/color.utils';

declare module '@mui/material/styles' {
  interface Palette {
    onPrimary: Palette['primary'];
    primaryContainer: Palette['primary'];
    onPrimaryContainer: Palette['primary'];
    onSecondary: Palette['primary'];
    secondaryContainer: Palette['primary'];
    onSecondaryContainer: Palette['primary'];
    tertiary: Palette['primary'];
    onTertiary: Palette['primary'];
    tertiaryContainer: Palette['primary'];
    onTertiaryContainer: Palette['primary'];
    onError: Palette['primary'];
    errorContainer: Palette['primary'];
    onErrorContainer: Palette['primary'];
    onSuccess: Palette['primary'];
    successContainer: Palette['primary'];
    onSuccessContainer: Palette['primary'];
    onWarning: Palette['primary'];
    warningContainer: Palette['primary'];
    onWarningContainer: Palette['primary'];
    surface: Palette['primary'];
    surfaceDim: Palette['primary'];
    surfaceBright: Palette['primary'];
    surfaceContainerLowest: Palette['primary'];
    surfaceContainerLow: Palette['primary'];
    surfaceContainer: Palette['primary'];
    surfaceContainerHigh: Palette['primary'];
    surfaceContainerHighest: Palette['primary'];
    onSurface: Palette['primary'];
    onSurfaceVariant: Palette['primary'];
    outline: Palette['primary'];
    outlineVariant: Palette['primary'];
    inverseSurface: Palette['primary'];
    inverseOnSurface: Palette['primary'];
    inversePrimary: Palette['primary'];
    scrim: Palette['primary'];
    shadow: Palette['primary'];
    marketing1: Palette['primary'];
    marketing2: Palette['primary'];
    marketing3: Palette['primary'];
    ghost: Palette['primary'];
    greys: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    marketing2?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    tonal: true;
  }
}

declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    surface: true;
    tertiary: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    displayLarge: true,
    displayMedium: true,
    displaySmall: true,
    headlineLarge: true;
    headlineMedium: true;
    headlineSmall: true;
    titleLarge: true;
    titleMedium: true;
    titleSmall: true;
    labelLarge: true;
    labelMedium: true;
    labelSmall: true;
    bodyLarge: true;
    bodyMedium: true;
    bodySmall: true;
  }
}

declare module '@mui/material/Switch' {
  interface SwitchPropsSizeOverrides {
    large: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    tag: true;
    assistive: true;
    filter: true;
  }
  interface ChipPropsColorOverrides {
    tertiaryContainer: true;
    primaryContainer: true;
    surfaceContainer: true;
    surfaceContainerHigh: true;
    surfaceContainerHighest: true;
  }
}

declare module '@mui/material/LinearProgress' {
  interface LinearProgressPropsColorOverrides {
    accent: true;
  }
}

declare module '@mui/material/Avatar' {
  interface AvatarOwnProps {
    size: number;
  }
}
declare module '@mui/material/Tooltip' {
  interface TooltipProps {
    variant?: 'plain' | 'rich';
  }
}

declare module '@mui/material/DialogTitle' {
  interface DialogTitleOwnProps {
    variant?: 'image';
  }
}

export type KheopsButtonVariant = 'text' | 'outlined' | 'contained' | 'tonal';
export type KheopsTypographyVariant =
  'displayLarge'| 'displayMedium' | 'displaySmall'
  | 'headlineLarge' | 'headlineMedium' | 'headlineSmall'
  | 'titleLarge' | 'titleMedium' | 'titleSmall'
  | 'labelLarge' | 'labelMedium' | 'labelSmall'
  | 'bodyLarge' | 'bodyMedium' | 'bodySmall';

interface CustomThemeProviderProps {
  children: React.ReactNode;
}
export default function CustomThemeProvider({ children }: CustomThemeProviderProps): React.JSX.Element {
  const clearface = "'Clearface', sans-serif";
  const ambit = "'Ambit', sans-serif";
  const colors = {
    black: '#102D2D',
    grey: '#535D5F',
    darkSnow: '#D6D6D6',
    mediumSnow: '#ECEDEE',
    snow: '#F6F7F8',
    primary: '#00B86E',
    gold: '#FFD28F',
    goldLight: '#FEE27A',
    error: '#E7444F',
    schrimp: '#FFC4D4',
    schrimpLight: '#FFDECC',
    tomato: '#FF7051',
    success: '#00B649',
    cream: '#FCF2E1',
  };
  const coreTheme = {
    palette: {
      common: { black: colors.black },
      text: {
        primary: NeutralPalette['10'],
        secondary: NeutralVariantPalette['30'],
        disabled: colors.darkSnow,
      },
      divider: NeutralVariantPalette['80'],
      primary: {
        main: PrimaryPalette['40'],
        contrastText: PrimaryPalette['100'],
      },
      onPrimary: {
        main: PrimaryPalette['100'],
        contrastText: PrimaryPalette['40'],
      },
      primaryContainer: {
        main: PrimaryPalette['90'],
        contrastText: PrimaryPalette['10'],
      },
      onPrimaryContainer: {
        main: PrimaryPalette['10'],
        contrastText: PrimaryPalette['90'],
      },
      secondary: {
        main: SecondaryPalette['40'],
        contrastText: SecondaryPalette['100'],
      },
      onSecondary: {
        main: SecondaryPalette['100'],
        contrastText: SecondaryPalette['40'],
      },
      secondaryContainer: {
        main: SecondaryPalette['90'],
        contrastText: SecondaryPalette['10'],
      },
      onSecondaryContainer: {
        main: SecondaryPalette['10'],
        contrastText: SecondaryPalette['90'],
      },
      tertiary: {
        main: TertiaryPalette['40'],
        contrastText: TertiaryPalette['100'],
      },
      onTertiary: {
        main: TertiaryPalette['100'],
        contrastText: TertiaryPalette['40'],
      },
      tertiaryContainer: {
        main: TertiaryPalette['90'],
        contrastText: TertiaryPalette['10'],
      },
      onTertiaryContainer: {
        main: TertiaryPalette['10'],
        contrastText: TertiaryPalette['90'],
      },
      error: {
        main: ErrorPalette['40'],
        contrastText: ErrorPalette['100'],
      },
      onError: {
        main: ErrorPalette['100'],
        contrastText: ErrorPalette['40'],
      },
      errorContainer: {
        main: ErrorPalette['90'],
        contrastText: ErrorPalette['10'],
      },
      onErrorContainer: {
        main: ErrorPalette['10'],
        contrastText: ErrorPalette['90'],
      },
      success: {
        main: SuccessPalette['40'],
        contrastText: SuccessPalette['100'],
      },
      onSuccess: {
        main: SuccessPalette['100'],
        contrastText: SuccessPalette['40'],
      },
      successContainer: {
        main: SuccessPalette['90'],
        contrastText: SuccessPalette['10'],
      },
      onSuccessContainer: {
        main: SuccessPalette['10'],
        contrastText: SuccessPalette['90'],
      },
      warning: {
        main: WarningPalette['70'],
        contrastText: WarningPalette['100'],
      },
      onWarning: {
        main: WarningPalette['100'],
        contrastText: WarningPalette['40'],
      },
      warningContainer: {
        main: WarningPalette['90'],
        contrastText: WarningPalette['10'],
      },
      onWarningContainer: {
        main: WarningPalette['10'],
        contrastText: WarningPalette['90'],
      },
      surfaceDim: {
        main: NeutralPalette['87'],
      },
      surface: {
        main: NeutralPalette['98'],
      },
      surfaceBright: {
        main: NeutralPalette['98'],
      },
      surfaceContainerLowest: {
        main: NeutralPalette['100'],
      },
      surfaceContainerLow: {
        main: NeutralPalette['96'],
      },
      surfaceContainer: {
        main: NeutralPalette['94'],
      },
      surfaceContainerHigh: {
        main: NeutralPalette['92'],
      },
      surfaceContainerHighest: {
        main: NeutralPalette['90'],
      },
      onSurface: {
        main: NeutralPalette['10'],
      },
      onSurfaceVariant: {
        main: NeutralVariantPalette['30'],
      },
      outline: {
        main: NeutralVariantPalette['50'],
      },
      outlineVariant: {
        main: NeutralVariantPalette['80'],
      },
      inverseSurface: {
        main: NeutralPalette['20'],
      },
      inverseOnSurface: {
        main: NeutralPalette['95'],
      },
      inversePrimary: {
        main: PrimaryPalette['80'],
      },
      scrim: {
        main: NeutralPalette['0'],
      },
      shadow: {
        main: NeutralPalette['0'],
      },
      marketing1: {
        main: colors.gold,
        dark: '#F5CE94',
        contrastText: colors.black,
      },
      marketing2: {
        main: colors.schrimpLight,
        dark: '#F5D4C2',
        contrastText: colors.black,
      },
      marketing3: {
        main: colors.schrimp,
        contrastText: colors.black,
      },
      accent: {
        main: colors.tomato,
        contrastText: colors.black,
      },
      discount: {
        main: colors.goldLight,
        contrastText: colors.black,
      },
      layout: {
        main: colors.cream,
        contrastText: colors.black,
      },
      ghost: {
        main: '#ffffff',
        dark: colors.snow,
        contrastText: colors.black,
      },
      greys: {
        main: colors.grey,
        primary: colors.snow,
        light: colors.mediumSnow,
        dark: colors.darkSnow,
        contrastText: colors.black,
      },
    },
  } as ThemeOptions;

  const typographyVariants = {
    fontFamily: ambit,
    displayLarge: {
      fontFamily: clearface,
      fontSize: '2rem',
      lineHeight: '2.5rem',
      fontWeight: 750,
    },
    displayMedium: {
      fontFamily: clearface,
      fontSize: '1.75rem',
      lineHeight: '2.25rem',
      fontWeight: 750,
    },
    displaySmall: {
      fontFamily: clearface,
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: 750,
    },
    headlineLarge: {
      fontFamily: ambit,
      fontSize: '2rem',
      lineHeight: '2.5rem',
      letterSpacing: 0,
      fontWeight: 400,
    },
    headlineMedium: {
      fontFamily: ambit,
      fontSize: '1.75rem',
      lineHeight: '2.25rem',
      letterSpacing: 0,
      fontWeight: 400,
    },
    headlineSmall: {
      fontFamily: ambit,
      fontSize: '1.5rem',
      lineHeight: '2rem',
      letterSpacing: 0,
      fontWeight: 400,
    },
    titleLarge: {
      fontFamily: ambit,
      fontSize: '1.375rem',
      lineHeight: '1.75rem',
      letterSpacing: 0,
      fontWeight: 400,
    },
    titleMedium: {
      fontFamily: ambit,
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      letterSpacing: 0.15,
    },
    titleSmall: {
      fontFamily: ambit,
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      letterSpacing: 0.1,
    },
    labelLarge: {
      fontFamily: ambit,
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      letterSpacing: 0.1,
    },
    labelMedium: {
      fontFamily: ambit,
      fontWeight: 600,
      fontSize: '0.75rem',
      lineHeight: '1rem',
      letterSpacing: 0.5,
    },
    labelSmall: {
      fontFamily: ambit,
      fontWeight: 600,
      fontSize: '0.6875rem',
      lineHeight: '1rem',
      letterSpacing: 0.5,
    },
    bodyLarge: {
      fontFamily: ambit,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      letterSpacing: 0.5,
      fontWeight: 400,
    },
    bodyMedium: {
      fontFamily: ambit,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      letterSpacing: 0.25,
      fontWeight: 400,
    },
    bodySmall: {
      fontFamily: ambit,
      fontSize: '0.75rem',
      lineHeight: '1rem',
      letterSpacing: 0.4,
      fontWeight: 400,
    },
  };

  const componentsTheme = {
    typography: typographyVariants,
    palette: {
      background: {
        default: colors.cream,
      },
    },
    components: {
      MuiDialog: {
        styleOverrides: {
          paperFullScreen: {
            borderRadius: 0,
          },
          paper: {
            borderRadius: '24px',
            padding: '0px',
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: '0px, 24px',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            textAlign: 'start',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            padding: '24px',
            '& p:first-child, & > div p': typographyVariants.headlineSmall,
            '& > div': {
              alignItems: 'center',
              display: 'flex',
            },
            '& > div svg': {
              marginRight: '12px',
            },
            '& > :nth-child(2)': {
              color: coreTheme.palette?.text?.secondary,
              ...typographyVariants.bodyMedium,
            },
          },
        },
        variants: [
          {
            props: { variant: 'image' },
            style: {
              padding: '0px',
            },
          },
        ],
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '24px',
            gap: '8px',
            /*
              The weird rule bellow is autogenerated by MUI.
              I believe its main role is to add a left margin for all elements except the last...
              We want remove it so that the gap works properly in row and column flex directions
            */
            '& > :not(style) ~ :not(style)': {
              marginLeft: 0,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            height: '40px',
            borderRadius: '100px',
            flexShrink: 0,
            padding: '0 24px',
            boxShadow: 'none',
            textTransform: 'none',
            whiteSpace: 'nowrap',
            ...typographyVariants.labelLarge,
            [`&:has(>.${buttonClasses.icon})`]: {
              paddingLeft: '16px',
            },
            '&:has(>svg)': {
              padding: '8px',
              minWidth: 0,
            },
            [`& .${buttonClasses.startIcon}`]: {
              marginLeft: 0,
              '& > .MuiSvgIcon-root': {
                height: '18px',
                width: '18px',
              },
            },
          },
          contained: {
            '&:hover': {
              backgroundColor: getStateLayerColor(StateLayer.Layer8, (coreTheme.palette as any).primary.main, (coreTheme.palette as any).onPrimary.main),
              boxShadow: 'none',
            },
            '&:focus': {
              backgroundColor: getStateLayerColor(StateLayer.Layer12, (coreTheme.palette as any).primary.main, (coreTheme.palette as any).onPrimary.main),
              boxShadow: 'none',
            },
            '&:focus:not(:focus-visible)': {
              backgroundColor: (coreTheme.palette as any).primary.main,
            },
            '&:active': {
              backgroundColor: getStateLayerColor(StateLayer.Layer16, (coreTheme.palette as any).primary.main, (coreTheme.palette as any).onPrimary.main),
              boxShadow: 'none',
            },
            '&:disabled': {
              backgroundColor: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer12),
              boxShadow: 'none',
              [`&:not(.${buttonClasses.loadingPositionCenter})`]: {
                color: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer38),
              },
            },
          },
          outlined: {
            '&:hover': {
              backgroundColor: alpha((coreTheme.palette as any).primary.main, StateLayer.Layer8),
              borderColor: getStateLayerColor(StateLayer.Layer8, (coreTheme.palette as any).outline.main, (coreTheme.palette as any).primary.main),
            },
            '&:focus': {
              backgroundColor: alpha((coreTheme.palette as any).primary.main, StateLayer.Layer12),
              borderColor: getStateLayerColor(StateLayer.Layer12, (coreTheme.palette as any).primary.main, (coreTheme.palette as any).primary.main),
            },
            '&:focus:not(:focus-visible)': {
              backgroundColor: 'transparent',
            },
            '&:active': {
              backgroundColor: alpha((coreTheme.palette as any).primary.main, StateLayer.Layer16),
              borderColor: getStateLayerColor(StateLayer.Layer16, (coreTheme.palette as any).outline.main, (coreTheme.palette as any).primary.main),
            },
            '&:disabled': {
              borderColor: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer12),
              color: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer38),
            },
          },
          text: {
            padding: '0 12px',
            [`&:has(>.${buttonClasses.icon})`]: {
              paddingLeft: '12px',
              paddingRight: '16px',
            },
            [`&:has(>svg, >.${buttonClasses.loading}>svg)`]: {
              color: (coreTheme.palette as any).onSurfaceVariant.main,
              '&:hover': {
                backgroundColor: alpha((coreTheme.palette as any).onSurfaceVariant.main, StateLayer.Layer8),
              },
              '&:focus': {
                backgroundColor: alpha((coreTheme.palette as any).onSurfaceVariant.main, StateLayer.Layer12),
              },
              '&:active': {
                backgroundColor: alpha((coreTheme.palette as any).onSurfaceVariant.main, StateLayer.Layer16),
              },
              '&:disabled': {
                color: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer38),
              },
            },
            '&:hover': {
              backgroundColor: alpha((coreTheme.palette as any).primary.main, StateLayer.Layer8),
            },
            '&:focus': {
              backgroundColor: alpha((coreTheme.palette as any).primary.main, StateLayer.Layer12),
            },
            '&:active': {
              backgroundColor: alpha((coreTheme.palette as any).primary.main, StateLayer.Layer16),
            },
            '&:disabled': {
              color: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer38),
            },
          },
          tonal: {
            backgroundColor: (coreTheme.palette as any).secondaryContainer.main,
            color: (coreTheme.palette as any).onSecondaryContainer.main,
            '&:hover': {
              backgroundColor: getStateLayerColor(StateLayer.Layer8, (coreTheme.palette as any).secondaryContainer.main, (coreTheme.palette as any).onSecondaryContainer.main),
            },
            '&:focus': {
              backgroundColor: getStateLayerColor(StateLayer.Layer12, (coreTheme.palette as any).secondaryContainer.main, (coreTheme.palette as any).onSecondaryContainer.main),
            },
            '&:focus:not(:focus-visible)': {
              backgroundColor: (coreTheme.palette as any).secondaryContainer.main,
            },
            '&:active': {
              backgroundColor: getStateLayerColor(StateLayer.Layer16, (coreTheme.palette as any).secondaryContainer.main, (coreTheme.palette as any).onSecondaryContainer.main),
            },
            '&:disabled': {
              backgroundColor: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer12),
              color: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer38),
            },
          },
        },
        variants: [
          {
            props: { size: 'small' },
            style: {
              width: 32,
              height: 32,
              '& .MuiSvgIcon-root': {
                fontSize: 18,
              },
            },
          },
        ],
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: '8px',
            '&:hover': {
              backgroundColor: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer8),
            },
            '&:focus': {
              backgroundColor: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer12),
            },
            '&:active': {
              backgroundColor: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer16),
            },
            [`&.${checkboxClasses.checked}, &.${checkboxClasses.indeterminate}`]: {
              '&:hover': {
                backgroundColor: alpha((coreTheme.palette as any).primary.main, StateLayer.Layer8),
              },
              '&:focus': {
                backgroundColor: alpha((coreTheme.palette as any).primary.main, StateLayer.Layer12),
              },
              '&:active': {
                backgroundColor: alpha((coreTheme.palette as any).primary.main, StateLayer.Layer16),
              },
            },
            '&.Mui-disabled': {
              color: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer38),
            },
          },
        },
      },
      MuiChip: {
        defaultProps: {
          deleteIcon: <CloseIcon />,
        },
        styleOverrides: {
          root: {
            ...typographyVariants.labelLarge,
            height: '32px',
            padding: '0 16px',
            gap: '8px',
            color: (coreTheme.palette as any).onSurfaceVariant.main,
            backgroundColor: 'unset',
            border: `1px solid ${(coreTheme.palette as any).outline.main}`,
            borderRadius: '8px',
            [`& .${chipClasses.label}`]: {
              padding: 0,
            },
            [`& .${chipClasses.icon}, & .${chipClasses.deleteIcon}`]: {
              color: (coreTheme.palette as any).primary.main,
              height: '18px',
              width: '18px',
            },
            [`& .${circularProgressClasses.root}`]: {
              color: (coreTheme.palette as any).primary.main,
              height: '18px!important',
              width: '18px!important',
            },
            [`& .${chipClasses.icon}, & .${circularProgressClasses.root}, & .${chipClasses.deleteIcon}, & .${chipClasses.avatar}`]: {
              margin: 0,
              flexShrink: 0,
            },
            [`& .${chipClasses.deleteIcon}`]: {
              color: (coreTheme.palette as any).onSurface.main,
            },
            [`& .${chipClasses.avatar}`]: {
              borderRadius: '50%',
            },
            '& .Kheops-brand': {
              width: '32px',
              height: '32px',
              '& img': {
                width: '20px',
                height: '20px',
              },
            },
            [`&:has(.${chipClasses.icon}), &:has(img)`]: {
              paddingLeft: '8px',
            },
            [`&:has(.${chipClasses.avatar}), &:has(.${avatarClasses.root}), &:has(.Kheops-brand)`]: {
              paddingLeft: '4px',
              borderRadius: '100px',
            },
            [`&:has(.${chipClasses.avatar}), &:has(.${avatarClasses.root})`]: {
              gap: '8px',
            },
            '&:has(.Kheops-brand)': {
              gap: '4px',
            },
            [`&:has(.${chipClasses.deleteIcon})`]: {
              paddingRight: '8px',
            },
            'a&': {
              cursor: 'pointer',
            },
            '&.Mui-disabled': {
              opacity: 1,
              color: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer38),
              borderColor: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer12),
              [`& .${chipClasses.icon}, & .${circularProgressClasses.root}`]: {
                color: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer38),
              },
            },
            '&.Kheops-selected': {
              border: '1px solid transparent',
              color: (coreTheme.palette as any).onSecondaryContainer.main,
              backgroundColor: (coreTheme.palette as any).secondaryContainer.main,
              '&:hover': {
                backgroundColor: getStateLayerColor(StateLayer.Layer8, (coreTheme.palette as any).secondaryContainer.main, (coreTheme.palette as any).onSecondaryContainer.main),
              },
              '&:focus-visible': {
                backgroundColor: getStateLayerColor(StateLayer.Layer12, (coreTheme.palette as any).secondaryContainer.main, (coreTheme.palette as any).onSecondaryContainer.main),
              },
              '&:active': {
                backgroundColor: getStateLayerColor(StateLayer.Layer16, (coreTheme.palette as any).secondaryContainer.main, (coreTheme.palette as any).onSecondaryContainer.main),
              },
            },
          },
        },
        variants: [
          {
            props: { variant: 'tag' },
            style: {
              ...typographyVariants.bodySmall,
              height: '16px',
              padding: '4px 12px',
              boxSizing: 'content-box',
              gap: '4px',
              backgroundColor: (coreTheme.palette as any).surfaceContainerLow.main,
              border: 'none',
              borderRadius: '100px',
              color: (coreTheme.palette as any).onSurface.main,
              [`& .${chipClasses.label}`]: {
                padding: 0,
              },
              [`& .${chipClasses.icon}`]: {
                margin: 0,
                height: '16px',
                width: '16px',
                flexShrink: 0,
                color: (coreTheme.palette as any).onSurface.main,
              },
              [`& .${chipClasses.avatar}`]: {
                height: '18px',
                width: '18px',
              },
              '& .Kheops-brand': {
                width: '18px',
                height: '18px',
                '& img': {
                  width: '10px',
                  height: '10px',
                },
              },
              '&.Mui-disabled': {
                opacity: 1,
                color: (coreTheme.palette as any).onSurface.main,
                backgroundColor: (coreTheme.palette as any).surfaceContainerLow.main,
                [`& .${chipClasses.icon}`]: {
                  color: (coreTheme.palette as any).onSurface.main,
                },
              },
            },
          },
          {
            props: { variant: 'assistive' },
            style: {
              '&:hover': {
                backgroundColor: alpha((coreTheme.palette as any).onSurfaceVariant.main, StateLayer.Layer8),
              },
              '&:active': {
                backgroundColor: alpha((coreTheme.palette as any).onSurfaceVariant.main, StateLayer.Layer12),
              },
              '&:focus-visible': {
                backgroundColor: alpha((coreTheme.palette as any).onSurfaceVariant.main, StateLayer.Layer12),
                borderColor: (coreTheme.palette as any).onSurface.main,
              },
            },
          },
          {
            props: { variant: 'filter' },
            style: {
              '&.Kheops-selected': {
                paddingLeft: '8px',
                gap: '8px',
                '&:before': {
                  fontFamily: 'Material Icons',
                  '-webkit-font-smoothing': 'antialiased',
                  '-moz-osx-font-smoothing': 'grayscale',
                  'text-rendering': 'optimizeLegibility',
                  'font-feature-settings': 'liga',
                  content: "'checked'",
                  width: '18px',
                  height: '18px',
                },
                [`&:has(.${chipClasses.icon}, img) > *:first-child`]: {
                  display: 'none',
                },
              },
              '&:hover': {
                backgroundColor: alpha((coreTheme.palette as any).onSurfaceVariant.main, StateLayer.Layer8),
              },
              '&:active': {
                backgroundColor: alpha((coreTheme.palette as any).onSurfaceVariant.main, StateLayer.Layer12),
              },
              '&:focus-visible': {
                backgroundColor: alpha((coreTheme.palette as any).onSurfaceVariant.main, StateLayer.Layer12),
                borderColor: (coreTheme.palette as any).onSurface.main,
              },
            },
          },
          {
            props: { color: 'primary' },
            style: {
              color: (coreTheme.palette as any).onPrimary.main,
              backgroundColor: (coreTheme.palette as any).primary.main,
              [`& .${chipClasses.icon}`]: {
                color: (coreTheme.palette as any).onPrimary.main,
              },
            },
          },
          {
            props: { color: 'error' },
            style: {
              color: (coreTheme.palette as any).onErrorContainer.main,
              backgroundColor: (coreTheme.palette as any).errorContainer.main,
              [`& .${chipClasses.icon}`]: {
                color: (coreTheme.palette as any).onErrorContainer.main,
              },
            },
          },
          {
            props: { color: 'success' },
            style: {
              color: (coreTheme.palette as any).onSuccessContainer.main,
              backgroundColor: (coreTheme.palette as any).successContainer.main,
              [`& .${chipClasses.icon}`]: {
                color: (coreTheme.palette as any).onSuccessContainer.main,
              },
            },
          },
          {
            props: { color: 'tertiaryContainer' },
            style: {
              color: (coreTheme.palette as any).onTertiaryContainer.main,
              backgroundColor: (coreTheme.palette as any).tertiaryContainer.main,
              [`& .${chipClasses.icon}`]: {
                color: (coreTheme.palette as any).onTertiaryContainer.main,
              },
            },
          },
          {
            props: { color: 'primaryContainer' },
            style: {
              color: (coreTheme.palette as any).onPrimaryContainer.main,
              backgroundColor: (coreTheme.palette as any).primaryContainer.main,
              [`& .${chipClasses.icon}`]: {
                color: (coreTheme.palette as any).onPrimaryContainer.main,
              },
            },
          },
          {
            props: { color: 'surfaceContainerHighest' },
            style: {
              color: (coreTheme.palette as any).onSurface.main,
              backgroundColor: (coreTheme.palette as any).surfaceContainerHighest.main,
            },
          },
          {
            props: { color: 'surfaceContainer' },
            style: {
              color: (coreTheme.palette as any).onSurface.main,
              backgroundColor: (coreTheme.palette as any).surfaceContainer.main,
            },
          },
          {
            props: { color: 'surfaceContainerHigh' },
            style: {
              color: (coreTheme.palette as any).onSurface.main,
              backgroundColor: (coreTheme.palette as any).surfaceContainerHigh.main,
            },
          },
        ],
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            [`&:has(> .${checkboxClasses.root}, > .${radioClasses.root}, > .${switchClasses.root}) .${formControlLabelClasses.label}`]: {
              ...typographyVariants.bodyMedium,
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            padding: '8px',
            '&:hover': {
              backgroundColor: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer8),
            },
            '&:focus': {
              backgroundColor: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer12),
            },
            '&:active': {
              backgroundColor: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer16),
            },
            [`&.${checkboxClasses.checked}, &.${checkboxClasses.indeterminate}`]: {
              '&:hover': {
                backgroundColor: alpha((coreTheme.palette as any).primary.main, StateLayer.Layer8),
              },
              '&:focus': {
                backgroundColor: alpha((coreTheme.palette as any).primary.main, StateLayer.Layer12),
              },
              '&:active': {
                backgroundColor: alpha((coreTheme.palette as any).primary.main, StateLayer.Layer16),
              },
            },
            '&.Mui-disabled': {
              color: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer38),
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: '26px',
            height: '16px',
            marginRight: '4px',
            padding: 0,
            display: 'flex',
            overflow: 'visible',
            '& .MuiSwitch-switchBase': {
              padding: '2px',
              color: (coreTheme.palette as any).outline.main,
              '&.Mui-checked': {
                transform: 'translateX(10px)',
                color: '#fff',
                [`& + .${switchClasses.track}`]: {
                  opacity: 1,
                  border: 'none',
                },
                [`& .${switchClasses.thumb}`]: {
                  width: '12px',
                  height: '12px',
                  margin: 0,
                },
              },
              '&.Mui-disabled': {
                [`& + .${switchClasses.track}`]: {
                  backgroundColor: alpha((coreTheme.palette as any).onSurfaceVariant.main, StateLayer.Layer12),
                  borderColor: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer12),
                },
                [`& .${switchClasses.thumb}`]: {
                  color: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer38),
                },
                '&.Mui-checked': {
                  [`& + .${switchClasses.track}`]: {
                    backgroundColor: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer12),
                  },
                  [`& .${switchClasses.thumb}`]: {
                    color: (coreTheme.palette as any).surface.main,
                  },
                },
              },
              '&:hover': {
                backgroundColor: 'unset',
                [`&.Mui-checked .${switchClasses.thumb}`]: {
                  color: (coreTheme.palette as any).primaryContainer.main,
                  '&::after': {
                    backgroundColor: alpha((coreTheme.palette as any).primary.main, StateLayer.Layer8),
                  },
                },
                [`& .${switchClasses.thumb}`]: {
                  color: (coreTheme.palette as any).onSurfaceVariant.main,
                  '&::after': {
                    backgroundColor: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer8),
                  },
                },
              },
              '&:focus, &:active': {
                [`&.Mui-checked .${switchClasses.thumb}`]: {
                  '&::after': {
                    backgroundColor: alpha((coreTheme.palette as any).primary.main, StateLayer.Layer12),
                  },
                },
                [`& .${switchClasses.thumb}`]: {
                  '&::after': {
                    backgroundColor: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer12),
                  },
                },
              },
            },
            [`& .${switchClasses.thumb}`]: {
              boxShadow: 'none',
              margin: '2px',
              width: '8px',
              height: '8px',
              borderRadius: '6px',
              '&::after': {
                content: "''",
                borderRadius: '100px',
                padding: '2px',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: '-2px',
                left: '-2px',
              },
            },
            [`& .${switchClasses.track}`]: {
              borderRadius: 16 / 2,
              border: `1px solid ${(coreTheme.palette as any).outline.main}`,
              opacity: 1,
              backgroundColor: (coreTheme.palette as any).surfaceContainerHighest.main,
              boxSizing: 'border-box',
            },
          },
        },
        variants: [
          {
            props: { size: 'large' },
            style: {
              width: '52px',
              height: '32px',
              '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(20px)',
                [`& .${switchClasses.thumb}`]: {
                  width: '24px',
                  height: '24px',
                  margin: '2px',
                },
              },
              [`& .${switchClasses.thumb}`]: {
                margin: '6px',
                width: '16px',
                height: '16px',
                borderRadius: '24px',
                '&::after': {
                  content: "''",
                  borderRadius: '100px',
                  padding: '4px',
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: '-4px',
                  left: '-4px',
                },
              },
              [`& .${switchClasses.track}`]: {
                borderRadius: 32 / 2,
                border: `1px solid ${(coreTheme.palette as any).outline.main}`,
                opacity: 1,
                backgroundColor: (coreTheme.palette as any).surfaceContainerHighest.main,
                boxSizing: 'border-box',
              },
            },
          },
        ],
      },
      MuiFab: {
        styleOverrides: {
          root: {
            boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30)',
            '&:hover': {
              boxShadow: '0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px 0px rgba(0, 0, 0, 0.30)',
            },
            '&:focused': {
              boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30)',
            },
          },
        },
        variants: [
          {
            props: { size: 'large' },
            style: {
              width: '96px',
              height: '96px',
              borderRadius: '28px',
              [`& .${svgIconClasses.root}`]: {
                width: '27px',
                height: '27px',
              },
            },
          },
          {
            props: { size: 'medium' },
            style: {
              width: '56px',
              height: '56px',
              borderRadius: '16px',
              [`& .${svgIconClasses.root}`]: {
                width: '24px',
                height: '24px',
              },
            },
          },
          {
            props: { size: 'small' },
            style: {
              width: '40px',
              height: '40px',
              borderRadius: '12px',
              [`& .${svgIconClasses.root}`]: {
                width: '24px',
                height: '24px',
              },
            },
          },
          {
            props: { color: 'surface' },
            style: {
              [`& .${svgIconClasses.root}`]: {
                color: (coreTheme.palette as any).primary.main,
              },
              backgroundColor: (coreTheme.palette as any).surfaceContainerHigh.main,
              '&:hover': {
                backgroundColor: getStateLayerColor(StateLayer.Layer8, (coreTheme.palette as any).surfaceContainerHigh.main, (coreTheme.palette as any).primary.main),
              },
              '&:focused': {
                backgroundColor: getStateLayerColor(StateLayer.Layer12, (coreTheme.palette as any).surfaceContainerHigh.main, (coreTheme.palette as any).primary.main),
              },
            },
          },
          {
            props: { color: 'primary' },
            style: {
              [`& .${svgIconClasses.root}`]: {
                color: (coreTheme.palette as any).onPrimaryContainer.main,
              },
              backgroundColor: (coreTheme.palette as any).primaryContainer.main,
              '&:hover': {
                backgroundColor: getStateLayerColor(StateLayer.Layer8, (coreTheme.palette as any).primaryContainer.main, (coreTheme.palette as any).onPrimaryContainer.main),
              },
              '&:focused': {
                backgroundColor: getStateLayerColor(StateLayer.Layer12, (coreTheme.palette as any).primaryContainer.main, (coreTheme.palette as any).onPrimaryContainer.main),
              },
            },
          },
          {
            props: { color: 'secondary' },
            style: {
              [`& .${svgIconClasses.root}`]: {
                color: (coreTheme.palette as any).onSecondaryContainer.main,
              },
              backgroundColor: (coreTheme.palette as any).secondaryContainer.main,
              '&:hover': {
                backgroundColor: getStateLayerColor(StateLayer.Layer8, (coreTheme.palette as any).secondaryContainer.main, (coreTheme.palette as any).onSecondaryContainer.main),
              },
              '&:focused': {
                backgroundColor: getStateLayerColor(StateLayer.Layer12, (coreTheme.palette as any).secondaryContainer.main, (coreTheme.palette as any).onSecondaryContainer.main),
              },
            },
          },
          {
            props: { color: 'tertiary' },
            style: {
              [`& .${svgIconClasses.root}`]: {
                color: (coreTheme.palette as any).onTertiaryContainer.main,
              },
              backgroundColor: (coreTheme.palette as any).tertiaryContainer.main,
              '&:hover': {
                backgroundColor: getStateLayerColor(StateLayer.Layer8, (coreTheme.palette as any).tertiaryContainer.main, (coreTheme.palette as any).onTertiaryContainer.main),
              },
              '&:focused': {
                backgroundColor: getStateLayerColor(StateLayer.Layer12, (coreTheme.palette as any).tertiaryContainer.main, (coreTheme.palette as any).onTertiaryContainer.main),
              },
            },
          },
          {
            props: { variant: 'extended' },
            style: {
              borderRadius: '16px',
              width: 'auto',
              height: 'auto',
              [`& .${svgIconClasses.root}`]: {
                width: '24px',
                height: '24px',
              },
            },
          },
        ],
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            minHeight: '48px',
            [`& .${inputAdornmentClasses.root}`]: {
              color: (coreTheme.palette as any).onSurfaceVariant.main,
              fontSize: '20px',
              margin: '0px',
              [`& .${svgIconClasses.root}`]: {
                fontSize: '20px',
              },
            },
            [`&.Mui-disabled .${inputAdornmentClasses.root}`]: {
              color: alpha((coreTheme.palette as any).onSurfaceVariant.main, StateLayer.Layer38),
            },
          },
          input: {
            ...typographyVariants.bodyLarge,
            color: (coreTheme.palette as any).onSurface.main,
            '&::placeholder': {
              color: (coreTheme.palette as any).onSurfaceVariant.main,
              opacity: 1,
            },
            '&.Mui-disabled': {
              color: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer38),
              '-webkit-text-fill-color': 'unset',
              [`& .${inputAdornmentClasses.root}`]: {
                color: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer38),
              },
              '&::placeholder': {
                color: alpha((coreTheme.palette as any).onSurfaceVariant.main, StateLayer.Layer38),
              },
            },
            '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
              display: 'none',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            height: '48px',
            borderRadius: '12px',
            padding: '0px 16px',
            gap: '8px',
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: (coreTheme.palette as any).outline.main,
            },
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: (coreTheme.palette as any).onSurface.main,
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              border: '3px solid',
              borderColor: (coreTheme.palette as any).primary.main,
            },
            [`&.Mui-disabled .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: alpha((coreTheme.palette as any).outline.main, StateLayer.Layer38),
            },
          },
          input: {
            padding: '0px',
          },
          multiline: {
            height: 'unset',
            padding: '12px 16px',
          },
        },
        variants: [
          {
            props: { color: 'warning' },
            style: {
              [`& .${outlinedInputClasses.notchedOutline}, &:hover .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: (coreTheme.palette as any).warning.main,
              },
            },
          },
        ],
      },
      MuiFilledInput: {
        defaultProps: {
          disableUnderline: true,
        },
        styleOverrides: {
          root: {
            height: '48px',
            borderRadius: '12px',
            padding: '0px 16px',
            gap: '8px',
            backgroundColor: (coreTheme.palette as any).surfaceContainerHighest.main,
            '&:hover': {
              backgroundColor: darken((coreTheme.palette as any).surfaceContainerHighest.main, StateLayer.Layer8),
              boxShadow: `inset 0 0 0 1px ${(coreTheme.palette as any).outline.main}`,
            },
            '&.Mui-focused': {
              boxShadow: `inset 0 0 0 3px ${(coreTheme.palette as any).primary.main}`,
              backgroundColor: (coreTheme.palette as any).surfaceContainerHighest.main,
            },
            '&.Mui-error': {
              boxShadow: `inset 0 0 0 1px ${(coreTheme.palette as any).error.main}`,
              '&.Mui-focused': {
                boxShadow: `inset 0 0 0 3px ${(coreTheme.palette as any).primary.main}`,
              },
            },
            '&.Mui-disabled': {
              border: 'none',
              backgroundColor: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer12),
            },
          },
          input: {
            padding: '0px',
          },
          multiline: {
            height: 'unset',
            padding: '12px 16px',
          },
        },
        variants: [
          {
            props: { color: 'warning' },
            style: {
              '&, &:hover': {
                boxShadow: `inset 0 0 0 1px ${(coreTheme.palette as any).warning.main}`,
              },
              '&.Mui-focused': {
                boxShadow: `inset 0 0 0 3px ${(coreTheme.palette as any).warning.main}`,
              },
            },
          },
        ],
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            borderWidth: 0,
            // eslint-disable-next-line max-len
            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within':
              { outlineWidth: '0px' },
            '& .MuiDataGrid-columnHeaderTitleContainer': { paddingLeft: '0px' },
            '& .MuiDataGrid-toolbarContainer': { justifyContent: 'flex-end' },
            '& .MuiDataGrid-columnsContainer': { borderBottomColor: 'transparent' },
          },
          cell: {
            color: colors.black,
            borderBottomColor: colors.darkSnow,
          },
          row: {
            cursor: 'pointer',
          },
          columnHeader: {
            color: colors.grey,
            fontSize: '0.75rem',
            '.MuiDataGrid-columnHeaderTitle': { fontWeight: 300 },
            '.MuiBadge-anchorOriginTopRightRectangular': {
              display: 'none',
            },
          },
        },
      },
      MuiTabs: {
        defaultProps: {
          slots: {
            StartScrollButtonIcon: ArrowBackIcon,
            EndScrollButtonIcon: ArrowForwardIcon,
          },
        },
        styleOverrides: {
          root: {
            [`&:has(.${tabsClasses.scrollableX})`]: {
              gap: '8px',
            },
            [`.${tabsClasses.scrollButtons}`]: {
              opacity: 1,
              borderRadius: '100px',
              padding: '8px',
              width: '40px',
              height: '40px',
              alignSelf: 'center',
              color: (coreTheme.palette as any).onSurfaceVariant.main,
              '&:hover': {
                backgroundColor: alpha((coreTheme.palette as any).onSurfaceVariant.main, StateLayer.Layer8),
              },
              '&:focus': {
                backgroundColor: alpha((coreTheme.palette as any).onSurfaceVariant.main, StateLayer.Layer12),
              },
              '&:active': {
                backgroundColor: alpha((coreTheme.palette as any).onSurfaceVariant.main, StateLayer.Layer16),
              },
              '&.Mui-disabled': {
                color: alpha((coreTheme.palette as any).onSurface.main, StateLayer.Layer38),
                opacity: 1,
              },
              '& svg': {
                width: '24px',
                height: '24px',
              },
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontSize: '1rem',
            '&.Mui-selected': {
              fontWeight: 700,
            },
          },
          labelIcon: {
            minHeight: 0,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: '24px',
            boxShadow: 'none',
            padding: '24px',
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paperAnchorBottom: {
            height: 'calc(100% - 88px)',
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
            boxShadow: 'none',
          },
          paperAnchorRight: {
            borderTopLeftRadius: 24,
            borderBottomLeftRadius: 24,
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            '& .MuiAlert-root': {
              borderRadius: '12px',
            },
          },
        },
      },
      MuiAlert: {
        defaultProps: {
          iconMapping: {
            success: <CheckCircleOutlineIcon />,
            warning: <InfoOutlinedIcon />,
            error: <DangerousOutlinedIcon />,
          },
        },
        variants: [
          {
            props: {
              severity: 'success',
            },
            style: {
              color: (coreTheme.palette as any).successContainer.contrastText,
              backgroundColor: (coreTheme.palette as any).successContainer.main,
            },
          },
          {
            props: {
              severity: 'warning',
            },
            style: {
              color: (coreTheme.palette as any).warningContainer.contrastText,
              backgroundColor: (coreTheme.palette as any).warningContainer.main,
            },
          },
          {
            props: {
              severity: 'error',
            },
            style: {
              color: (coreTheme.palette as any).errorContainer.contrastText,
              backgroundColor: (coreTheme.palette as any).errorContainer.main,
            },
          },
        ],
      },
      MuiUseMediaQuery: {
        defaultProps: {
          noSsr: true,
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            displayLarge: 'p',
            displayMedium: 'p',
            displaySmall: 'p',
            headlineLarge: 'p',
            headlineMedium: 'p',
            headlineSmall: 'p',
            titleLarge: 'p',
            titleMedium: 'p',
            titleSmall: 'p',
            labelLarge: 'p',
            labelMedium: 'p',
            labelSmall: 'p',
            bodyLarge: 'p',
            bodyMedium: 'p',
            bodySmall: 'p',
          },
        },
        styleOverrides: {
          root: {
            variants: [
              {
                props: { color: 'secondary' },
                style: {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  color: (coreTheme.palette as any).onSurfaceVariant.main,
                },
              },
            ],
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            height: 40,
            padding: 4,
            boxSizing: 'border-box',
            '& .MuiToggleButtonGroup-firstButton': {
              borderTopLeftRadius: 40,
              borderBottomLeftRadius: 40,
            },
            '& .MuiToggleButtonGroup-lastButton': {
              borderTopRightRadius: 40,
              borderBottomRightRadius: 40,
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            flex: 1,
            textTransform: 'none',
            color: (coreTheme.palette as any).onSurface.main,
            borderColor: (coreTheme.palette as any).outline.main,
            '&:focus-visible': {
              backgroundColor: getStateLayerColor(StateLayer.Layer8, (coreTheme.palette as any).surface.main, (coreTheme.palette as any).onSurface.main),
              borderColor: (coreTheme.palette as any).outline.main,
            },
            '&.Mui-selected': {
              color: (coreTheme.palette as any).onSecondaryContainer.main,
              backgroundColor: (coreTheme.palette as any).secondaryContainer.main,
              borderColor: (coreTheme.palette as any).outline.main,
              '&:before': {
                fontFamily: 'Material Icons',
                '-webkit-font-smoothing': 'antialiased',
                '-moz-osx-font-smoothing': 'grayscale',
                'text-rendering': 'optimizeLegibility',
                'font-feature-settings': 'liga',
                content: "'checked'",
                width: '18px',
                height: '18px',
                display: 'flex',
                alignItems: 'center',
                marginRight: 1,
              },
              '&:hover': {
                backgroundColor: getStateLayerColor(StateLayer.Layer8, (coreTheme.palette as any).secondaryContainer.main, (coreTheme.palette as any).onSecondaryContainer.main),
                borderColor: (coreTheme.palette as any).outline.main,
              },
              '&:focus-visible': {
                backgroundColor: getStateLayerColor(StateLayer.Layer12, (coreTheme.palette as any).secondaryContainer.main, (coreTheme.palette as any).onSecondaryContainer.main),
                borderColor: (coreTheme.palette as any).outline.main,
              },
            },
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            borderRadius: ownerState.variant === 'square' ? '12px' : undefined,
            width: ownerState.size,
            height: ownerState.size,
          }),
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: ({ ownerState }) => ({
            backgroundColor: ownerState.variant === 'rich' ? (coreTheme.palette as any).surfaceContainer.main : (coreTheme.palette as any).inverseSurface.main,
            borderRadius: ownerState.variant === 'rich' ? '12px' : '4px',
            color: (coreTheme.palette as any).inverseOnSurface.main,
            ...typographyVariants.bodySmall,
          }),
          arrow: {
            color: (coreTheme.palette as any).inverseSurface.main,
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          markLabel: {
            '&[data-index="0"]': {
              transform: 'translateX(0%)',
            },
            '&[data-index="1"]': {
              transform: 'translateX(-100%)',
            },
          },
          valueLabel: {
            '&:before': {
              display: 'none',
            },
            borderRadius: '100px',
            backgroundColor: (coreTheme.palette as any).primary.main,
          },
          rail: {
            color: (coreTheme.palette as any).secondaryContainer.main,
            opacity: 1,
          },
          mark: {
            display: 'none',
          },
        },
      },
    },
  } as ThemeOptions;

  const theme = createTheme(componentsTheme, coreTheme, frFR);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
