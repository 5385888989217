import { Box, Button, Typography } from '@mui/material';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import { useEffect, useMemo } from 'react';
import { generatePath, useLocation, Link } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { Business_Profile_Enum } from 'kheops-graphql';
import { RoutePaths } from '../../routes/AppRoutes';
import useMakeAnOffer from '../hooks/useMakeAnOffer';
import useOpenable from '../../hooks/useOpenable';
import ContractConditionsDialog from '../../common/components/ContractConditionsDialog';
import ContractsUtils from '../../common/utils/contracts.utils';
import { currentContextAtom } from '../../state';
import { ContractOfferInfo, ContractConditions } from '../state';

export interface NoOfferHeaderProps {
  contract: ContractOfferInfo;
  channel: GroupChannel;
}

const WORDING_KEYS_MAP = {
  [Business_Profile_Enum.Buyer]: {
    noOfferTitle: 'contracts:commercial_offer_title_as_buyer',
    noOfferDescription: 'contracts:commercial_offer_description_as_buyer',
    companyLink: 'contracts:see_supplier_page',
  },
  [Business_Profile_Enum.Supplier]: {
    noOfferTitle: 'contracts:commercial_offer_title_as_supplier',
    noOfferDescription: 'contracts:commercial_offer_description_as_supplier',
    companyLink: 'contracts:see_buyer_page',
  },
};

export default function NoOfferHeader({ contract, channel }: NoOfferHeaderProps): React.JSX.Element {
  const { t } = useTranslation(['contracts']);
  const context = useAtomValue(currentContextAtom);
  const makeAnOffer = useMakeAnOffer();
  const location = useLocation();
  const { open: openContractConditionsDialog, close: closeContractConditionsDialog, isOpen: isContractConditionsDialogOpen } = useOpenable();
  const conditions = useMemo((): Partial<ContractConditions> | undefined => {
    return ContractsUtils.getDefaultContractConditions(contract, context);
  }, [contract, context]);

  useEffect(() => {
    if (location.state?.newCustomPriceListId || location.state?.newSubCatalogId) {
      openContractConditionsDialog();
    }
  }, [location]);

  const handleContractOfferDialogClose = (response?: ContractConditions): void => {
    if (response) {
      makeAnOffer(channel, response, contract.id);
    }

    closeContractConditionsDialog();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: {
          xs: 'column',
          lg: 'row',
        },
        gap: {
          xs: 2,
          lg: 3,
        },
        mb: {
          xs: 2,
          lg: 3,
        },
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography variant="titleMedium">
          {t(WORDING_KEYS_MAP[context.realm].noOfferTitle)}
        </Typography>
        <Typography variant="bodySmall" color="secondary" sx={{ mt: 1, display: { xs: 'none', lg: 'block' } }}>
          {t(WORDING_KEYS_MAP[context.realm].noOfferDescription)}
        </Typography>
      </Box>
      <Box
        sx={{
          maxWidth: '100%',
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row-reverse',
            lg: 'column',
          },
          gap: 2,
        }}
      >
        <Button
          variant="contained"
          startIcon={<AddBusinessOutlinedIcon />}
          onClick={openContractConditionsDialog}
        >
          <Box
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {t('contracts:make_an_offer')}
          </Box>
        </Button>
        <Button
          variant="outlined"
          component={Link}
          to={
            generatePath(
              RoutePaths.CONTRACT_COMPANY,
              { companyId: context.realm === Business_Profile_Enum.Buyer ? contract.supplyingCompanyId : contract.buyingCompanyId },
            )
          }
        >
          {t(WORDING_KEYS_MAP[context.realm!].companyLink)}
        </Button>
      </Box>
      {isContractConditionsDialogOpen && (
        <ContractConditionsDialog
          onClose={(response) => handleContractOfferDialogClose(response)}
          open={isContractConditionsDialogOpen}
          title={t('contracts:make_an_offer')}
          description={t(`contracts:${context.realm === Business_Profile_Enum.Buyer ? 'offer_dialog_description_as_buyer' : 'offer_dialog_description_as_supplier'}`)}
          submitLabel={t('contracts:offer')}
          contractId={contract.id}
          conditions={conditions}
          commissionRate={context.commissionRate}
          firstOffer
        />
      )}
    </Box>
  );
}
