import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../common/components/ConfirmDialog';
import { BasketOrder } from '../hooks/useBasketOrders';
import SizedImage from '../common/components/SizedImage';

interface BulkRemoveFromBasketDialogProps {
  isOpen: boolean;
  onClose: (confirmerd: boolean) => void;
  order?: BasketOrder;
}

export default function BulkRemoveFromBasketDialog({ isOpen, onClose, order }: BulkRemoveFromBasketDialogProps): React.JSX.Element {
  const { t } = useTranslation(['basket', 'common']);

  return (
    <ConfirmDialog
      open={isOpen}
      onClose={onClose}
      title={order ? t('basket:do_you_want_remove_from_your_basket') : t('basket:do_you_want_empty_your_basket')}
      cancelLabel={t('common:cancel')}
      confirmLabel={t(order ? 'basket:yes_remove_from_my_basket' : 'basket:yes_empty_my_basket')}
    >
      {order && (
        <Box
          sx={{
            display: 'flex',
            border: '1px solid',
            borderColor: 'divider',
            alignItems: 'center',
            py: 1,
            px: 2,
            gap: 2,
            borderRadius: 3,
          }}
        >
          <SizedImage
            src={order.photoUrl}
            width={64}
            height={64}
            borderRadius={4}
            alt={t('common:company')}
          />
          <Typography variant="titleMedium">{order.name}</Typography>
        </Box>
      )}
    </ConfirmDialog>
  );
}
