import { Button, ClickAwayListener } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LocalStorageKeys } from '../../state';
import RichTooltip from '../../common/RichTooltip';

export interface CustomPriceListDiscountTooltipProps {
  open: boolean;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ReactElement<any, any>;
}

export default function CustomPriceListDiscountTooltip({ children, open, onClose }: CustomPriceListDiscountTooltipProps): React.JSX.Element {
  const { t } = useTranslation(['price']);

  const handleUnderstoodClick = (): void => {
    localStorage.setItem(LocalStorageKeys.DISABLE_CATALOG_PRICE_FIELD_TOOLTIP, 'true');
    onClose();
  };

  return (
    <RichTooltip
      open={open}
      disableHoverListener
      disableFocusListener
      placement="top-start"
      supportingText={t('price:price_field_tooltip')}
      primaryAction={(
        <ClickAwayListener onClickAway={() => onClose()} mouseEvent="onMouseDown">
          <Button onClick={handleUnderstoodClick} variant="text">
            {t('price:i_understood')}
          </Button>
        </ClickAwayListener>
      )}
    >
      {children}
    </RichTooltip>
  );
}
