import { Button, Card, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate, useSearchParams } from 'react-router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoginV2Mutation } from '../mutations/__generated__/login.generated';
import EmailErrorImage from '../assets/photos/email-error.png';
import KheopsLogoLong from '../assets/logo/kheops-logo-full-black.svg?react';
import CardFooterImage from '../assets/photos/card-footer.png';
import { RoutePaths } from '../routes/AppRoutes';
import useAuth from '../hooks/useAuth/useAuth';
import { useRedirectIfAuth } from '../hooks/useRedirectIfAuth';

export default function MagicLinkPage(): React.JSX.Element {
  const [searchParams] = useSearchParams();
  const [loginV2, loginV2Output] = useLoginV2Mutation();
  const navigate = useNavigate();
  const { setLoginToken, isLoading, isAuthenticated } = useAuth();
  const { t } = useTranslation('login');
  const [shouldShowError, setShouldShowError] = useState(false);
  const token = searchParams.get('token');
  const RedirectIfAuth = useRedirectIfAuth();

  useEffect(
    () => {
      if (isLoading || loginV2Output.data || isAuthenticated) {
        return;
      }

      loginV2({
        variables: {
          token: token || '',
        },
      });
    },
    [isLoading],
  );

  useEffect(() => {
    if (loginV2Output.data?.loginV2) {
      setLoginToken(loginV2Output.data.loginV2!);
    }
    if (loginV2Output.error) {
      setShouldShowError(true);
    }
  }, [loginV2Output.data]);

  return (
    <RedirectIfAuth>
      { shouldShowError ? (
        <Card sx={{ p: 0, mt: 5, mx: 'auto', width: { xs: '90%', sm: 520 }, height: { xs: 632, sm: 712 }, borderRadius: '32px' }}>
          <Box
            component={KheopsLogoLong}
            sx={{
              height: 50,
              width: 168,
              display: 'block',
              mx: 'auto',
              mt: 8,
              mb: { xs: 4, sm: 8 },
            }}
          />
          <Typography variant="displayMedium" sx={{ mb: 2 }} align="center">{t('login:invalid_link_title')}</Typography>
          <Typography variant="bodyMedium" sx={{ mx: { xs: 2, sm: 11 } }} align="center">{t('login:invalid_link_message')}</Typography>
          <Button
            variant="contained"
            onClick={() => navigate(RoutePaths.LOGIN)}
            sx={{ mb: { xs: 2, sm: 3 }, mt: { xs: 2, sm: 4 }, mx: 'auto', display: 'block' }}
          >
            {t('login:return_to_login_page')}
          </Button>
          <Box
            component="img"
            sx={{
              height: 168,
              width: 215,
              display: 'block',
              mx: 'auto',
              my: { xs: 4, sm: 8 },
            }}
            src={EmailErrorImage}
          />
          <Box
            component="img"
            src={CardFooterImage}
            sx={{
              width: '100%',
              display: 'block',
              color: 'primary',
              backgroundColor: 'text.primary',
              mb: 0,
            }}
          />
        </Card>
      ) : <></>}
    </RedirectIfAuth>
  );
}
